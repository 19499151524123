import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from '../firebase';
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { v4 as uuidv4 } from 'uuid';
import LiveTable from "../Components/LiveTable";
// Import the functions you need from the SDKs you need
import { getDoc, doc, setDoc } from 'firebase/firestore';
import rpmLogoGold from "../assets/logos/rpm_gold.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import { Centrifuge } from 'centrifuge';


const StandingsOnly = () => {
    // set up constants and states
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const [selectLeague, setSelectLeague] = useState(process.env.REACT_APP_LEAGUE);
    const [selectDivision, setSelectDivision] = useState("championship");
    const [centrifugoJWT, setCentrifugoJWT] = useState("");
    const [liveStandingsData, setLiveStandingsData] = useState([]);
    const [liveConstructors, setLiveConstructors] = useState([]);
    const [liveAllTiers, setLiveAllTiers] = useState([]);
    const [rebindAbly, setRebindAbly] = useState(0);
    // const [seasonRaces, setSeasonRaces] = useState()

    const newDataLoaded = () => toast("The current tables have been updated.");

    // get the standings from firebase
    // TODO replace with a get request to mongoDB
    async function getStandings(db, division) {
        const standingsCol = doc(db, process.env.REACT_APP_LEAGUE, division);
        const standingsSnapshot = await getDoc(standingsCol);
        const standingsList = standingsSnapshot.data();
        console.log('data', standingsList)
        setLiveStandingsData(standingsList.standings)
        setLiveConstructors(standingsList.constructors)
        return standingsList;
    }

    async function getAllTiers(db) {
        const standingsCol = doc(db, process.env.REACT_APP_LEAGUE, 'allTiers');
        const standingsSnapshot = await getDoc(standingsCol);
        const standingsList = standingsSnapshot.data();
        console.log('data', standingsList.allTiers)
        setLiveAllTiers(standingsList.allTiers)
        return standingsList;
    }

    // async function getTotalRaces(db) {
    //     const raceTotalsCol = doc(db, 'seasonInfo', 'races');
    //     const raceTotalsSnapshot = await getDoc(raceTotalsCol);
    //     const raceTotalsList = raceTotalsSnapshot.data();
    //     console.log('data', raceTotalsList)
    //     setSeasonRaces(raceTotalsList.raceTotals);
    //     return raceTotalsList;
    // }

    // useEffects for redirecting non-logged in users and blocking homepage from showing during check
    useEffect(() => {
        if (loading) {
            console.log('loading', loading);
            console.log('user', user)
            return;
        }
        if (user === null) {
            console.log('should redirect?')
            navigate("/login")
        };
    }, [user, loading]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                const email = user.email;
                // ...
                console.log("uid", uid, "email", email)
            } else {
                // User is signed out
                // ...
                console.log("user is logged out")
            }
        });

    }, [])

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    useEffect(() => {
        if (user) {
            // get the JWT token from the server
            console.log('try for JWT');
            const url = process.env.REACT_APP_JWT_ENDPOINT;
            const payload = {
                userID: user.uid,
            };

            try {
                axios.post(url, payload).then((response) => {
                    console.log(response.data.token)
                    setCentrifugoJWT(response.data.token);
                });
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.log('no user yet')
        }

    }, [user])

    useEffect(() => {
        console.log('start the CENTRIFUGO use effect');
        let subDrivers;
        let subFinalData;
        let subParticipants;
        let centrifuge
        if (centrifugoJWT !== "") {
            toast.success(`Established Connection.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            let driverStandings = [];
            let constructorStandings = [];
            let allTiers = [];
            // TODO consider placing pusher inside of this .. is there a instance where the data does not update in time?
            getStandings(db, selectDivision).then((data) => {
                console.log('data retrieved', data)
                driverStandings = data.standings;
                constructorStandings = data.constructors
                toast.success(`The ${selectDivision} tables have been loaded.`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            })
            getAllTiers(db).then((data) => {
                console.log('data retrieved', data)
                allTiers = data.allTiers;
                toast.success(`The allTiers table has been loaded.`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            })
            centrifuge = new Centrifuge("wss://messaging.purplekraken.dev/centrifugo/connection/websocket", {
                token: centrifugoJWT,
            });
            centrifuge.on('connecting', function (ctx) {
                console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
            }).on('connected', function (ctx) {
                console.log(`connected over ${ctx.transport}`);
            }).on('disconnected', function (ctx) {
                console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
            }).connect();

            subDrivers = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:drivers`);
            subFinalData = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:finalData`);
            subParticipants = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:participants`);

            subParticipants.on('publication', function (ctx) {
                console.log(ctx)
            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();

            subDrivers.on('publication', function (ctx) {
                console.log('-------- New Driver Data Recieved -----')
                const drivers = ctx.data;
                console.log(drivers)
                let currentStandings = [...driverStandings]
                let currentConstructors = [...constructorStandings]
                let currentAllTiers = [...allTiers]
                calculateTables(currentStandings, currentConstructors, drivers, false, currentAllTiers)

            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();

            subFinalData.on('publication', function (ctx) {
                console.log('-------- Final Recieved -----')
                const formattedData = ctx.data;
                console.log(formattedData)
                toast.info(`The race has finished. The tables will now be saved`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                let currentStandings = [...driverStandings]
                let currentConstructors = [...constructorStandings]
                let currentAllTiers = [...allTiers]
                let finalClassificationData = calculateTables(currentStandings, currentConstructors, formattedData[1].finalClassification, true, currentAllTiers)
                finalClassificationData.drivers.forEach((driver, index) => {
                    driver.points = driver.livePoints;
                    driver.fastestLap = false;
                    driver.polePosition = false;
                    driver.driverMatch = false;
                    driver.startingPosition = index + 1;
                    driver.positionChange = null
                })
                finalClassificationData.constructors.forEach((team, index) => {
                    team.points = team.livePoints
                    team.startingPosition = index + 1;
                    team.positionChange = null
                })
                finalClassificationData.allTiers.forEach((team, index) => {
                    team.points = team.livePoints
                    team.startingPosition = index + 1;
                    team.positionChange = null
                })
                if (user.uid === 'gFNF71YXY2XyrtDJR8hfrdfWdi92') {
                    updateTable(selectDivision, finalClassificationData.drivers, finalClassificationData.constructors).then(() => {
                        // integrate a toast here
                        console.log('updated tables on race end');
                        toast.success(`The ${selectDivision} tables have been saved.`, {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                    })

                    updateAllTiers("allTiers", finalClassificationData.allTiers).then(() => {
                        console.log('stored the allTiers data');
                        toast.success(`The allTiers has been saved.`, {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                    })

                    updateRaceResults(formattedData).then(() => {
                        console.log('stored the final data');
                        toast.success(`The final race data has been saved.`, {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                        let rebind = rebindAbly
                        setRebindAbly(rebind + 1)
                    })
                }
            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();
        } else {
            console.log('Could not generate a secure JWT');
            toast.error(`Could not generate a secure JWT.`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }

        // run the cleanup to unsubscribe from pusher and unbind the events
        return () => {
            console.log('clean up');

            if (subParticipants) {
                subParticipants.unsubscribe();
                subParticipants.removeAllListeners();
            }

            if (subDrivers) {
                subDrivers.unsubscribe();
                subDrivers.removeAllListeners();
            }

            if (subFinalData) {
                subFinalData.unsubscribe();
                subFinalData.removeAllListeners();
            }
            if (centrifuge) {
                centrifuge.disconnect();
            }
        };
    }, [selectDivision, rebindAbly, centrifugoJWT]);

    async function updateTable(docName, standings, constructors) {
        await setDoc(doc(db, process.env.REACT_APP_LEAGUE, docName), {
            standings: standings,
            constructors: constructors
        })
    }

    async function updateAllTiers(docName, constructors) {
        await setDoc(doc(db, process.env.REACT_APP_LEAGUE, docName), {
            allTiers: constructors
        })
    }

    async function updateRaceResults(raceResults) {
        await setDoc(doc(db, "raceResults", uuidv4()), {
            results: raceResults
        })
    }

    function calculateTables(currentStandings, currentConstructors, drivers, finalClassification, currentAllTiers) {
        console.log('current standings: ', currentStandings);
        currentStandings.map((driver) => {
            let driverMatch = drivers.find(element => {
                let elementMatch = element.name.toLowerCase().match(driver.name.toLowerCase());
                return elementMatch ? elementMatch.length > 0 : false
            });
            driver.driverMatch = false
            if (driverMatch) {
                let polePoint = driverMatch.gridPosition === 1 && driverMatch.noPolePoint === false ? 1 : 0
                let fastestPoint = driverMatch.fastestLap === true && driverMatch.position < 13 ? 1 : 0
                driver.livePoints = driver.points + driverMatch.currentPoints + polePoint + fastestPoint;
                driver.champion = false;
                driver.fastestLap = false;
                driver.polePosition = false;
                driver.driverMatch = true
                // console.log(driver.livePoints)
                if (driverMatch.fastestLap === true) {
                    driver.fastestLap = true
                }
                if (driverMatch.gridPosition === 1 && driverMatch.noPolePoint === false) {
                    driver.polePosition = true
                }
            }
            return true;
        })
        currentStandings.sort((a, b) => b.livePoints - a.livePoints);

        currentConstructors.map((team) => {
            let teamMatch = currentStandings.filter(element => {
                let elementMatch = element.constructor.toLowerCase().match(team.constructor.toLowerCase());
                return elementMatch ? elementMatch.length > 0 : false
            });
            if (teamMatch) {
                let newPoints = 0;
                for (let i = 0; i < teamMatch.length; i++) {
                    newPoints = newPoints + (teamMatch[i].livePoints - teamMatch[i].points);
                }
                team.livePoints = team.points + newPoints;
                team.champion = false;
            }
            return true;
        })
        currentConstructors.sort((a, b) => b.livePoints - a.livePoints);

        currentAllTiers.map((team) => {
            let teamMatch = currentStandings.filter(element => {
                let elementMatch = element.constructor.toLowerCase().match(team.constructor.toLowerCase());
                return elementMatch ? elementMatch.length > 0 : false
            });
            if (teamMatch) {
                let newPoints = 0;
                for (let i = 0; i < teamMatch.length; i++) {
                    newPoints = newPoints + (teamMatch[i].livePoints - teamMatch[i].points);
                }
                team.livePoints = team.points + newPoints;
                team.champion = false;
            }
            return true;
        })
        currentAllTiers.sort((a, b) => b.livePoints - a.livePoints);

        let seasonRaces = [
            {
                "totalNormal": 9,
                "completedNormal": 2,
                "completedSprints": 1,
                "totalSprints": 3
            }
        ]
        let currentRaceType = 'normal'
        let maxDriverPointsPerRace = 27
        let maxDriverPointsPerSprint = 31
        let totalDriverNormalPointsAvailable;
        let totalDriverSprintPointsAvailable;
        if (currentRaceType === 'normal') {
            totalDriverNormalPointsAvailable = maxDriverPointsPerRace * ((seasonRaces[0].totalNormal - seasonRaces[0].completedNormal) - 1)
            totalDriverSprintPointsAvailable = maxDriverPointsPerSprint * ((seasonRaces[0].totalSprints - seasonRaces[0].completedSprints))
        } else {
            totalDriverNormalPointsAvailable = maxDriverPointsPerRace * ((seasonRaces[0].totalNormal - seasonRaces[0].completedNormal))
            totalDriverSprintPointsAvailable = maxDriverPointsPerSprint * ((seasonRaces[0].totalSprints - seasonRaces[0].completedSprints) - 1)
        }
        let totalDriverPointsAvailable = totalDriverNormalPointsAvailable + totalDriverSprintPointsAvailable;
        console.log('driver points available', totalDriverPointsAvailable);

        let maxConstructorPointsPerRace = 47
        let maxConstructorPointsPerSprint = 55
        let totalConstructorNormalPointsAvailable;
        let totalConstructorSprintPointsAvailable;
        if (currentRaceType === 'normal') {
            totalConstructorNormalPointsAvailable = maxConstructorPointsPerRace * ((seasonRaces[0].totalNormal - seasonRaces[0].completedNormal) - 1)
            totalConstructorSprintPointsAvailable = maxConstructorPointsPerSprint * ((seasonRaces[0].totalSprints - seasonRaces[0].completedSprints))
        } else {
            totalConstructorNormalPointsAvailable = maxConstructorPointsPerRace * ((seasonRaces[0].totalNormal - seasonRaces[0].completedNormal))
            totalConstructorSprintPointsAvailable = maxConstructorPointsPerSprint * ((seasonRaces[0].totalSprints - seasonRaces[0].completedSprints) - 1)
        }
        let totalConstructorPointsAvailable = totalConstructorNormalPointsAvailable + totalConstructorSprintPointsAvailable;
        console.log('constructor points available', totalConstructorPointsAvailable);

        let totalAllTiersPointsAvailable = (totalConstructorPointsAvailable + totalDriverPointsAvailable) * 3;


        // TODO work out a dynamic live points system - take the total races so far and in season and work out points available
        if (currentStandings[0].livePoints > currentStandings[1].livePoints + totalDriverPointsAvailable) {
            currentStandings[0].champion = true;
        }

        if (currentConstructors[0].livePoints > currentConstructors[1].livePoints + totalConstructorPointsAvailable) {
            currentConstructors[0].champion = true;
        }

        if (currentAllTiers[0].livePoints > currentAllTiers[1].livePoints + totalAllTiersPointsAvailable) {
            currentAllTiers[0].champion = true;
        }

        setLiveStandingsData(currentStandings);
        setLiveConstructors(currentConstructors);
        setLiveAllTiers(currentAllTiers);
        if (finalClassification) {
            let returnData = {
                drivers: currentStandings,
                constructors: currentConstructors,
                allTiers: currentAllTiers
            }
            return returnData
        }
    }

    // updates the selected league - the Pusher useEffect is dependant on this so triggers the cleanup and rebinding after updating driver standings
    function refreshPusher(league) {
        setSelectDivision(league);
    }

    return (
        <>
            {loading !== true && user !== null &&
                <div>
                    <nav>
                        <img src={rpmLogoGold} style={{ "cursor": "pointer" }} onClick={() => { navigate("/") }} />
                        <div className='adminButtons headerButtons'>
                            <div>
                                <button onClick={() => { navigate("/live-standings") }}>
                                    Live Standings
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/session-data") }}>
                                    Session Data
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/battle") }}>
                                    Battle Data
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/stats") }}>
                                    Stats
                                </button>
                            </div>
                            {/* <div>
                                <button onClick={prepareServer()}>
                                    Prepare Server
                                </button>
                            </div> */}
                        </div>
                        <span style={{ 'color': 'white' }}>League ID - {process.env.REACT_APP_LEAGUE}</span>
                        <div>
                            <button onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    </nav>
                    <div className='adminButtons'>
                        <div>
                            <button style={{ "background": selectDivision === "premier" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("premier") }}>
                                Premier
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "championship" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("championship") }}>
                                Championship
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "first" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("first") }}>
                                First
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "practice" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("practice") }}>
                                Practice
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "offline" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("offline") }}>
                                Offline
                            </button>
                        </div>
                    </div>
                    <div className="App">
                        <h1 style={{ 'color': 'white', 'textTransform': 'capitalize' }}>RPM {selectDivision} Division</h1>


                        <div className='tables'>
                            <div
                                className='driverTable'>
                                <h4 style={{ 'color': 'white' }}>Live Driver Standings</h4>
                                <LiveTable drivers={liveStandingsData}></LiveTable>
                            </div>
                            <div
                                className='driverTable'>
                                <h4 style={{ 'color': 'white' }}>Live Constructor Standings</h4>
                                <LiveTable drivers={liveConstructors}></LiveTable>
                            </div>
                            <div
                                className='driverTable'>
                                <h4 style={{ 'color': 'white' }}>Live All Tiers</h4>
                                <LiveTable drivers={liveAllTiers}></LiveTable>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
            }
        </>
    );
}

export default StandingsOnly