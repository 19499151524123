import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from '../firebase';
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import '../App.css';
import rpmLogoGold from "../assets/logos/rpm_gold.png";
import rpmLogo from "../assets/logos/rmplogo.jpeg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import _ from "lodash";
import Select from 'react-select';
import axios from 'axios';
import { Centrifuge } from 'centrifuge';
import DriverFinshes from '../Components/DriverFinishes';
import DriverStatPack from '../Components/DriverStatPack';
import DriverLapsFront from '../Components/DriverLapsFront';
import Flag from 'react-svg-country-flags';

const SessionData = () => {
    // set up constants and states
    const navigate = useNavigate();
    const [centrifugoJWT, setCentrifugoJWT] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [user, loading] = useAuthState(auth);
    const [selectLeague, setSelectLeague] = useState(process.env.REACT_APP_LEAGUE);
    const [selectDivision, setSelectDivision] = useState("Not Set");
    const [driverSelectOne, setDriverSelectOne] = useState('Not Set')
    const [driverSelectTwo, setDriverSelectTwo] = useState('Not Set')
    const [driverSelectOneResults, setDriverSelectOneResults] = useState('Not Set')
    const [driverSelectTwoResults, setDriverSelectTwoResults] = useState('Not Set')
    const [driverSelectStats, setDriverSelectStats] = useState('Not Set')
    const [driverSelectOneStats, setDriverSelectOneStats] = useState('Not Set')
    const [driverSelectTwoStats, setDriverSelectTwoStats] = useState('Not Set')
    const [driverOptionsOffline, setDriverOptionsOffline] = useState([
        {
            label: "Hamilton",
            value: "hamilton"
        },
        {
            label: "Bottas",
            value: "bottas"
        },
        {
            label: "Sainz",
            value: "sainz"
        },
        {
            label: "Stroll",
            value: "stroll"
        },
        {
            label: "Alonso",
            value: "alonso"
        },
        {
            label: "Ocon",
            value: "ocon"
        },
        {
            label: "Zhou",
            value: "zhou"
        },
        {
            label: "Gasly",
            value: "gasly"
        },
        {
            label: "Tsunoda",
            value: "tsunoda"
        },
        {
            label: "Vettel",
            value: "vettel"
        },
        {
            label: "Magnussen",
            value: "magnussen"
        },
        {
            label: "Schumacher",
            value: "schumacher"
        },
        {
            label: "Leclerc",
            value: "leclerc"
        },
        {
            label: "Ricciardo",
            value: "ricciardo"
        },
        {
            label: "Norris",
            value: "norris"
        },
        {
            label: "Russel",
            value: "russel"
        },
        {
            label: "Verstappen",
            value: "verstappen"
        },
        {
            label: "PÉREZ",
            value: "PÉREZ"
        },
        {
            label: "Albon",
            value: "albon"
        },
        {
            label: "Latifi",
            value: "latifi"
        }
    ])
    const [driverOptionsFirstSeaons6, setDriverOptionsFirstSeason6] = useState([
        {
            label: "matty347",
            value: "matty347",
        },
        {
            label: "N4T_P4CK3R5",
            value: "N4T_P4CK3R5",
        },
        {
            label: "Mocky1480",
            value: "Mocky1480",
        },
        {
            label: "smit1717",
            value: "smit1717",
        },
        {
            label: "RPM_Danos",
            value: "RPM_Danos",
        },
        {
            label: "RPM_jones58",
            value: "RPM_jones58",
        },
        {
            label: "RTRodo",
            value: "RTRodo",
        },
        {
            label: "Spivey-CJ",
            value: "Spivey-CJ",
        },
        {
            label: "JLeeky7",
            value: "JLeeky7",
        },
        {
            label: "F1-Dino-F1",
            value: "F1-Dino-F1",
        },
        {
            label: "rycka9",
            value: "rycka9",
        },
        {
            label: "Inorganic",
            value: "Inorganic",
        },
        {
            label: "Reuze_Beertje",
            value: "Reuze_Beertje",
        },
        {
            label: "Goee14",
            value: "Goee14",
        },
        {
            label: "RPM_ijver",
            value: "RPM_ijver",
        },
        {
            label: "themightyfrax",
            value: "themightyfrax",
        },
        {
            label: "Philmcconachie",
            value: "Philmcconachie",
        },
        {
            label: "Callum0064",
            value: "Callum0064",
        },
        {
            label: "snellehenk73",
            value: "snellehenk73",
        },
        {
            label: "No Driver",
            value: "No Driver",
        },
    ])
    const [driverOptionsFirst, setDriverOptionsFirst] = useState([
        {
            label: "BOBSPUN",
            value: "BOBSPUN",
        },
        {
            label: "RPM_Inorganic",
            value: "RPM_Inorganic",
        },
        {
            label: "Mocky1480",
            value: "Mocky1480",
        },
        {
            label: "RPM_Jones58",
            value: "RPM_Jones58",
        },
        {
            label: "RPM_Danos",
            value: "RPM_Danos",
        },
        {
            label: "UnluckyFC__",
            value: "UnluckyFC__",
        },
        {
            label: "LisbonAELion67",
            value: "LisbonAELion67",
        },
        {
            label: "Spivey-CJ",
            value: "Spivey-CJ",
        },
        {
            label: "DSBAKER-58",
            value: "DSBAKER-58",
        },
        {
            label: "Kieron_994",
            value: "Kieron_994",
        },
        {
            label: "Faulkiller",
            value: "Faulkiller",
        },
        {
            label: "OST_Jeweller",
            value: "OST_Jeweller",
        },
        {
            label: "MikelAuf",
            value: "MikelAuf",
        },
        {
            label: "Reuze_Beertje",
            value: "Reuze_Beertje",
        },
        {
            label: "Shaunfuss",
            value: "Shaunfuss",
        },
        {
            label: "RPM_IJVER",
            value: "RPM_IJVER",
        },
        {
            label: "TheChaosDruid",
            value: "TheChaosDruid",
        },
        {
            label: "i_AmTron",
            value: "i_AmTron",
        },
        {
            label: "ScooterClem10",
            value: "ScooterClem10",
        },
        {
            label: "snellehenk73",
            value: "snellehenk73",
        }
    ])
    const [driverOptionsChampSeason6, setDriverOptionsChampSeason6] = useState([
        {
            label: "Benpaines_",
            value: "Benpaines_",
        },
        {
            label: "RPM_Zabijaka420",
            value: "RPM_Zabijaka420",
        },
        {
            label: "BigPuggy1987",
            value: "BigPuggy1987",
        },
        {
            label: "booty1973",
            value: "booty1973",
        },
        {
            label: "lViatt",
            value: "lViatt",
        },
        {
            label: "RPM_Stubbe",
            value: "RPM_Stubbe",
        },
        {
            label: "UnluckyFC__",
            value: "Wardy-R6",
        },
        {
            label: "Kieran0064",
            value: "Kieran0064",
        },
        {
            label: "Jetfish11",
            value: "Jetfish11",
        },
        {
            label: "AL2737",
            value: "AL2737",
        },
        {
            label: "Stebo__",
            value: "Stebo__",
        },
        {
            label: "RustyOilcan",
            value: "RustyOilcan",
        },
        {
            label: "lost_glaedr",
            value: "lost_glaedr",
        },
        {
            label: "DZ_Karting",
            value: "DZ_Karting",
        },
        {
            label: "RPM_BAKKER1194",
            value: "RPM_BAKKER1194",
        }
    ])
    const [driverOptionsChamp, setDriverOptionsChamp] = useState([
        {
            "label": "matty347",
            "value": "matty347"
        },
        {
            "label": "N4T_P4CK3R5",
            "value": "N4T_P4CK3R5"
        },
        {
            "label": "smit1717",
            "value": "smit1717"
        },
        {
            "label": "GiraffeBHA",
            "value": "GiraffeBHA"
        },
        {
            "label": "RPM_Stubbe",
            "value": "RPM_Stubbe"
        },
        {
            "label": "RPM_Entity123",
            "value": "RPM_Entity123"
        },
        {
            "label": "RPM_Bakker1194",
            "value": "RPM_Bakker1194"
        },
        {
            "label": "Laur1609",
            "value": "Laur1609"
        },
        {
            "label": "Explicitsimmo23",
            "value": "Explicitsimmo23"
        },
        {
            "label": "Kieran0064",
            "value": "Kieran0064"
        },
        {
            "label": "RPM_husssrc",
            "value": "RPM_husssrc"
        },
        {
            "label": "RTRodo",
            "value": "RTRodo"
        },
        {
            "label": "Jleeky7",
            "value": "Jleeky7"
        },
        {
            "label": "Rycka9",
            "value": "Rycka9"
        },
        {
            "label": "lost_glaedr",
            "value": "lost_glaedr"
        },
        {
            "label": "booty1973",
            "value": "booty1973"
        },
        {
            "label": "RustyOilcan",
            "value": "RustyOilcan"
        },
        {
            "label": "XdOscarvdg",
            "value": "XdOscarvdg"
        },
        {
            "label": "Troy08Blue",
            "value": "Troy08Blue"
        },
        {
            "label": "No Driver",
            "value": "No Driver"
        }
    ])
    const [driverOptionsPremierSeason6, setDriverOptionsPremierSeason6] = useState([
        {
            label: "RPM_Colin-86",
            value: "RPM_Colin-86",
        },
        {
            label: "RPM_Sinfull",
            value: "RPM_Sinfull",
        },
        {
            label: "Sminotla789",
            value: "Sminotla789",
        },
        {
            label: "RVL_Volcano",
            value: "RVL_Volcano",
        },
        {
            label: "RPM_Glassy",
            value: "RPM_Glassy",
        },
        {
            label: "RPM_Higgzy",
            value: "RPM_Higgzy",
        },
        {
            label: "RPM_Luukvdm",
            value: "RPM_Luukvdm",
        },
        {
            label: "Andrewx2905",
            value: "Andrewx2905",
        },
        {
            label: "RPM_KingPinquinn",
            value: "RPM_KingPinquinn",
        },
        {
            label: "CPI_Mango",
            value: "CPI_Mango",
        },
        {
            label: "ET8_Evil",
            value: "ET8_Evil",
        },
        {
            label: "RPM_Entity123",
            value: "RPM_Entity123",
        },
        {
            label: "GiraffeBha",
            value: "GiraffeBha",
        },
        {
            label: "C_osmo90",
            value: "C_osmo90",
        },
        {
            label: "maggot361975",
            value: "maggot361975",
        },
        {
            label: "RPM_Dragos",
            value: "RPM_Dragos",
        },
        {
            label: "RPM_husssrc",
            value: "RPM_husssrc",
        },
        {
            label: "davie_w",
            value: "davie_w",
        },
        {
            label: "Juul_Warrior_",
            value: "Juul_Warrior_",
        },
        {
            label: "ABV",
            value: "ABV",
        }
    ])
    const [driverOptionsPremier, setDriverOptionsPremier] = useState([
        {
            "label": "Banter_Branden_0",
            "value": "Banter_Branden_0",
        },
        {
            "label": "LearnerDriver86",
            "value": "LearnerDriver86",
        },
        {
            "label": "AL2737",
            "value": "AL2737",
        },
        {
            "label": "Jetfish11",
            "value": "Jetfish11",
        },
        {
            "label": "RVL_Volcano",
            "value": "RVL_Volcano",
        },
        {
            "label": "RPM_Higgzy",
            "value": "RPM_Higgzy",
        },
        {
            "label": "Davie_w",
            "value": "Davie_w",
        },
        {
            "label": "Stebo__",
            "value": "Stebo__",
        },
        {
            "label": "Sminotla789",
            "value": "Sminotla789",
        },
        {
            "label": "Maggot361975",
            "value": "Maggot361975",
        },
        {
            "label": "RPM_Dragos",
            "value": "RPM_Dragos",
        },
        {
            "label": "RPM_Kvkfc",
            "value": "RPM_Kvkfc",
        },
        {
            "label": "RPM_KingPinquinn",
            "value": "RPM_KingPinquinn",
        },
        {
            "label": "RPM_Yeast",
            "value": "RPM_Yeast",
        },
        {
            "label": "RPM_Luukvdm",
            "value": "RPM_Luukvdm",
        },
        {
            "label": "ET8_Evil",
            "value": "ET8_Evil",
        },
        {
            "label": "Zykotikz",
            "value": "Zykotikz",
        },
        {
            "label": "No Driver",
            "value": "No Driver",
        },
        {
            "label": "No Driver",
            "value": "No Driver",
        },
        {
            "label": "No Driver",
            "value": "No Driver",
        }
    ])
    const [driverOptions, setDriverOptions] = useState([])
    const [rebindAbly, setRebindAbly] = useState({})

    const [driverFinishesPrem, setDriverFinishesPrem] = useState([
        {
            "official_rank": 9,
            "team": "Mercedes",
            "driver_name": "RPM_KingPinquinn",
            "round_1": 7,
            "round_2": 4,
            "round_3": "DNF",
            "round_4": 6,
            "round_5": 5,
            "round_6": 13,
            "round_7": 10,
            "round_8": 9,
            "round_9": 8,
            "round_10": "DNF",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 10,
            "team": "Mercedes",
            "driver_name": "RPM_Sinfull",
            "round_1": "DNF",
            "round_2": 10,
            "round_3": 7,
            "round_4": 12,
            "round_5": 8,
            "round_6": 6,
            "round_7": 7,
            "round_8": 7,
            "round_9": 12,
            "round_10": 12,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 12,
            "team": "Red Bull",
            "driver_name": "ET8_Evil",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": 2,
            "round_9": 2,
            "round_10": 1,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 3,
            "team": "Red Bull",
            "driver_name": "AndrewX2905",
            "round_1": 10,
            "round_2": 2,
            "round_3": "DNF",
            "round_4": 5,
            "round_5": 2,
            "round_6": "DNF",
            "round_7": 8,
            "round_8": 4,
            "round_9": 5,
            "round_10": 2,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 1,
            "team": "Ferrari",
            "driver_name": "RPM_Colin-86",
            "round_1": 1,
            "round_2": 1,
            "round_3": 1,
            "round_4": 1,
            "round_5": 1,
            "round_6": 1,
            "round_7": 1,
            "round_8": 1,
            "round_9": 1,
            "round_10": 3,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 11,
            "team": "Ferrari",
            "driver_name": "RPM_Luukvdm",
            "round_1": "DNF",
            "round_2": 8,
            "round_3": 6,
            "round_4": 11,
            "round_5": 7,
            "round_6": 7,
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": 11,
            "round_10": 6,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 4,
            "team": "McLaren",
            "driver_name": "RPM_Glassy",
            "round_1": "DNS",
            "round_2": 3,
            "round_3": 9,
            "round_4": 3,
            "round_5": 10,
            "round_6": 4,
            "round_7": 4,
            "round_8": 10,
            "round_9": 6,
            "round_10": 7,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 5,
            "team": "McLaren",
            "driver_name": "RPM_Higgzy",
            "round_1": 6,
            "round_2": 6,
            "round_3": 4,
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": 3,
            "round_7": 3,
            "round_8": 5,
            "round_9": 10,
            "round_10": 11,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 6,
            "team": "Aston Martin",
            "driver_name": "maggot361975",
            "round_1": 2,
            "round_2": 11,
            "round_3": 3,
            "round_4": 4,
            "round_5": 3,
            "round_6": "DNF",
            "round_7": "DNF",
            "round_8": 8,
            "round_9": 13,
            "round_10": 8,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 19,
            "team": "Aston Martin",
            "driver_name": "CPI_Mango",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNF",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 13,
            "team": "Alpine",
            "driver_name": "RPM_Dragos",
            "round_1": "DNF",
            "round_2": 12,
            "round_3": 10,
            "round_4": 8,
            "round_5": 12,
            "round_6": 9,
            "round_7": 9,
            "round_8": 11,
            "round_9": 7,
            "round_10": 10,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 17,
            "team": "Alpine",
            "driver_name": "C_osmo90",
            "round_1": 9,
            "round_2": 15,
            "round_3": 14,
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": 10,
            "round_7": "DNS",
            "round_8": "DNF",
            "round_9": "DNF",
            "round_10": "DNF",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 15,
            "team": "Alpha Tauri",
            "driver_name": "Sminotla789",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": 12,
            "round_7": 6,
            "round_8": "DNF",
            "round_9": 4,
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 18,
            "team": "Alpha Tauri",
            "driver_name": "Juul_Warrior_",
            "round_1": 11,
            "round_2": 17,
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNF",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 7,
            "team": "Alfa Romeo",
            "driver_name": "Davie_w",
            "round_1": 3,
            "round_2": 7,
            "round_3": 2,
            "round_4": "DNS",
            "round_5": "DNF",
            "round_6": "DNF",
            "round_7": "DNF",
            "round_8": 6,
            "round_9": 3,
            "round_10": 4,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 8,
            "team": "Alfa Romeo",
            "driver_name": "RPM_husssrc",
            "round_1": 4,
            "round_2": 9,
            "round_3": 5,
            "round_4": 7,
            "round_5": 9,
            "round_6": 11,
            "round_7": 5,
            "round_8": 12,
            "round_9": 9,
            "round_10": "DNF",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 2,
            "team": "Haas",
            "driver_name": "RVL_Volcano",
            "round_1": "DNS",
            "round_2": 5,
            "round_3": "DNF",
            "round_4": 2,
            "round_5": 4,
            "round_6": 2,
            "round_7": 2,
            "round_8": 3,
            "round_9": "DNS",
            "round_10": 5,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 20,
            "team": "Haas",
            "driver_name": "ABV",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 14,
            "team": "Williams",
            "driver_name": "RPM_Entity123",
            "round_1": 5,
            "round_2": 14,
            "round_3": 8,
            "round_4": "DNS",
            "round_5": "DNF",
            "round_6": 8,
            "round_7": 12,
            "round_8": 13,
            "round_9": "DNS",
            "round_10": 13,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 16,
            "team": "Williams",
            "driver_name": "Giraffebha",
            "round_1": "DNF",
            "round_2": 16,
            "round_3": "DNF",
            "round_4": "DNS",
            "round_5": 11,
            "round_6": 5,
            "round_7": 11,
            "round_8": 14,
            "round_9": "DNS",
            "round_10": 9,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        }
    ])
    const [driverFinishesChamp, setDriverFinishesChamp] = useState([
        {
            "official_rank": 1,
            "team": "Mercedes",
            "driver_name": "Jetfish11",
            "round_1": 2,
            "round_2": 1,
            "round_3": 2,
            "round_4": 2,
            "round_5": 1,
            "round_6": 2,
            "round_7": 3,
            "round_8": 1,
            "round_9": 2,
            "round_10": 3,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 2,
            "team": "Mercedes",
            "driver_name": "AL2737",
            "round_1": 5,
            "round_2": 2,
            "round_3": 1,
            "round_4": 1,
            "round_5": 3,
            "round_6": "DNS",
            "round_7": 1,
            "round_8": 2,
            "round_9": 3,
            "round_10": 1,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 7,
            "team": "Red Bull",
            "driver_name": "LVIatt",
            "round_1": 4,
            "round_2": 3,
            "round_3": 4,
            "round_4": 4,
            "round_5": "DNS",
            "round_6": 8,
            "round_7": "DNF",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 16,
            "team": "Red Bull",
            "driver_name": "Seat Available",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 3,
            "team": "Ferrari",
            "driver_name": "Stebo__",
            "round_1": 3,
            "round_2": 4,
            "round_3": 3,
            "round_4": 3,
            "round_5": 2,
            "round_6": 1,
            "round_7": "DNS",
            "round_8": 5,
            "round_9": 1,
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 11,
            "team": "Ferrari",
            "driver_name": "RPM_BAKKER1194",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": 6,
            "round_10": 6,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 4,
            "team": "McLaren",
            "driver_name": "Kieran0064",
            "round_1": 6,
            "round_2": 5,
            "round_3": 7,
            "round_4": 8,
            "round_5": 4,
            "round_6": 5,
            "round_7": 2,
            "round_8": 4,
            "round_9": 7,
            "round_10": 5,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 5,
            "team": "McLaren",
            "driver_name": "Booty1973",
            "round_1": 10,
            "round_2": 8,
            "round_3": 6,
            "round_4": 5,
            "round_5": "DNF",
            "round_6": 7,
            "round_7": "DNS",
            "round_8": 3,
            "round_9": 5,
            "round_10": 2,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 17,
            "team": "Aston Martin",
            "driver_name": "UnluckyFC__",
            "round_1": "*",
            "round_2": "*",
            "round_3": "*",
            "round_4": "*",
            "round_5": "*",
            "round_6": "*",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 13,
            "team": "Aston Martin",
            "driver_name": "Fragiletunnel",
            "round_1": "*",
            "round_2": "*",
            "round_3": "*",
            "round_4": "*",
            "round_5": "*",
            "round_6": "*",
            "round_7": "DNF",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 18,
            "team": "Alpine",
            "driver_name": "Brocki4",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 15,
            "team": "Alpine",
            "driver_name": "Benpaines_",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 6,
            "team": "Alpha Tauri",
            "driver_name": "DZ Karting",
            "round_1": "*",
            "round_2": "*",
            "round_3": 10,
            "round_4": 9,
            "round_5": 5,
            "round_6": 3,
            "round_7": 4,
            "round_8": "DNF",
            "round_9": 4,
            "round_10": "DNF",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 19,
            "team": "Alpha Tauri",
            "driver_name": "Seat Available",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 8,
            "team": "Alfa Romeo",
            "driver_name": "RustyOilcan",
            "round_1": 7,
            "round_2": 7,
            "round_3": "DNF",
            "round_4": 7,
            "round_5": "DNS",
            "round_6": 6,
            "round_7": "DNS",
            "round_8": "DNF",
            "round_9": "DNS",
            "round_10": "DNF",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 12,
            "team": "Alfa Romeo",
            "driver_name": "RPM_Zabijaka420",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": 8,
            "round_10": 4,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 10,
            "team": "Haas",
            "driver_name": "RPM_Stubbe",
            "round_1": 9,
            "round_2": "DNF",
            "round_3": "DNF",
            "round_4": "DNF",
            "round_5": "DNF",
            "round_6": 9,
            "round_7": "DNS",
            "round_8": 6,
            "round_9": "DNF",
            "round_10": 7,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 9,
            "team": "Haas",
            "driver_name": "lost_glaedr",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": 8,
            "round_4": 11,
            "round_5": "DNF",
            "round_6": 4,
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 14,
            "team": "Williams",
            "driver_name": "Seat Available",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 20,
            "team": "Williams",
            "driver_name": "Seat Available",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        }
    ]);
    const [driverFinishesFirst, setDriverFinishesFirst] = useState([
        {
            "official_rank": 3,
            "team": "Mercedes",
            "driver_name": "smit1717",
            "round_1": 10,
            "round_2": 5,
            "round_3": 11,
            "round_4": 3,
            "round_5": 2,
            "round_6": 5,
            "round_7": 6,
            "round_8": 1,
            "round_9": 7,
            "round_10": 4,
            "round_11": 3,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 12,
            "team": "Mercedes",
            "driver_name": "Spivey-CJ",
            "round_1": 7,
            "round_2": 12,
            "round_3": 14,
            "round_4": 13,
            "round_5": 13,
            "round_6": 9,
            "round_7": 9,
            "round_8": 9,
            "round_9": 12,
            "round_10": 11,
            "round_11": 13,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 1,
            "team": "Red Bull",
            "driver_name": "Rycka9",
            "round_1": 4,
            "round_2": 4,
            "round_3": 2,
            "round_4": 1,
            "round_5": 10,
            "round_6": 3,
            "round_7": 7,
            "round_8": 2,
            "round_9": 2,
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 13,
            "team": "Red Bull",
            "driver_name": "Callum0064",
            "round_1": "DNF",
            "round_2": "DNF",
            "round_3": 7,
            "round_4": "DNF",
            "round_5": 9,
            "round_6": "DNF",
            "round_7": "DNS",
            "round_8": "DNF",
            "round_9": 6,
            "round_10": 12,
            "round_11": 12,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 6,
            "team": "Ferrari",
            "driver_name": "RPM_Danos",
            "round_1": 5,
            "round_2": 9,
            "round_3": 4,
            "round_4": 10,
            "round_5": "DNF",
            "round_6": 2,
            "round_7": 3,
            "round_8": 10,
            "round_9": 5,
            "round_10": 3,
            "round_11": 5,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 17,
            "team": "Ferrari",
            "driver_name": "RPM_Ijver",
            "round_1": "DNF",
            "round_2": 14,
            "round_3": "DNF",
            "round_4": 11,
            "round_5": "DNF",
            "round_6": "DNF",
            "round_7": 12,
            "round_8": 12,
            "round_9": 15,
            "round_10": 10,
            "round_11": 14,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 19,
            "team": "McLaren",
            "driver_name": "Scooterclem10",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNF",
            "round_6": 12,
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": 16,
            "round_10": 8,
            "round_11": 10,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 9,
            "team": "McLaren",
            "driver_name": "Snellehenk73",
            "round_1": 3,
            "round_2": 11,
            "round_3": 13,
            "round_4": 7,
            "round_5": 8,
            "round_6": 7,
            "round_7": 8,
            "round_8": "DNF",
            "round_9": 10,
            "round_10": 6,
            "round_11": 9,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 20,
            "team": "Aston Martin",
            "driver_name": "Mocky1480",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": 13,
            "round_9": "DNS",
            "round_10": 13,
            "round_11": 11,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 8,
            "team": "Aston Martin",
            "driver_name": "RPM_jones58",
            "round_1": 6,
            "round_2": 7,
            "round_3": 8,
            "round_4": 12,
            "round_5": 11,
            "round_6": "DNS",
            "round_7": 4,
            "round_8": 7,
            "round_9": 8,
            "round_10": "DNF",
            "round_11": "DNS",
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 5,
            "team": "Alpine",
            "driver_name": "RTRodo",
            "round_1": 9,
            "round_2": 3,
            "round_3": 5,
            "round_4": 8,
            "round_5": 3,
            "round_6": "DNF",
            "round_7": 2,
            "round_8": 4,
            "round_9": 4,
            "round_10": 5,
            "round_11": 7,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 16,
            "team": "Alpine",
            "driver_name": "Philmcconachie",
            "round_1": "DNF",
            "round_2": 16,
            "round_3": 6,
            "round_4": "DNF",
            "round_5": "DNF",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 18,
            "team": "Alpha Tauri",
            "driver_name": "Goee14",
            "round_1": "DNF",
            "round_2": 10,
            "round_3": 12,
            "round_4": "DNF",
            "round_5": "DNS",
            "round_6": "DNF",
            "round_7": "DNF",
            "round_8": "DNF",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 11,
            "team": "Alpha Tauri",
            "driver_name": "Reuze_Beertje",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": 4,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 4,
            "team": "Alfa Romeo",
            "driver_name": "F1-Dino-F1",
            "round_1": 2,
            "round_2": "DNS",
            "round_3": 9,
            "round_4": 4,
            "round_5": 4,
            "round_6": 1,
            "round_7": 5,
            "round_8": 14,
            "round_9": 3,
            "round_10": "DNF",
            "round_11": "DNS",
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 14,
            "team": "Alfa Romeo",
            "driver_name": "Inorganic",
            "round_1": "DNF",
            "round_2": 15,
            "round_3": 15,
            "round_4": 14,
            "round_5": "DNF",
            "round_6": 11,
            "round_7": 10,
            "round_8": 5,
            "round_9": 9,
            "round_10": 9,
            "round_11": 8,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 7,
            "team": "Haas",
            "driver_name": "Matty347",
            "round_1": 1,
            "round_2": 2,
            "round_3": "DNS",
            "round_4": 2,
            "round_5": 12,
            "round_6": 4,
            "round_7": 11,
            "round_8": 15,
            "round_9": 14,
            "round_10": 1,
            "round_11": 1,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 10,
            "team": "Haas",
            "driver_name": "Jleeky7",
            "round_1": 8,
            "round_2": 8,
            "round_3": "DNS",
            "round_4": 5,
            "round_5": 7,
            "round_6": 6,
            "round_7": "DNS",
            "round_8": 6,
            "round_9": "DNS",
            "round_10": 7,
            "round_11": 6,
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 15,
            "team": "Williams",
            "driver_name": "themightyfrax",
            "round_1": "DNF",
            "round_2": 16,
            "round_3": "DNF",
            "round_4": 9,
            "round_5": "DNF",
            "round_6": 8,
            "round_7": "DNS",
            "round_8": 8,
            "round_9": 13,
            "round_10": 14,
            "round_11": "DNS",
            "round_12": null,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 2,
            "team": "Williams",
            "driver_name": "N4T_P4CK3R5",
            "round_1": "DNS",
            "round_2": 1,
            "round_3": 1,
            "round_4": "DNF",
            "round_5": 1,
            "round_6": 13,
            "round_7": 1,
            "round_8": 3,
            "round_9": 1,
            "round_10": 2,
            "round_11": 2,
            "round_12": null,
            "round_13": null,
            "round_14": null
        }
    ]);

    const [driverQualisPrem, setDriverQualisPrem] = useState([
        {
            "official_rank": 9,
            "team": "Mercedes",
            "driver_name": "RPM_KingPinquinn",
            "round_1": 15,
            "round_2": 6,
            "round_3": 8,
            "round_4": 9,
            "round_5": 15,
            "round_6": 11,
            "round_7": 13,
            "round_8": 16,
            "round_9": 12,
            "round_10": 15,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 10,
            "team": "Mercedes",
            "driver_name": "RPM_Sinfull",
            "round_1": 16,
            "round_2": 10,
            "round_3": 13,
            "round_4": 16,
            "round_5": 10,
            "round_6": 3,
            "round_7": 14,
            "round_8": 15,
            "round_9": 9,
            "round_10": 18,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 12,
            "team": "Red Bull",
            "driver_name": "ET8_Evil",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": 1,
            "round_9": 1,
            "round_10": 1,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 3,
            "team": "Red Bull",
            "driver_name": "AndrewX2905",
            "round_1": 2,
            "round_2": 2,
            "round_3": 11,
            "round_4": 5,
            "round_5": 5,
            "round_6": 15,
            "round_7": 5,
            "round_8": 2,
            "round_9": 10,
            "round_10": 4,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 1,
            "team": "Ferrari",
            "driver_name": "RPM_Colin-86",
            "round_1": 1,
            "round_2": 1,
            "round_3": 9,
            "round_4": 1,
            "round_5": 1,
            "round_6": 10,
            "round_7": 1,
            "round_8": 3,
            "round_9": 4,
            "round_10": 2,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 11,
            "team": "Ferrari",
            "driver_name": "RPM_Luukvdm",
            "round_1": 10,
            "round_2": 9,
            "round_3": 3,
            "round_4": 11,
            "round_5": 9,
            "round_6": 4,
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": 17,
            "round_10": 13,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 4,
            "team": "McLaren",
            "driver_name": "RPM_Glassy",
            "round_1": 3,
            "round_2": 4,
            "round_3": 7,
            "round_4": 10,
            "round_5": 3,
            "round_6": 2,
            "round_7": 7,
            "round_8": 10,
            "round_9": 8,
            "round_10": 6,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 5,
            "team": "McLaren",
            "driver_name": "RPM_Higgzy",
            "round_1": 5,
            "round_2": 20,
            "round_3": 20,
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": 14,
            "round_7": 6,
            "round_8": 7,
            "round_9": 11,
            "round_10": 11,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 6,
            "team": "Aston Martin",
            "driver_name": "maggot361975",
            "round_1": 4,
            "round_2": 7,
            "round_3": 10,
            "round_4": 4,
            "round_5": 17,
            "round_6": 7,
            "round_7": 8,
            "round_8": 4,
            "round_9": 14,
            "round_10": 9,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 19,
            "team": "Aston Martin",
            "driver_name": "CPI_Mango",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": 2,
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 13,
            "team": "Alpine",
            "driver_name": "RPM_Dragos",
            "round_1": 8,
            "round_2": 13,
            "round_3": 4,
            "round_4": 13,
            "round_5": 14,
            "round_6": 17,
            "round_7": 9,
            "round_8": 12,
            "round_9": 7,
            "round_10": 16,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 17,
            "team": "Alpine",
            "driver_name": "C_osmo90",
            "round_1": 14,
            "round_2": 16,
            "round_3": 15,
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": 12,
            "round_7": "DNS",
            "round_8": 17,
            "round_9": 16,
            "round_10": 17,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 15,
            "team": "Alpha Tauri",
            "driver_name": "Sminotla789",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": 6,
            "round_7": 10,
            "round_8": 8,
            "round_9": 5,
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 18,
            "team": "Alpha Tauri",
            "driver_name": "Juul_Warrior_",
            "round_1": 7,
            "round_2": 8,
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": 13,
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 7,
            "team": "Alfa Romeo",
            "driver_name": "Davie_w",
            "round_1": 6,
            "round_2": 3,
            "round_3": 6,
            "round_4": 5,
            "round_5": 4,
            "round_6": 1,
            "round_7": 3,
            "round_8": 6,
            "round_9": 3,
            "round_10": 3,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 8,
            "team": "Alfa Romeo",
            "driver_name": "RPM_husssrc",
            "round_1": 13,
            "round_2": 12,
            "round_3": 5,
            "round_4": 12,
            "round_5": 11,
            "round_6": 9,
            "round_7": 11,
            "round_8": 13,
            "round_9": 6,
            "round_10": 12,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 2,
            "team": "Haas",
            "driver_name": "RVL_Volcano",
            "round_1": "DNS",
            "round_2": 5,
            "round_3": 1,
            "round_4": 3,
            "round_5": 2,
            "round_6": 5,
            "round_7": 4,
            "round_8": 5,
            "round_9": "DNS",
            "round_10": 7,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 20,
            "team": "Haas",
            "driver_name": "ABV",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 14,
            "team": "Williams",
            "driver_name": "RPM_Entity123",
            "round_1": 12,
            "round_2": 15,
            "round_3": 14,
            "round_4": "DNS",
            "round_5": 12,
            "round_6": 8,
            "round_7": 12,
            "round_8": 9,
            "round_9": "DNS",
            "round_10": 14,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 16,
            "team": "Williams",
            "driver_name": "Giraffebha",
            "round_1": 9,
            "round_2": 11,
            "round_3": 2,
            "round_4": "DNS",
            "round_5": 13,
            "round_6": 16,
            "round_7": 15,
            "round_8": 11,
            "round_9": "DNS",
            "round_10": 10,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        }
    ]);
    const [driverQualiChamp, setDriverQualiChamp] = useState([
        {
            "official_rank": 1,
            "team": "Mercedes",
            "driver_name": "Jetfish11",
            "round_1": 3,
            "round_2": 1,
            "round_3": 1,
            "round_4": 3,
            "round_5": 2,
            "round_6": 2,
            "round_7": 2,
            "round_8": 4,
            "round_9": 2,
            "round_10": 2,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 2,
            "team": "Mercedes",
            "driver_name": "AL2737",
            "round_1": 6,
            "round_2": 2,
            "round_3": 2,
            "round_4": 6,
            "round_5": 5,
            "round_6": "DNS",
            "round_7": 4,
            "round_8": 6,
            "round_9": 1,
            "round_10": 1,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 7,
            "team": "Red Bull",
            "driver_name": "LVIatt",
            "round_1": 4,
            "round_2": 5,
            "round_3": 5,
            "round_4": 7,
            "round_5": "DNS",
            "round_6": 4,
            "round_7": 1,
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 16,
            "team": "Red Bull",
            "driver_name": "Seat Available",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 3,
            "team": "Ferrari",
            "driver_name": "Stebo__",
            "round_1": 2,
            "round_2": 3,
            "round_3": 9,
            "round_4": 1,
            "round_5": 1,
            "round_6": 8,
            "round_7": "DNS",
            "round_8": 1,
            "round_9": 10,
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 11,
            "team": "Ferrari",
            "driver_name": "RPM_BAKKER1194",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": 6,
            "round_10": 8,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 4,
            "team": "McLaren",
            "driver_name": "Kieran0064",
            "round_1": 8,
            "round_2": 4,
            "round_3": 7,
            "round_4": 10,
            "round_5": 6,
            "round_6": 6,
            "round_7": 3,
            "round_8": 7,
            "round_9": 7,
            "round_10": 9,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 5,
            "team": "McLaren",
            "driver_name": "Booty1973",
            "round_1": 10,
            "round_2": 6,
            "round_3": 4,
            "round_4": 9,
            "round_5": 10,
            "round_6": 5,
            "round_7": "DNS",
            "round_8": 5,
            "round_9": 3,
            "round_10": 5,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 17,
            "team": "Aston Martin",
            "driver_name": "UnluckyFC__",
            "round_1": "*",
            "round_2": "*",
            "round_3": "*",
            "round_4": "*",
            "round_5": "*",
            "round_6": "*",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 13,
            "team": "Aston Martin",
            "driver_name": "Fragiletunnel",
            "round_1": "*",
            "round_2": "*",
            "round_3": "*",
            "round_4": "*",
            "round_5": "*",
            "round_6": "*",
            "round_7": 5,
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 18,
            "team": "Alpine",
            "driver_name": "Brocki4",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 15,
            "team": "Alpine",
            "driver_name": "Benpaines_",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 6,
            "team": "Alpha Tauri",
            "driver_name": "DZ Karting",
            "round_1": "*",
            "round_2": "*",
            "round_3": 6,
            "round_4": 2,
            "round_5": 4,
            "round_6": 3,
            "round_7": 9,
            "round_8": 2,
            "round_9": 5,
            "round_10": 5,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 19,
            "team": "Alpha Tauri",
            "driver_name": "Seat Available",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 8,
            "team": "Alfa Romeo",
            "driver_name": "RustyOilcan",
            "round_1": 7,
            "round_2": 7,
            "round_3": 11,
            "round_4": 8,
            "round_5": "DNS",
            "round_6": 10,
            "round_7": "DNS",
            "round_8": 9,
            "round_9": "DNS",
            "round_10": 9,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 12,
            "team": "Alfa Romeo",
            "driver_name": "RPM_Zabijaka420",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": 4,
            "round_10": 7,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 10,
            "team": "Haas",
            "driver_name": "RPM_Stubbe",
            "round_1": 5,
            "round_2": 8,
            "round_3": "DNF",
            "round_4": 4,
            "round_5": 7,
            "round_6": 1,
            "round_7": "DNS",
            "round_8": 8,
            "round_9": 9,
            "round_10": 6,
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 9,
            "team": "Haas",
            "driver_name": "lost_glaedr",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": 8,
            "round_4": 5,
            "round_5": 3,
            "round_6": 7,
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 14,
            "team": "Williams",
            "driver_name": "Seat Available",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        },
        {
            "official_rank": 20,
            "team": "Williams",
            "driver_name": "Seat Available",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "*",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
        }
    ]);
    const [driverQualisFirst, setDriverQualisFirst] = useState([
        {
            "official_rank": 3,
            "team": "Mercedes",
            "driver_name": "smit1717",
            "round_1": 6,
            "round_2": 8,
            "round_3": 3,
            "round_4": 8,
            "round_5": 1,
            "round_6": 3,
            "round_7": 12,
            "round_8": 1,
            "round_9": 11,
            "round_10": 1,
            "round_11": 1,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 12,
            "team": "Mercedes",
            "driver_name": "Spivey-CJ",
            "round_1": 15,
            "round_2": 17,
            "round_3": 12,
            "round_4": 17,
            "round_5": 19,
            "round_6": 16,
            "round_7": 9,
            "round_8": 15,
            "round_9": 15,
            "round_10": 14,
            "round_11": 13,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 2,
            "team": "Red Bull",
            "driver_name": "Rycka9",
            "round_1": 2,
            "round_2": 5,
            "round_3": 5,
            "round_4": 5,
            "round_5": 18,
            "round_6": 7,
            "round_7": 7,
            "round_8": 4,
            "round_9": 6,
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 14,
            "team": "Red Bull",
            "driver_name": "Callum0064",
            "round_1": 10,
            "round_2": 6,
            "round_3": 1,
            "round_4": 10,
            "round_5": 5,
            "round_6": 10,
            "round_7": "DNS",
            "round_8": 11,
            "round_9": 1,
            "round_10": 6,
            "round_11": 10,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 7,
            "team": "Ferrari",
            "driver_name": "RPM_Danos",
            "round_1": 3,
            "round_2": 11,
            "round_3": 14,
            "round_4": 4,
            "round_5": 7,
            "round_6": 12,
            "round_7": 4,
            "round_8": 5,
            "round_9": 17,
            "round_10": 2,
            "round_11": 6,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 16,
            "team": "Ferrari",
            "driver_name": "RPM_Ijver",
            "round_1": 14,
            "round_2": 15,
            "round_3": 13,
            "round_4": 13,
            "round_5": 12,
            "round_6": 5,
            "round_7": 8,
            "round_8": 16,
            "round_9": 14,
            "round_10": 13,
            "round_11": 11,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 17,
            "team": "McLaren",
            "driver_name": "Scooterclem10",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": 9,
            "round_6": 11,
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": 3,
            "round_10": 7,
            "round_11": 15,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 8,
            "team": "McLaren",
            "driver_name": "Snellehenk73",
            "round_1": 13,
            "round_2": 12,
            "round_3": 10,
            "round_4": 15,
            "round_5": 13,
            "round_6": 2,
            "round_7": 10,
            "round_8": 9,
            "round_9": 8,
            "round_10": 11,
            "round_11": 8,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 20,
            "team": "Aston Martin",
            "driver_name": "Mocky1480",
            "round_1": "*",
            "round_2": "*",
            "round_3": "*",
            "round_4": "*",
            "round_5": "*",
            "round_6": "*",
            "round_7": "*",
            "round_8": 12,
            "round_9": "DNS",
            "round_10": 16,
            "round_11": 12,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 10,
            "team": "Aston Martin",
            "driver_name": "RPM_jones58",
            "round_1": 9,
            "round_2": 7,
            "round_3": 8,
            "round_4": 19,
            "round_5": 16,
            "round_6": "DNS",
            "round_7": 6,
            "round_8": 6,
            "round_9": 12,
            "round_10": 9,
            "round_11": 5,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 4,
            "team": "Alpine",
            "driver_name": "RTRodo",
            "round_1": 4,
            "round_2": 2,
            "round_3": 15,
            "round_4": 3,
            "round_5": 3,
            "round_6": 15,
            "round_7": 2,
            "round_8": 8,
            "round_9": 10,
            "round_10": 4,
            "round_11": 4,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 18,
            "team": "Alpine",
            "driver_name": "Philmcconachie",
            "round_1": 7,
            "round_2": 18,
            "round_3": 6,
            "round_4": 11,
            "round_5": 14,
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 19,
            "team": "Alpha Tauri",
            "driver_name": "Goee14",
            "round_1": 17,
            "round_2": 14,
            "round_3": 12,
            "round_4": 14,
            "round_5": "DNS",
            "round_6": 8,
            "round_7": 14,
            "round_8": 18,
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 11,
            "team": "Alpha Tauri",
            "driver_name": "Reuze_Beertje",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": 9,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 5,
            "team": "Alfa Romeo",
            "driver_name": "F1-Dino-F1",
            "round_1": 11,
            "round_2": "DNS",
            "round_3": 4,
            "round_4": 7,
            "round_5": 6,
            "round_6": 1,
            "round_7": 5,
            "round_8": 7,
            "round_9": 7,
            "round_10": 8,
            "round_11": "DNS",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 13,
            "team": "Alfa Romeo",
            "driver_name": "Inorganic",
            "round_1": 8,
            "round_2": 13,
            "round_3": 9,
            "round_4": 12,
            "round_5": 15,
            "round_6": 13,
            "round_7": 11,
            "round_8": 17,
            "round_9": 9,
            "round_10": 12,
            "round_11": 14,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 6,
            "team": "Haas",
            "driver_name": "Matty347",
            "round_1": 1,
            "round_2": 3,
            "round_3": "DNS",
            "round_4": 2,
            "round_5": 4,
            "round_6": 9,
            "round_7": 3,
            "round_8": 3,
            "round_9": 2,
            "round_10": 5,
            "round_11": 3,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 9,
            "team": "Haas",
            "driver_name": "Jleeky7",
            "round_1": 12,
            "round_2": 10,
            "round_3": "DNS",
            "round_4": 9,
            "round_5": 10,
            "round_6": 6,
            "round_7": "DNS",
            "round_8": 10,
            "round_9": "DNS",
            "round_10": 10,
            "round_11": 7,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 15,
            "team": "Williams",
            "driver_name": "themightyfrax",
            "round_1": 16,
            "round_2": 16,
            "round_3": 17,
            "round_4": 18,
            "round_5": 13,
            "round_6": 17,
            "round_7": "DNS",
            "round_8": 13,
            "round_9": 5,
            "round_10": 15,
            "round_11": "DNS",
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 1,
            "team": "Williams",
            "driver_name": "N4T_P4CK3R5",
            "round_1": "DNS",
            "round_2": 1,
            "round_3": 7,
            "round_4": 1,
            "round_5": 2,
            "round_6": 4,
            "round_7": 1,
            "round_8": 2,
            "round_9": 4,
            "round_10": 3,
            "round_11": 2,
            "round_12": "*",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        }
    ]);

    const [driverStatsFirst, setDriverStatsFirst] = useState([
        {
            "driver_name": "smit1717",
            "starts": 12,
            "wins": 1,
            "podiums": 4,
            "pole": 4,
            "fastest_lap": 2,
            "top_five": 7,
            "points": 288,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 5,
            "avg_start": 5,
            "total_positions_gained": -3,
            "avg_positions_gained": 0,
            "dotw": 1,
            "win_percentage": "8%",
            "pole_percentage": "33%",
            "podium_percentage": "33%",
            "top_five_percentage": "58%",
            "dnf_percentage": "0%",
            "points_per_race": 24
        },
        {
            "driver_name": "Spivey-CJ",
            "starts": 12,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 77,
            "penalty_points": 2,
            "dnf": 0,
            "best_finish": 7,
            "best_quali": 9,
            "avg_finish": 11,
            "avg_start": 15,
            "total_positions_gained": 41,
            "avg_positions_gained": 3,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "0%",
            "points_per_race": 6.42
        },
        {
            "driver_name": "Rycka9",
            "starts": 9,
            "wins": 1,
            "podiums": 5,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 7,
            "points": 252,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 1,
            "best_quali": 2,
            "avg_finish": 4,
            "avg_start": 7,
            "total_positions_gained": 24,
            "avg_positions_gained": 3,
            "dotw": "",
            "win_percentage": "11%",
            "pole_percentage": "0%",
            "podium_percentage": "56%",
            "top_five_percentage": "78%",
            "dnf_percentage": "0%",
            "points_per_race": 28
        },
        {
            "driver_name": "Callum0064",
            "starts": 11,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 82,
            "penalty_points": 0,
            "dnf": 5,
            "best_finish": 6,
            "best_quali": 1,
            "avg_finish": 9,
            "avg_start": 7,
            "total_positions_gained": -80,
            "avg_positions_gained": -7,
            "dotw": 1,
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "45%",
            "points_per_race": 7.45
        },
        {
            "driver_name": "RPM_Danos",
            "starts": 12,
            "wins": 0,
            "podiums": 3,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 7,
            "points": 213,
            "penalty_points": 2,
            "dnf": 2,
            "best_finish": 2,
            "best_quali": 2,
            "avg_finish": 6,
            "avg_start": 8,
            "total_positions_gained": 7,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "25%",
            "top_five_percentage": "58%",
            "dnf_percentage": "17%",
            "points_per_race": 17.75
        },
        {
            "driver_name": "RPM_Ijver",
            "starts": 12,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 34,
            "penalty_points": 4,
            "dnf": 4,
            "best_finish": 10,
            "best_quali": 5,
            "avg_finish": 12,
            "avg_start": 12,
            "total_positions_gained": -35,
            "avg_positions_gained": -3,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "33%",
            "points_per_race": 2.83
        },
        {
            "driver_name": "Schnitzy91",
            "starts": 6,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 28,
            "penalty_points": 0,
            "dnf": 2,
            "best_finish": 8,
            "best_quali": 3,
            "avg_finish": 12,
            "avg_start": 10,
            "total_positions_gained": -21,
            "avg_positions_gained": -4,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "33%",
            "points_per_race": 4.67
        },
        {
            "driver_name": "Snellehenk73",
            "starts": 12,
            "wins": 0,
            "podiums": 1,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 2,
            "points": 168,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 3,
            "best_quali": 2,
            "avg_finish": 8,
            "avg_start": 10,
            "total_positions_gained": 9,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "8%",
            "top_five_percentage": "17%",
            "dnf_percentage": "8%",
            "points_per_race": 14
        },
        {
            "driver_name": "Mocky1480",
            "starts": 3,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 12,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 11,
            "best_quali": 12,
            "avg_finish": 12,
            "avg_start": 13,
            "total_positions_gained": 3,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "0%",
            "points_per_race": 4
        },
        {
            "driver_name": "RPM_jones58",
            "starts": 10,
            "wins": 0,
            "podiums": 1,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 2,
            "points": 148,
            "penalty_points": 2,
            "dnf": 1,
            "best_finish": 3,
            "best_quali": 1,
            "avg_finish": 7,
            "avg_start": 9,
            "total_positions_gained": 11,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "10%",
            "top_five_percentage": "20%",
            "dnf_percentage": "10%",
            "points_per_race": 14.8
        },
        {
            "driver_name": "RTRodo",
            "starts": 12,
            "wins": 0,
            "podiums": 3,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 7,
            "points": 245,
            "penalty_points": 3,
            "dnf": 1,
            "best_finish": 2,
            "best_quali": 2,
            "avg_finish": 5,
            "avg_start": 6,
            "total_positions_gained": -1,
            "avg_positions_gained": 0,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "25%",
            "top_five_percentage": "58%",
            "dnf_percentage": "8%",
            "points_per_race": 20.42
        },
        {
            "driver_name": "Philmcconachie",
            "starts": 5,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 0,
            "points": 19,
            "penalty_points": 3,
            "dnf": 3,
            "best_finish": 6,
            "best_quali": 6,
            "avg_finish": 11,
            "avg_start": 11,
            "total_positions_gained": -26,
            "avg_positions_gained": -5,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "60%",
            "points_per_race": 3.8
        },
        {
            "driver_name": "Goee14",
            "starts": 7,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 12,
            "penalty_points": 2,
            "dnf": 5,
            "best_finish": 10,
            "best_quali": 8,
            "avg_finish": 11,
            "avg_start": 14,
            "total_positions_gained": -25,
            "avg_positions_gained": -4,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "71%",
            "points_per_race": 1.71
        },
        {
            "driver_name": "Reuze_Beertje",
            "starts": 1,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 1,
            "points": 34,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 4,
            "best_quali": 9,
            "avg_finish": 7,
            "avg_start": 16,
            "total_positions_gained": 7,
            "avg_positions_gained": 4,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "100%",
            "dnf_percentage": "0%",
            "points_per_race": 34
        },
        {
            "driver_name": "F1-Dino-F1",
            "starts": 11,
            "wins": 1,
            "podiums": 4,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 7,
            "points": 233,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 5,
            "avg_start": 6,
            "total_positions_gained": 4,
            "avg_positions_gained": 0,
            "dotw": 1,
            "win_percentage": "9%",
            "pole_percentage": "0%",
            "podium_percentage": "36%",
            "top_five_percentage": "64%",
            "dnf_percentage": "9%",
            "points_per_race": 21.18
        },
        {
            "driver_name": "Inorganic",
            "starts": 12,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 1,
            "points": 85,
            "penalty_points": 4,
            "dnf": 2,
            "best_finish": 5,
            "best_quali": 8,
            "avg_finish": 11,
            "avg_start": 12,
            "total_positions_gained": -3,
            "avg_positions_gained": 0,
            "dotw": 2,
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "8%",
            "dnf_percentage": "17%",
            "points_per_race": 7.08
        },
        {
            "driver_name": "Matty347",
            "starts": 11,
            "wins": 3,
            "podiums": 5,
            "pole": 1,
            "fastest_lap": 1,
            "top_five": 6,
            "points": 240,
            "penalty_points": 1,
            "dnf": 1,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 6,
            "avg_start": 3,
            "total_positions_gained": -41,
            "avg_positions_gained": -4,
            "dotw": 1,
            "win_percentage": "27%",
            "pole_percentage": "9%",
            "podium_percentage": "45%",
            "top_five_percentage": "55%",
            "dnf_percentage": "9%",
            "points_per_race": 21.82
        },
        {
            "driver_name": "Jleeky7",
            "starts": 9,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 2,
            "points": 159,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 5,
            "best_quali": 6,
            "avg_finish": 6,
            "avg_start": 10,
            "total_positions_gained": 30,
            "avg_positions_gained": 3,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "22%",
            "dnf_percentage": "0%",
            "points_per_race": 17.67
        },
        {
            "driver_name": "themightyfrax",
            "starts": 8,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 42,
            "penalty_points": 0,
            "dnf": 3,
            "best_finish": 8,
            "best_quali": 5,
            "avg_finish": 11,
            "avg_start": 14,
            "total_positions_gained": 2,
            "avg_positions_gained": 0,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "38%",
            "points_per_race": 5.25
        },
        {
            "driver_name": "N4T_P4CK3R5",
            "starts": 11,
            "wins": 6,
            "podiums": 9,
            "pole": 3,
            "fastest_lap": 4,
            "top_five": 9,
            "points": 358,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 3,
            "avg_start": 3,
            "total_positions_gained": -13,
            "avg_positions_gained": -1,
            "dotw": 1,
            "win_percentage": "55%",
            "pole_percentage": "27%",
            "podium_percentage": "82%",
            "top_five_percentage": "82%",
            "dnf_percentage": "9%",
            "points_per_race": 32.55
        }
    ]);
    const [driverStatsPrem, setDriverStatsPrem] = useState([
        {
            "driver_name": "RPM_KingPinquinn",
            "starts": 10,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 2,
            "points": 117,
            "penalty_points": 1,
            "dnf": 2,
            "best_finish": 4,
            "best_quali": 6,
            "avg_finish": 8,
            "avg_start": 12,
            "total_positions_gained": 26,
            "avg_positions_gained": 3,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "20%",
            "dnf_percentage": "20%",
            "points_per_race": 11.7
        },
        {
            "driver_name": "RPM_Sinfull",
            "starts": 10,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 103,
            "penalty_points": 2,
            "dnf": 1,
            "best_finish": 6,
            "best_quali": 3,
            "avg_finish": 9,
            "avg_start": 12,
            "total_positions_gained": 28,
            "avg_positions_gained": 3,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "10%",
            "points_per_race": 10.3
        },
        {
            "driver_name": "ET8_Evil",
            "starts": 3,
            "wins": 1,
            "podiums": 3,
            "pole": 2,
            "fastest_lap": 1,
            "top_five": 3,
            "points": 119,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 2,
            "avg_start": 1,
            "total_positions_gained": -2,
            "avg_positions_gained": -1,
            "dotw": 1,
            "win_percentage": "33%",
            "pole_percentage": "67%",
            "podium_percentage": "100%",
            "top_five_percentage": "100%",
            "dnf_percentage": "0%",
            "points_per_race": 39.67
        },
        {
            "driver_name": "AndrewX2905",
            "starts": 10,
            "wins": 0,
            "podiums": 3,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 6,
            "points": 198,
            "penalty_points": 4,
            "dnf": 2,
            "best_finish": 2,
            "best_quali": 2,
            "avg_finish": 5,
            "avg_start": 6,
            "total_positions_gained": -9,
            "avg_positions_gained": -1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "30%",
            "top_five_percentage": "60%",
            "dnf_percentage": "20%",
            "points_per_race": 19.8
        },
        {
            "driver_name": "RPM_Colin-86",
            "starts": 10,
            "wins": 9,
            "podiums": 10,
            "pole": 5,
            "fastest_lap": 3,
            "top_five": 10,
            "points": 403,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 1,
            "avg_start": 3,
            "total_positions_gained": 21,
            "avg_positions_gained": 2,
            "dotw": 3,
            "win_percentage": "90%",
            "pole_percentage": "50%",
            "podium_percentage": "100%",
            "top_five_percentage": "100%",
            "dnf_percentage": "0%",
            "points_per_race": 40.3
        },
        {
            "driver_name": "RPM_Luukvdm",
            "starts": 8,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 100,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 6,
            "best_quali": 3,
            "avg_finish": 8,
            "avg_start": 10,
            "total_positions_gained": 5,
            "avg_positions_gained": 1,
            "dotw": 1,
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "13%",
            "points_per_race": 12.5
        },
        {
            "driver_name": "RPM_Glassy",
            "starts": 10,
            "wins": 0,
            "podiums": 2,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 4,
            "points": 179,
            "penalty_points": 4,
            "dnf": 0,
            "best_finish": 3,
            "best_quali": 2,
            "avg_finish": 6,
            "avg_start": 6,
            "total_positions_gained": 1,
            "avg_positions_gained": 0,
            "dotw": 1,
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "20%",
            "top_five_percentage": "40%",
            "dnf_percentage": "0%",
            "points_per_race": 17.9
        },
        {
            "driver_name": "RPM_Higgzy",
            "starts": 8,
            "wins": 0,
            "podiums": 2,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 4,
            "points": 160,
            "penalty_points": 2,
            "dnf": 0,
            "best_finish": 3,
            "best_quali": 5,
            "avg_finish": 6,
            "avg_start": 12,
            "total_positions_gained": 46,
            "avg_positions_gained": 6,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "25%",
            "top_five_percentage": "50%",
            "dnf_percentage": "0%",
            "points_per_race": 20
        },
        {
            "driver_name": "Maggot",
            "starts": 10,
            "wins": 0,
            "podiums": 3,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 4,
            "points": 156,
            "penalty_points": 0,
            "dnf": 2,
            "best_finish": 2,
            "best_quali": 4,
            "avg_finish": 7,
            "avg_start": 8,
            "total_positions_gained": 0,
            "avg_positions_gained": 0,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "30%",
            "top_five_percentage": "40%",
            "dnf_percentage": "20%",
            "points_per_race": 15.6
        },
        {
            "driver_name": "Mango",
            "starts": 1,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 4,
            "penalty_points": "",
            "dnf": 1,
            "best_finish": "",
            "best_quali": 2,
            "avg_finish": "",
            "avg_start": 2,
            "total_positions_gained": -15,
            "avg_positions_gained": -8,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "100%",
            "points_per_race": 4
        },
        {
            "driver_name": "RPM_Dragos",
            "starts": 10,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 81,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 7,
            "best_quali": 4,
            "avg_finish": 10,
            "avg_start": 11,
            "total_positions_gained": 10,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "10%",
            "points_per_race": 8.1
        },
        {
            "driver_name": "Rpm_Cosmo",
            "starts": 7,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 21,
            "penalty_points": 0,
            "dnf": 3,
            "best_finish": 9,
            "best_quali": 12,
            "avg_finish": 12,
            "avg_start": 15,
            "total_positions_gained": 8,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "43%",
            "points_per_race": 3
        },
        {
            "driver_name": "Sminotla789",
            "starts": 4,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 1,
            "points": 50,
            "penalty_points": 2,
            "dnf": 1,
            "best_finish": 4,
            "best_quali": 5,
            "avg_finish": 7,
            "avg_start": 7,
            "total_positions_gained": -10,
            "avg_positions_gained": -2,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "25%",
            "dnf_percentage": "25%",
            "points_per_race": 12.5
        },
        {
            "driver_name": "Juul_Warrior_",
            "starts": 3,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 0,
            "points": 8,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 11,
            "best_quali": 7,
            "avg_finish": 14,
            "avg_start": 9,
            "total_positions_gained": -17,
            "avg_positions_gained": -6,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "33%",
            "points_per_race": 2.67
        },
        {
            "driver_name": "Davie_w",
            "starts": 10,
            "wins": 0,
            "podiums": 3,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 4,
            "points": 164,
            "penalty_points": 0,
            "dnf": 3,
            "best_finish": 2,
            "best_quali": 1,
            "avg_finish": 4,
            "avg_start": 4,
            "total_positions_gained": -26,
            "avg_positions_gained": -3,
            "dotw": 1,
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "30%",
            "top_five_percentage": "40%",
            "dnf_percentage": "30%",
            "points_per_race": 16.4
        },
        {
            "driver_name": "RPM_husssrc",
            "starts": 10,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 3,
            "points": 127,
            "penalty_points": 5,
            "dnf": 1,
            "best_finish": 4,
            "best_quali": 5,
            "avg_finish": 8,
            "avg_start": 10,
            "total_positions_gained": 16,
            "avg_positions_gained": 2,
            "dotw": 1,
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "30%",
            "dnf_percentage": "10%",
            "points_per_race": 12.7
        },
        {
            "driver_name": "RVL_Volcano",
            "starts": 8,
            "wins": 0,
            "podiums": 4,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 7,
            "points": 213,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 2,
            "best_quali": 1,
            "avg_finish": 3,
            "avg_start": 4,
            "total_positions_gained": -6,
            "avg_positions_gained": -1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "50%",
            "top_five_percentage": "88%",
            "dnf_percentage": "13%",
            "points_per_race": 26.63
        },
        {
            "driver_name": "ABV",
            "starts": 0,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 0,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": "",
            "best_quali": "",
            "avg_finish": "",
            "avg_start": "",
            "total_positions_gained": "",
            "avg_positions_gained": "",
            "dotw": "",
            "win_percentage": "",
            "pole_percentage": "",
            "podium_percentage": "",
            "top_five_percentage": "",
            "dnf_percentage": "",
            "points_per_race": ""
        },
        {
            "driver_name": "RPM_Entity123",
            "starts": 8,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 1,
            "points": 62,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 5,
            "best_quali": 8,
            "avg_finish": 10,
            "avg_start": 12,
            "total_positions_gained": 8,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "13%",
            "dnf_percentage": "13%",
            "points_per_race": 7.75
        },
        {
            "driver_name": "Giraffebha",
            "starts": 8,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 1,
            "points": 50,
            "penalty_points": 0,
            "dnf": 2,
            "best_finish": 5,
            "best_quali": 2,
            "avg_finish": 11,
            "avg_start": 11,
            "total_positions_gained": -9,
            "avg_positions_gained": -1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "13%",
            "dnf_percentage": "25%",
            "points_per_race": 6.25
        }
    ]);
    const [driverStatsChamp, setDriverStatsChamp] = useState([
        {
            "driver_name": "Jetfish11",
            "starts": 10,
            "wins": 3,
            "podiums": 10,
            "pole": 1,
            "fastest_lap": 1,
            "top_five": 10,
            "points": 371,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 2,
            "avg_start": 2,
            "total_positions_gained": 3,
            "avg_positions_gained": 0,
            "dotw": 2,
            "win_percentage": "30%",
            "pole_percentage": "10%",
            "podium_percentage": "100%",
            "top_five_percentage": "100%",
            "dnf_percentage": "0%",
            "points_per_race": 37.1
        },
        {
            "driver_name": "AL2737",
            "starts": 9,
            "wins": 4,
            "podiums": 8,
            "pole": 1,
            "fastest_lap": 2,
            "top_five": 9,
            "points": 326,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 2,
            "avg_start": 4,
            "total_positions_gained": 14,
            "avg_positions_gained": 2,
            "dotw": "",
            "win_percentage": "44%",
            "pole_percentage": "11%",
            "podium_percentage": "89%",
            "top_five_percentage": "100%",
            "dnf_percentage": "0%",
            "points_per_race": 36.22
        },
        {
            "driver_name": "LVIatt",
            "starts": 6,
            "wins": 0,
            "podiums": 1,
            "pole": 1,
            "fastest_lap": 1,
            "top_five": 4,
            "points": 127,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 3,
            "best_quali": 1,
            "avg_finish": 5,
            "avg_start": 4,
            "total_positions_gained": -7,
            "avg_positions_gained": -1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "17%",
            "podium_percentage": "17%",
            "top_five_percentage": "67%",
            "dnf_percentage": "17%",
            "points_per_race": 21.17
        },
        {
            "driver_name": "Seat Available",
            "starts": 0,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 0,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": "",
            "best_quali": "",
            "avg_finish": "",
            "avg_start": "",
            "total_positions_gained": "",
            "avg_positions_gained": "",
            "dotw": "",
            "win_percentage": "",
            "pole_percentage": "",
            "podium_percentage": "",
            "top_five_percentage": "",
            "dnf_percentage": "",
            "points_per_race": ""
        },
        {
            "driver_name": "Stebo__",
            "starts": 8,
            "wins": 2,
            "podiums": 6,
            "pole": 4,
            "fastest_lap": 1,
            "top_five": 8,
            "points": 259,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 3,
            "avg_start": 4,
            "total_positions_gained": 13,
            "avg_positions_gained": 2,
            "dotw": 2,
            "win_percentage": "25%",
            "pole_percentage": "50%",
            "podium_percentage": "75%",
            "top_five_percentage": "100%",
            "dnf_percentage": "0%",
            "points_per_race": 32.38
        },
        {
            "driver_name": "RPM_BAKKER1194",
            "starts": 2,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 0,
            "points": 40,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 6,
            "best_quali": 6,
            "avg_finish": 6,
            "avg_start": 7,
            "total_positions_gained": 2,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "0%",
            "points_per_race": 20
        },
        {
            "driver_name": "Kieran0064",
            "starts": 10,
            "wins": 0,
            "podiums": 1,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 6,
            "points": 219,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 2,
            "best_quali": 3,
            "avg_finish": 5,
            "avg_start": 7,
            "total_positions_gained": 14,
            "avg_positions_gained": 1,
            "dotw": 1,
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "10%",
            "top_five_percentage": "60%",
            "dnf_percentage": "0%",
            "points_per_race": 21.9
        },
        {
            "driver_name": "Booty1973",
            "starts": 9,
            "wins": 0,
            "podiums": 2,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 4,
            "points": 171,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 2,
            "best_quali": 3,
            "avg_finish": 6,
            "avg_start": 6,
            "total_positions_gained": 1,
            "avg_positions_gained": 0,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "22%",
            "top_five_percentage": "44%",
            "dnf_percentage": "11%",
            "points_per_race": 19
        },
        {
            "driver_name": "UnluckyFC__",
            "starts": 0,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 0,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": "",
            "best_quali": "",
            "avg_finish": "",
            "avg_start": "",
            "total_positions_gained": "",
            "avg_positions_gained": "",
            "dotw": "",
            "win_percentage": "",
            "pole_percentage": "",
            "podium_percentage": "",
            "top_five_percentage": "",
            "dnf_percentage": "",
            "points_per_race": ""
        },
        {
            "driver_name": "Fragiletunnel",
            "starts": 1,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 0,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": "",
            "best_quali": 5,
            "avg_finish": "",
            "avg_start": 5,
            "total_positions_gained": -5,
            "avg_positions_gained": -5,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "100%",
            "points_per_race": 0
        },
        {
            "driver_name": "Brocki4",
            "starts": 0,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 0,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": "",
            "best_quali": "",
            "avg_finish": "",
            "avg_start": "",
            "total_positions_gained": "",
            "avg_positions_gained": "",
            "dotw": "",
            "win_percentage": "",
            "pole_percentage": "",
            "podium_percentage": "",
            "top_five_percentage": "",
            "dnf_percentage": "",
            "points_per_race": ""
        },
        {
            "driver_name": "Benpaines_",
            "starts": 0,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 0,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": "",
            "best_quali": "",
            "avg_finish": "",
            "avg_start": "",
            "total_positions_gained": "",
            "avg_positions_gained": "",
            "dotw": "",
            "win_percentage": "",
            "pole_percentage": "",
            "podium_percentage": "",
            "top_five_percentage": "",
            "dnf_percentage": "",
            "points_per_race": ""
        },
        {
            "driver_name": "DZ Karting",
            "starts": 8,
            "wins": 0,
            "podiums": 1,
            "pole": 0,
            "fastest_lap": 2,
            "top_five": 4,
            "points": 130,
            "penalty_points": 6,
            "dnf": 2,
            "best_finish": 3,
            "best_quali": 2,
            "avg_finish": 6,
            "avg_start": 4,
            "total_positions_gained": -21,
            "avg_positions_gained": -3,
            "dotw": 1,
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "13%",
            "top_five_percentage": "50%",
            "dnf_percentage": "25%",
            "points_per_race": 16.25
        },
        {
            "driver_name": "Seat Available",
            "starts": 0,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 0,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": "",
            "best_quali": "",
            "avg_finish": "",
            "avg_start": "",
            "total_positions_gained": "",
            "avg_positions_gained": "",
            "dotw": "",
            "win_percentage": "",
            "pole_percentage": "",
            "podium_percentage": "",
            "top_five_percentage": "",
            "dnf_percentage": "",
            "points_per_race": ""
        },
        {
            "driver_name": "RustyOilcan",
            "starts": 7,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 67,
            "penalty_points": 0,
            "dnf": 3,
            "best_finish": 6,
            "best_quali": 7,
            "avg_finish": 7,
            "avg_start": 9,
            "total_positions_gained": -6,
            "avg_positions_gained": -1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "43%",
            "points_per_race": 9.57
        },
        {
            "driver_name": "RPM_Zabijaka420",
            "starts": 2,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 1,
            "points": 41,
            "penalty_points": 3,
            "dnf": 0,
            "best_finish": 4,
            "best_quali": 4,
            "avg_finish": 6,
            "avg_start": 6,
            "total_positions_gained": -1,
            "avg_positions_gained": -1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "50%",
            "dnf_percentage": "0%",
            "points_per_race": 20.5
        },
        {
            "driver_name": "RPM_Stubbe",
            "starts": 9,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 60,
            "penalty_points": 0,
            "dnf": 5,
            "best_finish": 6,
            "best_quali": 1,
            "avg_finish": 8,
            "avg_start": 6,
            "total_positions_gained": -43,
            "avg_positions_gained": -5,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "56%",
            "points_per_race": 6.67
        },
        {
            "driver_name": "lost_glaedr",
            "starts": 4,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 1,
            "points": 45,
            "penalty_points": 2,
            "dnf": 1,
            "best_finish": 4,
            "best_quali": 3,
            "avg_finish": 8,
            "avg_start": 6,
            "total_positions_gained": -10,
            "avg_positions_gained": -3,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "25%",
            "dnf_percentage": "25%",
            "points_per_race": 11.25
        },
        {
            "driver_name": "Seat Available",
            "starts": 1,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 0,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 0,
            "best_quali": 1,
            "avg_finish": "#DIV/0!",
            "avg_start": 1,
            "total_positions_gained": "#VALUE!",
            "avg_positions_gained": "#VALUE!",
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "0%",
            "points_per_race": 0
        },
        {
            "driver_name": "Seat Available",
            "starts": "",
            "wins": "",
            "podiums": "",
            "pole": "",
            "fastest_lap": "",
            "top_five": "",
            "points": "",
            "penalty_points": "",
            "dnf": "",
            "best_finish": "",
            "best_quali": "",
            "avg_finish": "",
            "avg_start": "",
            "total_positions_gained": "",
            "avg_positions_gained": "",
            "dotw": "",
            "win_percentage": "",
            "pole_percentage": "",
            "podium_percentage": "",
            "top_five_percentage": "",
            "dnf_percentage": "",
            "points_per_race": ""
        }
    ]);

    const [lapsLead, setLapsLead] = useState([
        {
            "driver_name": "N4T_P4CK3R5",
            "laps_lead": 155
        },
        {
            "driver_name": "matty347",
            "laps_lead": 54
        },
        {
            "driver_name": "Callum0064",
            "laps_lead": 32
        },
        {
            "driver_name": "RTRodo",
            "laps_lead": 22
        },
        {
            "driver_name": "Rycka9",
            "laps_lead": 14
        },
        {
            "driver_name": "f1-dino-f1",
            "laps_lead": 14
        },
        {
            "driver_name": "BigPuggy",
            "laps_lead": 10
        },
        {
            "driver_name": "inorganic",
            "laps_lead": 10
        },
        {
            "driver_name": "smit1717",
            "laps_lead": 6
        },
        {
            "driver_name": "goee",
            "laps_lead": 4
        },
        {
            "driver_name": "scooterclem",
            "laps_lead": 3
        },
        {
            "driver_name": "snellehenk",
            "laps_lead": 2
        },
        {
            "driver_name": "mockey",
            "laps_lead": 1
        }
    ]);
    const [driverQualis, setDriverQualis] = useState([
        {
            "official_rank": 3,
            "team": "Mercedes",
            "driver_name": "smit1717",
            "round_1": 6,
            "round_2": 8,
            "round_3": 3,
            "round_4": 8,
            "round_5": 1,
            "round_6": 3,
            "round_7": 12,
            "round_8": 1,
            "round_9": 11,
            "round_10": 1,
            "round_11": 1,
            "round_12": 5,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 12,
            "team": "Mercedes",
            "driver_name": "Spivey-CJ",
            "round_1": 15,
            "round_2": 17,
            "round_3": 12,
            "round_4": 17,
            "round_5": 19,
            "round_6": 16,
            "round_7": 9,
            "round_8": 15,
            "round_9": 15,
            "round_10": 14,
            "round_11": 13,
            "round_12": 12,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 2,
            "team": "Red Bull",
            "driver_name": "Rycka9",
            "round_1": 2,
            "round_2": 5,
            "round_3": 5,
            "round_4": 5,
            "round_5": 18,
            "round_6": 7,
            "round_7": 7,
            "round_8": 4,
            "round_9": 6,
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": "DNS",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 14,
            "team": "Red Bull",
            "driver_name": "Callum0064",
            "round_1": 10,
            "round_2": 6,
            "round_3": 1,
            "round_4": 10,
            "round_5": 5,
            "round_6": 10,
            "round_7": "DNS",
            "round_8": 11,
            "round_9": 1,
            "round_10": 6,
            "round_11": 10,
            "round_12": 3,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 7,
            "team": "Ferrari",
            "driver_name": "RPM_Danos",
            "round_1": 3,
            "round_2": 11,
            "round_3": 14,
            "round_4": 4,
            "round_5": 7,
            "round_6": 12,
            "round_7": 4,
            "round_8": 5,
            "round_9": 17,
            "round_10": 2,
            "round_11": 6,
            "round_12": 13,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 16,
            "team": "Ferrari",
            "driver_name": "RPM_Ijver",
            "round_1": 14,
            "round_2": 15,
            "round_3": 13,
            "round_4": 13,
            "round_5": 12,
            "round_6": 5,
            "round_7": 8,
            "round_8": 16,
            "round_9": 14,
            "round_10": 13,
            "round_11": 11,
            "round_12": 10,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 17,
            "team": "McLaren",
            "driver_name": "Scooterclem10",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": 9,
            "round_6": 11,
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": 3,
            "round_10": 7,
            "round_11": 15,
            "round_12": 15,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 8,
            "team": "McLaren",
            "driver_name": "Snellehenk73",
            "round_1": 13,
            "round_2": 12,
            "round_3": 10,
            "round_4": 15,
            "round_5": 13,
            "round_6": 2,
            "round_7": 10,
            "round_8": 9,
            "round_9": 8,
            "round_10": 11,
            "round_11": 8,
            "round_12": 4,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 20,
            "team": "Aston Martin",
            "driver_name": "Mocky1480",
            "round_1": "*",
            "round_2": "*",
            "round_3": "*",
            "round_4": "*",
            "round_5": "*",
            "round_6": "*",
            "round_7": "*",
            "round_8": 12,
            "round_9": "DNS",
            "round_10": 16,
            "round_11": 12,
            "round_12": "DNS",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 10,
            "team": "Aston Martin",
            "driver_name": "RPM_jones58",
            "round_1": 9,
            "round_2": 7,
            "round_3": 8,
            "round_4": 19,
            "round_5": 16,
            "round_6": "DNS",
            "round_7": 6,
            "round_8": 6,
            "round_9": 12,
            "round_10": 9,
            "round_11": 5,
            "round_12": 1,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 4,
            "team": "Alpine",
            "driver_name": "RTRodo",
            "round_1": 4,
            "round_2": 2,
            "round_3": 15,
            "round_4": 3,
            "round_5": 3,
            "round_6": 15,
            "round_7": 2,
            "round_8": 8,
            "round_9": 10,
            "round_10": 4,
            "round_11": 4,
            "round_12": 7,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 18,
            "team": "Alpine",
            "driver_name": "Philmcconachie",
            "round_1": 7,
            "round_2": 18,
            "round_3": 6,
            "round_4": 11,
            "round_5": 14,
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": "DNS",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 19,
            "team": "Alpha Tauri",
            "driver_name": "Goee14",
            "round_1": 17,
            "round_2": 14,
            "round_3": 12,
            "round_4": 14,
            "round_5": "DNS",
            "round_6": 8,
            "round_7": 14,
            "round_8": 18,
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": "DNS",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 11,
            "team": "Alpha Tauri",
            "driver_name": "Reuze_Beertje",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": 9,
            "round_12": 12,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 5,
            "team": "Alfa Romeo",
            "driver_name": "F1-Dino-F1",
            "round_1": 11,
            "round_2": "DNS",
            "round_3": 4,
            "round_4": 7,
            "round_5": 6,
            "round_6": 1,
            "round_7": 5,
            "round_8": 7,
            "round_9": 7,
            "round_10": 8,
            "round_11": "DNS",
            "round_12": 8,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 13,
            "team": "Alfa Romeo",
            "driver_name": "Inorganic",
            "round_1": 8,
            "round_2": 13,
            "round_3": 9,
            "round_4": 12,
            "round_5": 15,
            "round_6": 13,
            "round_7": 11,
            "round_8": 17,
            "round_9": 9,
            "round_10": 12,
            "round_11": 14,
            "round_12": 9,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 6,
            "team": "Haas",
            "driver_name": "Matty347",
            "round_1": 1,
            "round_2": 3,
            "round_3": "DNS",
            "round_4": 2,
            "round_5": 4,
            "round_6": 9,
            "round_7": 3,
            "round_8": 3,
            "round_9": 2,
            "round_10": 5,
            "round_11": 3,
            "round_12": 2,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 9,
            "team": "Haas",
            "driver_name": "Jleeky7",
            "round_1": 12,
            "round_2": 10,
            "round_3": "DNS",
            "round_4": 9,
            "round_5": 10,
            "round_6": 6,
            "round_7": "DNS",
            "round_8": 10,
            "round_9": "DNS",
            "round_10": 10,
            "round_11": 7,
            "round_12": 14,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 15,
            "team": "Williams",
            "driver_name": "themightyfrax",
            "round_1": 16,
            "round_2": 16,
            "round_3": 17,
            "round_4": 18,
            "round_5": 13,
            "round_6": 17,
            "round_7": "DNS",
            "round_8": 13,
            "round_9": 5,
            "round_10": 15,
            "round_11": "DNS",
            "round_12": "DNS",
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        },
        {
            "official_rank": 1,
            "team": "Williams",
            "driver_name": "N4T_P4CK3R5",
            "round_1": "DNS",
            "round_2": 1,
            "round_3": 7,
            "round_4": 1,
            "round_5": 2,
            "round_6": 4,
            "round_7": 1,
            "round_8": 2,
            "round_9": 4,
            "round_10": 3,
            "round_11": 2,
            "round_12": 6,
            "round_13": "*",
            "round_14": "*",
            "round_15": "*",
            "round_16": "*"
        }
    ]);
    const [driverStats, setDriverStats] = useState([
        {
            "driver_name": "smit1717",
            "starts": 12,
            "wins": 1,
            "podiums": 4,
            "pole": 4,
            "fastest_lap": 2,
            "top_five": 7,
            "points": 288,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 5,
            "avg_start": 5,
            "total_positions_gained": -3,
            "avg_positions_gained": 0,
            "dotw": 1,
            "win_percentage": "8%",
            "pole_percentage": "33%",
            "podium_percentage": "33%",
            "top_five_percentage": "58%",
            "dnf_percentage": "0%",
            "points_per_race": 24
        },
        {
            "driver_name": "Spivey-CJ",
            "starts": 12,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 77,
            "penalty_points": 2,
            "dnf": 0,
            "best_finish": 7,
            "best_quali": 9,
            "avg_finish": 11,
            "avg_start": 15,
            "total_positions_gained": 41,
            "avg_positions_gained": 3,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "0%",
            "points_per_race": 6.42
        },
        {
            "driver_name": "Rycka9",
            "starts": 9,
            "wins": 1,
            "podiums": 5,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 7,
            "points": 252,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 1,
            "best_quali": 2,
            "avg_finish": 4,
            "avg_start": 7,
            "total_positions_gained": 24,
            "avg_positions_gained": 3,
            "dotw": "",
            "win_percentage": "11%",
            "pole_percentage": "0%",
            "podium_percentage": "56%",
            "top_five_percentage": "78%",
            "dnf_percentage": "0%",
            "points_per_race": 28
        },
        {
            "driver_name": "Callum0064",
            "starts": 11,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 82,
            "penalty_points": 0,
            "dnf": 5,
            "best_finish": 6,
            "best_quali": 1,
            "avg_finish": 9,
            "avg_start": 7,
            "total_positions_gained": -80,
            "avg_positions_gained": -7,
            "dotw": 1,
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "45%",
            "points_per_race": 7.45
        },
        {
            "driver_name": "RPM_Danos",
            "starts": 12,
            "wins": 0,
            "podiums": 3,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 7,
            "points": 213,
            "penalty_points": 2,
            "dnf": 2,
            "best_finish": 2,
            "best_quali": 2,
            "avg_finish": 6,
            "avg_start": 8,
            "total_positions_gained": 7,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "25%",
            "top_five_percentage": "58%",
            "dnf_percentage": "17%",
            "points_per_race": 17.75
        },
        {
            "driver_name": "RPM_Ijver",
            "starts": 12,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 34,
            "penalty_points": 4,
            "dnf": 4,
            "best_finish": 10,
            "best_quali": 5,
            "avg_finish": 12,
            "avg_start": 12,
            "total_positions_gained": -35,
            "avg_positions_gained": -3,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "33%",
            "points_per_race": 2.83
        },
        {
            "driver_name": "Schnitzy91",
            "starts": 6,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 28,
            "penalty_points": 0,
            "dnf": 2,
            "best_finish": 8,
            "best_quali": 3,
            "avg_finish": 12,
            "avg_start": 10,
            "total_positions_gained": -21,
            "avg_positions_gained": -4,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "33%",
            "points_per_race": 4.67
        },
        {
            "driver_name": "Snellehenk73",
            "starts": 12,
            "wins": 0,
            "podiums": 1,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 2,
            "points": 168,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 3,
            "best_quali": 2,
            "avg_finish": 8,
            "avg_start": 10,
            "total_positions_gained": 9,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "8%",
            "top_five_percentage": "17%",
            "dnf_percentage": "8%",
            "points_per_race": 14
        },
        {
            "driver_name": "Mocky1480",
            "starts": 3,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 12,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 11,
            "best_quali": 12,
            "avg_finish": 12,
            "avg_start": 13,
            "total_positions_gained": 3,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "0%",
            "points_per_race": 4
        },
        {
            "driver_name": "RPM_jones58",
            "starts": 10,
            "wins": 0,
            "podiums": 1,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 2,
            "points": 148,
            "penalty_points": 2,
            "dnf": 1,
            "best_finish": 3,
            "best_quali": 1,
            "avg_finish": 7,
            "avg_start": 9,
            "total_positions_gained": 11,
            "avg_positions_gained": 1,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "10%",
            "top_five_percentage": "20%",
            "dnf_percentage": "10%",
            "points_per_race": 14.8
        },
        {
            "driver_name": "RTRodo",
            "starts": 12,
            "wins": 0,
            "podiums": 3,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 7,
            "points": 245,
            "penalty_points": 3,
            "dnf": 1,
            "best_finish": 2,
            "best_quali": 2,
            "avg_finish": 5,
            "avg_start": 6,
            "total_positions_gained": -1,
            "avg_positions_gained": 0,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "25%",
            "top_five_percentage": "58%",
            "dnf_percentage": "8%",
            "points_per_race": 20.42
        },
        {
            "driver_name": "Philmcconachie",
            "starts": 5,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 0,
            "points": 19,
            "penalty_points": 3,
            "dnf": 3,
            "best_finish": 6,
            "best_quali": 6,
            "avg_finish": 11,
            "avg_start": 11,
            "total_positions_gained": -26,
            "avg_positions_gained": -5,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "60%",
            "points_per_race": 3.8
        },
        {
            "driver_name": "Goee14",
            "starts": 7,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 12,
            "penalty_points": 2,
            "dnf": 5,
            "best_finish": 10,
            "best_quali": 8,
            "avg_finish": 11,
            "avg_start": 14,
            "total_positions_gained": -25,
            "avg_positions_gained": -4,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "71%",
            "points_per_race": 1.71
        },
        {
            "driver_name": "Reuze_Beertje",
            "starts": 1,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 1,
            "points": 34,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 4,
            "best_quali": 9,
            "avg_finish": 7,
            "avg_start": 16,
            "total_positions_gained": 7,
            "avg_positions_gained": 4,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "100%",
            "dnf_percentage": "0%",
            "points_per_race": 34
        },
        {
            "driver_name": "F1-Dino-F1",
            "starts": 11,
            "wins": 1,
            "podiums": 4,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 7,
            "points": 233,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 5,
            "avg_start": 6,
            "total_positions_gained": 4,
            "avg_positions_gained": 0,
            "dotw": 1,
            "win_percentage": "9%",
            "pole_percentage": "0%",
            "podium_percentage": "36%",
            "top_five_percentage": "64%",
            "dnf_percentage": "9%",
            "points_per_race": 21.18
        },
        {
            "driver_name": "Inorganic",
            "starts": 12,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 1,
            "top_five": 1,
            "points": 85,
            "penalty_points": 4,
            "dnf": 2,
            "best_finish": 5,
            "best_quali": 8,
            "avg_finish": 11,
            "avg_start": 12,
            "total_positions_gained": -3,
            "avg_positions_gained": 0,
            "dotw": 2,
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "8%",
            "dnf_percentage": "17%",
            "points_per_race": 7.08
        },
        {
            "driver_name": "Matty347",
            "starts": 11,
            "wins": 3,
            "podiums": 5,
            "pole": 1,
            "fastest_lap": 1,
            "top_five": 6,
            "points": 240,
            "penalty_points": 1,
            "dnf": 1,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 6,
            "avg_start": 3,
            "total_positions_gained": -41,
            "avg_positions_gained": -4,
            "dotw": 1,
            "win_percentage": "27%",
            "pole_percentage": "9%",
            "podium_percentage": "45%",
            "top_five_percentage": "55%",
            "dnf_percentage": "9%",
            "points_per_race": 21.82
        },
        {
            "driver_name": "Jleeky7",
            "starts": 9,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 2,
            "points": 159,
            "penalty_points": 0,
            "dnf": 0,
            "best_finish": 5,
            "best_quali": 6,
            "avg_finish": 6,
            "avg_start": 10,
            "total_positions_gained": 30,
            "avg_positions_gained": 3,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "22%",
            "dnf_percentage": "0%",
            "points_per_race": 17.67
        },
        {
            "driver_name": "themightyfrax",
            "starts": 8,
            "wins": 0,
            "podiums": 0,
            "pole": 0,
            "fastest_lap": 0,
            "top_five": 0,
            "points": 42,
            "penalty_points": 0,
            "dnf": 3,
            "best_finish": 8,
            "best_quali": 5,
            "avg_finish": 11,
            "avg_start": 14,
            "total_positions_gained": 2,
            "avg_positions_gained": 0,
            "dotw": "",
            "win_percentage": "0%",
            "pole_percentage": "0%",
            "podium_percentage": "0%",
            "top_five_percentage": "0%",
            "dnf_percentage": "38%",
            "points_per_race": 5.25
        },
        {
            "driver_name": "N4T_P4CK3R5",
            "starts": 11,
            "wins": 6,
            "podiums": 9,
            "pole": 3,
            "fastest_lap": 4,
            "top_five": 9,
            "points": 358,
            "penalty_points": 0,
            "dnf": 1,
            "best_finish": 1,
            "best_quali": 1,
            "avg_finish": 3,
            "avg_start": 3,
            "total_positions_gained": -13,
            "avg_positions_gained": -1,
            "dotw": 1,
            "win_percentage": "55%",
            "pole_percentage": "27%",
            "podium_percentage": "82%",
            "top_five_percentage": "82%",
            "dnf_percentage": "9%",
            "points_per_race": 32.55
        }
    ]);
    const [driverFinishes, setDriverFinishes] = useState([
        {
            "official_rank": 3,
            "team": "Mercedes",
            "driver_name": "smit1717",
            "round_1": 10,
            "round_2": 5,
            "round_3": 11,
            "round_4": 3,
            "round_5": 2,
            "round_6": 5,
            "round_7": 6,
            "round_8": 1,
            "round_9": 7,
            "round_10": 4,
            "round_11": 3,
            "round_12": 6,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 12,
            "team": "Mercedes",
            "driver_name": "Spivey-CJ",
            "round_1": 7,
            "round_2": 12,
            "round_3": 14,
            "round_4": 13,
            "round_5": 13,
            "round_6": 9,
            "round_7": 9,
            "round_8": 9,
            "round_9": 12,
            "round_10": 11,
            "round_11": 13,
            "round_12": 12,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 1,
            "team": "Red Bull",
            "driver_name": "Rycka9",
            "round_1": 4,
            "round_2": 4,
            "round_3": 2,
            "round_4": 1,
            "round_5": 10,
            "round_6": 3,
            "round_7": 7,
            "round_8": 2,
            "round_9": 2,
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": "DNS",
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 13,
            "team": "Red Bull",
            "driver_name": "Callum0064",
            "round_1": "DNF",
            "round_2": "DNF",
            "round_3": 7,
            "round_4": "DNF",
            "round_5": 9,
            "round_6": "DNF",
            "round_7": "DNS",
            "round_8": "DNF",
            "round_9": 6,
            "round_10": 12,
            "round_11": 12,
            "round_12": 7,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 6,
            "team": "Ferrari",
            "driver_name": "RPM_Danos",
            "round_1": 5,
            "round_2": 9,
            "round_3": 4,
            "round_4": 10,
            "round_5": "DNF",
            "round_6": 2,
            "round_7": 3,
            "round_8": 10,
            "round_9": 5,
            "round_10": 3,
            "round_11": 5,
            "round_12": "DNF",
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 17,
            "team": "Ferrari",
            "driver_name": "RPM_Ijver",
            "round_1": "DNF",
            "round_2": 14,
            "round_3": "DNF",
            "round_4": 11,
            "round_5": "DNF",
            "round_6": "DNF",
            "round_7": 12,
            "round_8": 12,
            "round_9": 15,
            "round_10": 10,
            "round_11": 14,
            "round_12": 11,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 19,
            "team": "McLaren",
            "driver_name": "Scooterclem10",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNF",
            "round_6": 12,
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": 16,
            "round_10": 8,
            "round_11": 10,
            "round_12": "DNF",
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 9,
            "team": "McLaren",
            "driver_name": "Snellehenk73",
            "round_1": 3,
            "round_2": 11,
            "round_3": 13,
            "round_4": 7,
            "round_5": 8,
            "round_6": 7,
            "round_7": 8,
            "round_8": "DNF",
            "round_9": 10,
            "round_10": 6,
            "round_11": 9,
            "round_12": 4,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 20,
            "team": "Aston Martin",
            "driver_name": "Mocky1480",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": 13,
            "round_9": "DNS",
            "round_10": 13,
            "round_11": 11,
            "round_12": "DNS",
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 8,
            "team": "Aston Martin",
            "driver_name": "RPM_jones58",
            "round_1": 6,
            "round_2": 7,
            "round_3": 8,
            "round_4": 12,
            "round_5": 11,
            "round_6": "DNS",
            "round_7": 4,
            "round_8": 7,
            "round_9": 8,
            "round_10": "DNF",
            "round_11": "DNS",
            "round_12": 3,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 5,
            "team": "Alpine",
            "driver_name": "RTRodo",
            "round_1": 9,
            "round_2": 3,
            "round_3": 5,
            "round_4": 8,
            "round_5": 3,
            "round_6": "DNF",
            "round_7": 2,
            "round_8": 4,
            "round_9": 4,
            "round_10": 5,
            "round_11": 7,
            "round_12": 8,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 16,
            "team": "Alpine",
            "driver_name": "Philmcconachie",
            "round_1": "DNF",
            "round_2": 16,
            "round_3": 6,
            "round_4": "DNF",
            "round_5": "DNF",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": "DNS",
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 18,
            "team": "Alpha Tauri",
            "driver_name": "Goee14",
            "round_1": "DNF",
            "round_2": 10,
            "round_3": 12,
            "round_4": "DNF",
            "round_5": "DNS",
            "round_6": "DNF",
            "round_7": "DNF",
            "round_8": "DNF",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": "DNS",
            "round_12": "DNS",
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 11,
            "team": "Alpha Tauri",
            "driver_name": "Reuze_Beertje",
            "round_1": "DNS",
            "round_2": "DNS",
            "round_3": "DNS",
            "round_4": "DNS",
            "round_5": "DNS",
            "round_6": "DNS",
            "round_7": "DNS",
            "round_8": "DNS",
            "round_9": "DNS",
            "round_10": "DNS",
            "round_11": 4,
            "round_12": 10,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 4,
            "team": "Alfa Romeo",
            "driver_name": "F1-Dino-F1",
            "round_1": 2,
            "round_2": "DNS",
            "round_3": 9,
            "round_4": 4,
            "round_5": 4,
            "round_6": 1,
            "round_7": 5,
            "round_8": 14,
            "round_9": 3,
            "round_10": "DNF",
            "round_11": "DNS",
            "round_12": 2,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 14,
            "team": "Alfa Romeo",
            "driver_name": "Inorganic",
            "round_1": "DNF",
            "round_2": 15,
            "round_3": 15,
            "round_4": 14,
            "round_5": "DNF",
            "round_6": 11,
            "round_7": 10,
            "round_8": 5,
            "round_9": 9,
            "round_10": 9,
            "round_11": 8,
            "round_12": 9,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 7,
            "team": "Haas",
            "driver_name": "Matty347",
            "round_1": 1,
            "round_2": 2,
            "round_3": "DNS",
            "round_4": 2,
            "round_5": 12,
            "round_6": 4,
            "round_7": 11,
            "round_8": 15,
            "round_9": 14,
            "round_10": 1,
            "round_11": 1,
            "round_12": "DNF",
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 10,
            "team": "Haas",
            "driver_name": "Jleeky7",
            "round_1": 8,
            "round_2": 8,
            "round_3": "DNS",
            "round_4": 5,
            "round_5": 7,
            "round_6": 6,
            "round_7": "DNS",
            "round_8": 6,
            "round_9": "DNS",
            "round_10": 7,
            "round_11": 6,
            "round_12": 5,
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 15,
            "team": "Williams",
            "driver_name": "themightyfrax",
            "round_1": "DNF",
            "round_2": 16,
            "round_3": "DNF",
            "round_4": 9,
            "round_5": "DNF",
            "round_6": 8,
            "round_7": "DNS",
            "round_8": 8,
            "round_9": 13,
            "round_10": 14,
            "round_11": "DNS",
            "round_12": "DNS",
            "round_13": null,
            "round_14": null
        },
        {
            "official_rank": 2,
            "team": "Williams",
            "driver_name": "N4T_P4CK3R5",
            "round_1": "DNS",
            "round_2": 1,
            "round_3": 1,
            "round_4": "DNF",
            "round_5": 1,
            "round_6": 13,
            "round_7": 1,
            "round_8": 3,
            "round_9": 1,
            "round_10": 2,
            "round_11": 2,
            "round_12": 1,
            "round_13": null,
            "round_14": null
        }
    ]);

    const flagCodes = [
        { code: 'AU', name: 'Australia' },
        { code: 'BE', name: 'Belgium' },
        { code: 'AT', name: 'Austria' },
        { code: 'MX', name: 'Mexico' },
        { code: 'SG', name: 'Singapore' },
        { code: 'IT', name: 'Italy' },
        { code: 'AE', name: 'Abu Dhabi' },
        { code: 'IT', name: 'Italy' },
        { code: 'GB', name: 'Britain' },
        { code: 'CA', name: 'Canada' },
        { code: 'PT', name: 'Portugal' },
        { code: 'SA', name: 'Jeddah' },
        { code: 'NL', name: 'Netherlands' },
        { code: 'BR', name: 'Brazil' }
    ]

    const lastRaceNumber = 12;

    const newDataLoaded = () => toast("The current tables have been updated.");

    // useEffects for redirecting non-logged in users and blocking homepage from showing during check
    useEffect(() => {
        if (loading) {
            console.log('loading', loading);
            console.log('user', user)
            return;
        }
        if (user === null) {
            console.log('should redirect?')
            navigate("/login")
        };
    }, [user, loading]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                const email = user.email;
                // ...
                console.log("uid", uid, "email", email)
            } else {
                // User is signed out
                // ...
                console.log("user is logged out")
            }
        });

    }, [])

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    useEffect(() => {
        if (user) {
            // get the JWT token from the server
            console.log('try for JWT');
            const url = process.env.REACT_APP_JWT_ENDPOINT;
            const payload = {
                userID: user.uid,
            };

            try {
                axios.post(url, payload).then((response) => {
                    console.log(response.data.token)
                    setCentrifugoJWT(response.data.token);
                });
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.log('no user yet')
        }

    }, [user])

    useEffect(() => {

        console.log('start the CENTRIFUGO use effect');
        let subDriverStats;
        let subDriverResultsCompare;
        let subDriverStatsDriver;
        let subLapsLead;
        let subOvertakes;
        let centrifuge
        if (centrifugoJWT !== "") {
            toast.success(`Established Connection.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            centrifuge = new Centrifuge("wss://messaging.purplekraken.dev/centrifugo/connection/websocket", {
                token: centrifugoJWT,
            });
            centrifuge.on('connecting', function (ctx) {
                console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
            }).on('connected', function (ctx) {
                console.log(`connected over ${ctx.transport}`);
            }).on('disconnected', function (ctx) {
                console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
            }).connect();

            console.log(rebindAbly)

            if (rebindAbly.drivers !== undefined && rebindAbly.drivers.length === 2) {
                centrifuge.publish("rpm:driverSelectResultsCompare", { drivers: rebindAbly.drivers }).then(function (res) {
                    console.log('successfully published drivers selected', rebindAbly.drivers.length);
                }, function (err) {
                    console.log('publish error', err);
                });
            }

            if (rebindAbly.drivers !== undefined && rebindAbly.drivers.length === 1) {
                centrifuge.publish("rpm:driverSelectResultsStats", { drivers: rebindAbly.drivers }).then(function (res) {
                    console.log('successfully published driver selected', rebindAbly.drivers.length);
                }, function (err) {
                    console.log('publish error', err);
                });
            }

            subDriverStats = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:driverStats`);
            subDriverResultsCompare = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:driverSelectResultsCompare`);
            subDriverStatsDriver = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:driverSelectResultsStats`);
            subLapsLead = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:leadLaps`);
            subOvertakes = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:overtakeRecord`);

            subDriverResultsCompare.on('publication', function (ctx) {
                console.log('-------- New Driver Results Selection Recieved -----')
                const names = ctx.data.drivers;
                console.log(names)
                if (names.length === 2 && names[0] !== null && names[1] !== null) {
                    setDriverSelectOneResults(names[0]);
                    setDriverSelectTwoResults(names[1]);
                }
            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();

            subDriverStatsDriver.on('publication', function (ctx) {
                console.log('-------- New Driver Stats Selection Recieved -----')
                const names = ctx.data.drivers;
                console.log(names)
                if (names.length === 1 && names[0] !== null) {
                    setDriverSelectOneStats(names[0]);
                }
            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();

            subLapsLead.on('publication', function (ctx) {
                console.log('-------- New Laps Lead Selection Recieved -----')
                const laps = ctx.data;
                console.log(laps)
            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();


            subOvertakes.on('overtakes', function (ctx) {
                console.log('-------- New Overtake Record Selection Recieved -----')
                const overtakes = ctx.data;
                console.log(overtakes)
            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();

        } else {
            console.log('Could not generate a secure JWT');
            toast.error(`Could not generate a secure JWT.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }

        // run the cleanup to unsubscribe from pusher and unbind the events
        return () => {
            console.log('clean up');

            if (subDriverStats) {
                subDriverStats.unsubscribe();
                subDriverStats.removeAllListeners();
            }

            if (subDriverResultsCompare) {
                subDriverResultsCompare.unsubscribe();
                subDriverResultsCompare.removeAllListeners();
            }

            if (subDriverStatsDriver) {
                subDriverStatsDriver.unsubscribe();
                subDriverStatsDriver.removeAllListeners();
            }

            if (subLapsLead) {
                subLapsLead.unsubscribe();
                subLapsLead.removeAllListeners();
            }

            if (subOvertakes) {
                subOvertakes.unsubscribe();
                subOvertakes.removeAllListeners();
            }

            if (centrifuge) {
                centrifuge.disconnect();
            }
        };

    }, [rebindAbly, centrifugoJWT]);

    const handleDriverSelectOne = (selectedOption) => {
        // if(selectedOption)
        setDriverSelectOne(selectedOption.label);
        console.log(`Driver Option selected:`, selectedOption.label);
    };

    const handleDriverSelectTwo = (selectedOption) => {
        // if(selectedOption)
        setDriverSelectTwo(selectedOption.label);
        console.log(`Driver Option selected:`, selectedOption.label);
    };

    const handleDriverSelectOneStats = (selectedOption) => {
        // if(selectedOption)
        setDriverSelectStats(selectedOption.label);
        console.log(`Driver Option selected:`, selectedOption.label);
    };

    function refreshPusher(league) {
        console.log("refresh select league")
        if (league === "premier") {
            console.log(driverOptionsPremier)
            setDriverOptions(driverOptionsPremier)
        }
        if (league === "championship") {
            setDriverOptions(driverOptionsChamp)
        }
        if (league === "first") {
            setDriverOptions(driverOptionsFirst)
        }
        if (league === "offline") {
            setDriverOptions(driverOptionsOffline)
        }
        setSelectDivision(league);
    }

    function setDrivers() {
        let driverSelectOneValid = driverSelectOne !== 'Not Set' && driverSelectOne !== 'No Driver' ? true : false;
        let driverSelectTwoValid = driverSelectTwo !== 'Not Set' && driverSelectTwo !== 'No Driver' ? true : false;
        if (driverSelectOneValid && driverSelectTwoValid) {
            setRebindAbly({ drivers: [driverSelectOne, driverSelectTwo] });
        } else {
            console.log('Please select two drivers.');
            toast.error(`Please select two drivers.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }

    function setDriverStatsFun() {
        let driverSelectOneValid = driverSelectStats !== 'Not Set' && driverSelectStats !== 'No Driver' ? true : false;
        if (driverSelectOneValid) {
            setRebindAbly({ drivers: [driverSelectStats] });
        } else {
            console.log('Please select driver.');
            toast.error(`Please select driver.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }

    function getDriverFinishes(name) {
        let data = driverFinishes.find((driver) => {
            let elementMatch = name.toLowerCase().match(driver.driver_name.toLowerCase())
            return elementMatch
        })
        console.log(data)
        return data
    }

    function getDriverQualis(name) {
        let data = driverQualis.find((driver) => {
            let elementMatch = name.toLowerCase().match(driver.driver_name.toLowerCase())
            return elementMatch
        })
        console.log(data)
        return data
    }

    function getDriverStats(name) {
        let data = driverStats.find((driver) => {
            let elementMatch = name.toLowerCase().match(driver.driver_name.toLowerCase())
            return elementMatch
        })
        console.log(data)
        return data
    }


    return (
        <>
            {loading !== true && user !== null &&
                <div>
                    <nav>
                        <img src={rpmLogoGold} style={{ "cursor": "pointer" }} onClick={() => { navigate("/") }} />
                        <div className='adminButtons headerButtons'>
                            <div>
                                <button onClick={() => { navigate("/live-standings") }}>
                                    Live Standings
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/session-data") }}>
                                    Session Data
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/battle") }}>
                                    Battle Data
                                </button>
                            </div>
                        </div>
                        <span style={{ 'color': 'white' }}>League ID - {process.env.REACT_APP_LEAGUE}</span>
                        <div>
                            <button onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    </nav>
                    {selectDivision === "Not Set" ? <div style={{ "textAlign": "center" }}><h2 style={{ "color": "white" }}>Select League for Data Binding</h2></div> : null}
                    <div className='adminButtons'>
                        <div>
                            <button style={{ "background": selectDivision === "premier" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("premier") }}>
                                Premier
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "championship" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("championship") }}>
                                Championship
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "first" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("first") }}>
                                First
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "practice" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("practice") }}>
                                Practice
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "offline" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("offline") }}>
                                Offline
                            </button>
                        </div>
                    </div>
                    <div className="App">
                        <Tabs>
                            <TabList>
                                <Tab>Driver Stats</Tab>
                            </TabList>
                            <TabPanel>
                                <div className='selectContainers'>
                                    <div className='driverSelect'>
                                        <h2 style={{ "color": "white" }}>Select Driver One</h2>
                                        {<Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // isMulti
                                            isDisabled={isDisabled}
                                            isLoading={isLoading}
                                            name="driverList"
                                            id='driverList'
                                            options={driverOptions}
                                            onChange={handleDriverSelectOne} />}
                                    </div>
                                    <button style={{ width: "fit-content", margin: "0 2rem" }} onClick={() => { setDrivers() }}>
                                        Set selected drivers
                                    </button>
                                    <div className='driverSelect'>
                                        <h2 style={{ "color": "white" }}>Select Driver Two</h2>
                                        {<Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // isMulti
                                            isDisabled={isDisabled}
                                            isLoading={isLoading}
                                            name="driverList"
                                            id='driverList'
                                            options={driverOptions}
                                            onChange={handleDriverSelectTwo} />}
                                    </div>
                                </div>
                                <div style={{ "margin": "3rem auto", "background": "#fff", "maxWidth": "700px", "display": "flex" }}>
                                    <div style={{
                                        "background": "#000",
                                        "height": "216px",
                                        "display": "flex",
                                        "justifyContent": "center",
                                        "alignItems": "center"
                                    }}>
                                        <img src={rpmLogo} style={{ "cursor": "pointer", "width": "150px", "paddingLeft": "1rem" }} />
                                    </div>
                                    <div>
                                        <div style={{ "fontWeight": "700", "textAlign": "center", "marginTop": "1rem", "paddingLeft": "1rem", "paddingBottom": "0.5rem", "paddingTop": "0.5rem" }}>Last 5 Race Finishes</div>
                                        <div className='finishesContainer' style={{ "padding": "1rem 1rem 0 1rem" }}>
                                            <div style={{ "fontWeight": "700" }}></div>
                                            <div className='finish'><Flag country={flagCodes[lastRaceNumber - 5].code} /></div>
                                            <div className='finish'><Flag country={flagCodes[lastRaceNumber - 4].code} /></div>
                                            <div className='finish'><Flag country={flagCodes[lastRaceNumber - 3].code} /></div>
                                            <div className='finish'><Flag country={flagCodes[lastRaceNumber - 2].code} /></div>
                                            <div className='finish'><Flag country={flagCodes[lastRaceNumber - 1].code} /></div>
                                        </div>
                                        {
                                            (!_.isEmpty(driverFinishes) && driverSelectOneResults !== 'Not Set') && <DriverFinshes driverData={getDriverFinishes(driverSelectOneResults)}></DriverFinshes>
                                        }
                                        {
                                            (!_.isEmpty(driverFinishes) && driverSelectTwoResults !== 'Not Set') && <DriverFinshes driverData={
                                                getDriverFinishes(driverSelectTwoResults)
                                            }></DriverFinshes>
                                        }
                                        {
                                        }
                                    </div>
                                </div>
                                <div style={{ "margin": "3rem auto", "background": "#fff", "maxWidth": "700px", "display": "flex" }}>
                                    <div style={{
                                        "background": "#000",
                                        "height": "216px",
                                        "display": "flex",
                                        "justifyContent": "center",
                                        "alignItems": "center"
                                    }}>
                                        <img src={rpmLogo} style={{ "cursor": "pointer", "width": "150px", "paddingLeft": "1rem" }} />
                                    </div>
                                    <div>
                                        <div style={{ "fontWeight": "700", "textAlign": "center", "marginTop": "1rem", "paddingLeft": "1rem", "paddingBottom": "0.5rem", "paddingTop": "0.5rem" }}>Last 5 Qualifications</div>
                                        <div className='finishesContainer' style={{ "padding": "1rem 1rem 0 1rem" }}>
                                            <div style={{ "fontWeight": "700" }}></div>
                                            <div className='finish'><Flag country={flagCodes[lastRaceNumber - 5].code} /></div>
                                            <div className='finish'><Flag country={flagCodes[lastRaceNumber - 4].code} /></div>
                                            <div className='finish'><Flag country={flagCodes[lastRaceNumber - 3].code} /></div>
                                            <div className='finish'><Flag country={flagCodes[lastRaceNumber - 2].code} /></div>
                                            <div className='finish'><Flag country={flagCodes[lastRaceNumber - 1].code} /></div>
                                        </div>
                                        {
                                            (!_.isEmpty(driverQualis) && driverSelectOneResults !== 'Not Set') && <DriverFinshes driverData={getDriverQualis(driverSelectOneResults)}></DriverFinshes>
                                        }
                                        {
                                            (!_.isEmpty(driverQualis) && driverSelectTwoResults !== 'Not Set') && <DriverFinshes driverData={
                                                getDriverQualis(driverSelectTwoResults)
                                            }></DriverFinshes>
                                        }
                                        {
                                        }
                                    </div>
                                </div>
                                <div className='selectContainers'>
                                    <div className='driverSelect'>
                                        <h2 style={{ "color": "white" }}>Select Driver Stats</h2>
                                        {<Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // isMulti
                                            isDisabled={isDisabled}
                                            isLoading={isLoading}
                                            name="driverList"
                                            id='driverList'
                                            options={driverOptions}
                                            onChange={handleDriverSelectOneStats} />}
                                    </div>
                                    <button style={{ width: "fit-content", margin: "0 2rem" }} onClick={() => { setDriverStatsFun() }}>
                                        Set Driver
                                    </button>
                                </div>
                                <div style={{ "margin": "3rem auto", "background": "#fff", "maxWidth": "500px", "display": "flex" }}>
                                    <div style={{
                                        "background": "#000",
                                        "height": "440px",
                                        "display": "flex",
                                        "justifyContent": "center",
                                        "alignItems": "center"
                                    }}>
                                        <img src={rpmLogo} style={{ "cursor": "pointer", "width": "150px", "paddingLeft": "1rem" }} />
                                    </div>
                                    <div style={{ "width": "100%" }}>
                                        <div style={{ "fontWeight": "700", "textAlign": "left", "marginTop": "1rem", "paddingLeft": "1rem", "paddingBottom": "0.5rem", "paddingTop": "0.5rem" }}>Driver Profile - {driverSelectOneStats !== 'Not Set' && driverSelectOneStats}</div>
                                        {
                                            (!_.isEmpty(driverStats) && driverSelectOneStats !== 'Not Set') && <DriverStatPack driverData={getDriverStats(driverSelectOneStats)}></DriverStatPack>
                                        }
                                    </div>
                                </div>
                                <h2 style={{ "color": "white" }}>Division Stats</h2>
                                <div style={{ "margin": "3rem auto", "background": "#fff", "maxWidth": "450px", "display": "flex" }}>
                                    <div style={{
                                        "background": "#000",
                                        "height": "440px",
                                        "display": "flex",
                                        "justifyContent": "center",
                                        "alignItems": "center"
                                    }}>
                                        <img src={rpmLogo} style={{ "cursor": "pointer", "width": "150px", "paddingLeft": "1rem" }} />
                                    </div>
                                    <div>
                                        <div style={{ "fontWeight": "700", "textAlign": "center", "marginTop": "1rem", "paddingLeft": "1rem", "paddingBottom": "0.5rem", "paddingTop": "0.5rem" }}>Laps Lead (Season)</div>
                                        <DriverLapsFront driverData={lapsLead}></DriverLapsFront>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>


                    </div>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
            }
        </>
    );
}

export default SessionData