import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom';
import rpmLogo from "../assets/logos/rmplogo.jpeg";


const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate("/")
                console.log(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });

    }

    return (
        <>
            <main >
                <section>
                    <div>
                    <nav>
                            <img src={rpmLogo}/>
                    </nav>
                        <h1 style={{"textAlign": "center", "color": "white"}}> Log In </h1>

                        <form className="login">
                            <div className="login__container">
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    className="login__textBox"
                                    required
                                    placeholder="Email address"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    className="login__textBox"
                                    required
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="buttonTextHolder">
                                <button
                                    className="login__btn"
                                    onClick={onLogin}
                                >
                                    Login
                                </button>
                                <p className="text-sm text-white text-center" style={{"color": "white"}}>
                                    No account yet? {' '}
                                    <NavLink to="/signup">
                                        Sign up
                                    </NavLink>
                                </p>
                            </div>
                        </form>



                    </div>
                </section>
            </main>
        </>
    )
}

export default Login