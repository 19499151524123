import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../firebase';
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import '../App.css';
import rpmLogoGold from "../assets/logos/rpm_gold.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import _ from "lodash";
import axios from 'axios';
import { Centrifuge } from 'centrifuge';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS


const LapHistory = () => {
    // set up constants and states
    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [user, loading] = useAuthState(auth);
    const [selectLeague, setSelectLeague] = useState(process.env.REACT_APP_LEAGUE);
    const [selectDivision, setSelectDivision] = useState("Not Set");
    const [driverSelectOne, setDriverSelectOne] = useState('Not Set')
    const [driverSelectTwo, setDriverSelectTwo] = useState('Not Set')
    const [driverOptionsOffline, setDriverOptionsOffline] = useState([
        {
            label: "Hamilton",
            value: "hamilton"
        },
        {
            label: "Bottas",
            value: "bottas"
        },
        {
            label: "Sainz",
            value: "sainz"
        },
        {
            label: "Stroll",
            value: "stroll"
        },
        {
            label: "Alonso",
            value: "alonso"
        },
        {
            label: "Ocon",
            value: "ocon"
        },
        {
            label: "Zhou",
            value: "zhou"
        },
        {
            label: "Gasly",
            value: "gasly"
        },
        {
            label: "Tsunoda",
            value: "tsunoda"
        },
        {
            label: "Vettel",
            value: "vettel"
        },
        {
            label: "Magnussen",
            value: "magnussen"
        },
        {
            label: "Schumacher",
            value: "schumacher"
        },
        {
            label: "Leclerc",
            value: "leclerc"
        },
        {
            label: "Ricciardo",
            value: "ricciardo"
        },
        {
            label: "Norris",
            value: "norris"
        },
        {
            label: "Russel",
            value: "russel"
        },
        {
            label: "Verstappen",
            value: "verstappen"
        },
        {
            label: "PÉREZ",
            value: "PÉREZ"
        },
        {
            label: "Albon",
            value: "albon"
        },
        {
            label: "Latifi",
            value: "latifi"
        }
    ])
    const [driverOptionsFirstSeason6, setDriverOptionsFirstSeason6] = useState([
        {
            label: "matty347",
            value: "matty347",
        },
        {
            label: "Mocky1480",
            value: "Mocky1480",
        },
        {
            label: "smit1717",
            value: "smit1717",
        },
        {
            label: "RPM_Danos",
            value: "RPM_Danos",
        },
        {
            label: "RPM_jones58",
            value: "RPM_jones58",
        },
        {
            label: "RTRodo",
            value: "RTRodo",
        },
        {
            label: "Spivey-CJ",
            value: "Spivey-CJ",
        },
        {
            label: "JLeeky7",
            value: "JLeeky7",
        },
        {
            label: "F1-Dino-F1",
            value: "F1-Dino-F1",
        },
        {
            label: "Hasbullah-19",
            value: "Hasbullah-19",
        },
        {
            label: "rycka9",
            value: "rycka9",
        },
        {
            label: "Inorganic",
            value: "Inorganic",
        },
        {
            label: "Reuze_Beertje",
            value: "Reuze_Beertje",
        },
        {
            label: "Goee14",
            value: "Goee14",
        },
        {
            label: "RPM_ijver",
            value: "RPM_ijver",
        },
        {
            label: "themightyfrax",
            value: "themightyfrax",
        },
        {
            label: "Philmcconachie",
            value: "Philmcconachie",
        },
        {
            label: "Callum0064",
            value: "Callum0064",
        },
        {
            label: "snellehenk73",
            value: "snellehenk73",
        },
        {
            label: "No Driver",
            value: "No Driver",
        },
    ])
    const [driverOptionsFirst, setDriverOptionsFirst] = useState([
        {
            label: "BOBSPUN",
            value: "BOBSPUN",
        },
        {
            label: "RPM_Inorganic",
            value: "RPM_Inorganic",
        },
        {
            label: "Mocky1480",
            value: "Mocky1480",
        },
        {
            label: "RPM_Jones58",
            value: "RPM_Jones58",
        },
        {
            label: "RPM_Danos",
            value: "RPM_Danos",
        },
        {
            label: "UnluckyFC__",
            value: "UnluckyFC__",
        },
        {
            label: "LisbonAELion67",
            value: "LisbonAELion67",
        },
        {
            label: "Spivey-CJ",
            value: "Spivey-CJ",
        },
        {
            label: "DSBAKER-58",
            value: "DSBAKER-58",
        },
        {
            label: "Kieron_994",
            value: "Kieron_994",
        },
        {
            label: "Faulkiller",
            value: "Faulkiller",
        },
        {
            label: "OST_Jeweller",
            value: "OST_Jeweller",
        },
        {
            label: "MikelAuf",
            value: "MikelAuf",
        },
        {
            label: "Reuze_Beertje",
            value: "Reuze_Beertje",
        },
        {
            label: "Shaunfuss",
            value: "Shaunfuss",
        },
        {
            label: "RPM_IJVER",
            value: "RPM_IJVER",
        },
        {
            label: "TheChaosDruid",
            value: "TheChaosDruid",
        },
        {
            label: "i_AmTron",
            value: "i_AmTron",
        },
        {
            label: "ScooterClem10",
            value: "ScooterClem10",
        },
        {
            label: "snellehenk73",
            value: "snellehenk73",
        }
    ])
    const [driverOptionsChampSeason6, setDriverOptionsChampSeason6] = useState([
        {
            label: "Benpaines_",
            value: "Benpaines_",
        },
        {
            label: "BigPuggy1987",
            value: "BigPuggy1987",
        },
        {
            label: "booty1973",
            value: "booty1973",
        },
        {
            label: "lVlatt",
            value: "lVlatt",
        },
        {
            label: "RPM_Stubbe",
            value: "RPM_Stubbe",
        },
        {
            label: "Wardy-R6",
            value: "Wardy-R6",
        },
        {
            label: "Kieran0064",
            value: "Kieran0064",
        },
        {
            label: "Jetfish11",
            value: "Jetfish11",
        },
        {
            label: "AL2737",
            value: "AL2737",
        },
        {
            label: "Stebo__",
            value: "Stebo__",
        },
        {
            label: "RustyOilcan",
            value: "RustyOilcan",
        },
        {
            label: "lost_glaedr",
            value: "lost_glaedr",
        },
        {
            label: "DZ_Karting",
            value: "DZ_Karting",
        },
        {
            label: "RPM_BAKKER1194",
            value: "RPM_BAKKER1194",
        }
    ])
    const [driverOptionsChamp, setDriverOptionsChamp] = useState([
        {
            "label": "matty347",
            "value": "matty347"
        },
        {
            "label": "N4T_P4CK3R5",
            "value": "N4T_P4CK3R5"
        },
        {
            "label": "smit1717",
            "value": "smit1717"
        },
        {
            "label": "GiraffeBHA",
            "value": "GiraffeBHA"
        },
        {
            "label": "RPM_Stubbe",
            "value": "RPM_Stubbe"
        },
        {
            "label": "RPM_Entity123",
            "value": "RPM_Entity123"
        },
        {
            "label": "RPM_Bakker1194",
            "value": "RPM_Bakker1194"
        },
        {
            "label": "Laur1609",
            "value": "Laur1609"
        },
        {
            "label": "Explicitsimmo23",
            "value": "Explicitsimmo23"
        },
        {
            "label": "Kieran0064",
            "value": "Kieran0064"
        },
        {
            "label": "RPM_husssrc",
            "value": "RPM_husssrc"
        },
        {
            "label": "RTRodo",
            "value": "RTRodo"
        },
        {
            "label": "Jleeky7",
            "value": "Jleeky7"
        },
        {
            "label": "Rycka9",
            "value": "Rycka9"
        },
        {
            "label": "lost_glaedr",
            "value": "lost_glaedr"
        },
        {
            "label": "booty1973",
            "value": "booty1973"
        },
        {
            "label": "RustyOilcan",
            "value": "RustyOilcan"
        },
        {
            "label": "XdOscarvdg",
            "value": "XdOscarvdg"
        },
        {
            "label": "Troy08Blue",
            "value": "Troy08Blue"
        },
        {
            "label": "No Driver",
            "value": "No Driver"
        }
    ])
    const [driverOptionsPremierSeason6, setDriverOptionsPremierSeason6] = useState([
        {
            label: "RPM_Colin-86",
            value: "RPM_Colin-86",
        },
        {
            label: "RPM_Sinfull",
            value: "RPM_Sinfull",
        },
        {
            label: "RPM_Branden23",
            value: "RPM_Branden23",
        },
        {
            label: "RVL_Volcano",
            value: "RVL_Volcano",
        },
        {
            label: "RPM_Glassy",
            value: "RPM_Glassy",
        },
        {
            label: "RPM_Higgzy",
            value: "RPM_Higgzy",
        },
        {
            label: "RPM_Luukvdm",
            value: "RPM_Luukvdm",
        },
        {
            label: "Andrewx2905",
            value: "Andrewx2905",
        },
        {
            label: "RPM_KingPinquinn",
            value: "RPM_KingPinquinn",
        },
        {
            label: "Spiderman__F1",
            value: "Spiderman__F1",
        },
        {
            label: "RPM_Zabijaka420",
            value: "RPM_Zabijaka420",
        },
        {
            label: "ET8_Evil",
            value: "ET8_Evil",
        },
        {
            label: "RPM_Entity123",
            value: "RPM_Entity123",
        },
        {
            label: "GiraffeBha",
            value: "GiraffeBha",
        },
        {
            label: "C_osmo90",
            value: "C_osmo90",
        },
        {
            label: "maggot361975",
            value: "maggot361975",
        },
        {
            label: "RPM_Dragos",
            value: "RPM_Dragos",
        },
        {
            label: "RPM_husssrc",
            value: "RPM_husssrc",
        },
        {
            label: "davie_w",
            value: "davie_w",
        },
        {
            label: "Juul_Warrior_",
            value: "Juul_Warrior_",
        }
    ])
    const [driverOptionsPremier, setDriverOptionsPremier] = useState([
        {
            "label": "Banter_Branden_0",
            "value": "Banter_Branden_0",
        },
        {
            "label": "LearnerDriver86",
            "value": "LearnerDriver86",
        },
        {
            "label": "AL2737",
            "value": "AL2737",
        },
        {
            "label": "Jetfish11",
            "value": "Jetfish11",
        },
        {
            "label": "RVL_Volcano",
            "value": "RVL_Volcano",
        },
        {
            "label": "RPM_Higgzy",
            "value": "RPM_Higgzy",
        },
        {
            "label": "Davie_w",
            "value": "Davie_w",
        },
        {
            "label": "Stebo__",
            "value": "Stebo__",
        },
        {
            "label": "Sminotla789",
            "value": "Sminotla789",
        },
        {
            "label": "Maggot361975",
            "value": "Maggot361975",
        },
        {
            "label": "RPM_Dragos",
            "value": "RPM_Dragos",
        },
        {
            "label": "RPM_Kvkfc",
            "value": "RPM_Kvkfc",
        },
        {
            "label": "RPM_KingPinquinn",
            "value": "RPM_KingPinquinn",
        },
        {
            "label": "RPM_Yeast",
            "value": "RPM_Yeast",
        },
        {
            "label": "RPM_Luukvdm",
            "value": "RPM_Luukvdm",
        },
        {
            "label": "ET8_Evil",
            "value": "ET8_Evil",
        },
        {
            "label": "Zykotikz",
            "value": "Zykotikz",
        },
        {
            "label": "No Driver",
            "value": "No Driver",
        },
        {
            "label": "No Driver",
            "value": "No Driver",
        },
        {
            "label": "No Driver",
            "value": "No Driver",
        }
    ])
    const [driverOptions, setDriverOptions] = useState([])
    const [rebindAbly, setRebindAbly] = useState({})
    const [centrifugoJWT, setCentrifugoJWT] = useState("");

    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
    const [cellClassRules, setCellClassRules] = useState(); // Set rowData to Array of Objects, one Object per Row

    // const cellClassRules = ;
    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState([
        { field: 'name', filter: true, cellClassRules: cellClassRules },
        { field: 'sector1', filter: true, cellClassRules: cellClassRules },
        { field: 'sector2', filter: true, cellClassRules: cellClassRules },
        { field: 'sector3', filter: true, cellClassRules: cellClassRules },
        { field: 'lap', filter: true, cellClassRules: cellClassRules },
    ]);

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => ({
        sortable: true
    }));

    // Example of consuming Grid Event
    const cellClickedListener = useCallback(event => {
        console.log('cellClicked', event);
    }, []);

    // Example load data from server
    // useEffect(() => {
    //     fetch('https://www.ag-grid.com/example-assets/row-data.json')
    //         .then(result => result.json())
    //         .then(rowData => setRowData(rowData))
    // }, []);

    // Example using Grid's API
    const buttonListener = useCallback(e => {
        gridRef.current.api.deselectAll();
    }, []);



    const newDataLoaded = () => toast("The current tables have been updated.");

    // useEffects for redirecting non-logged in users and blocking homepage from showing during check
    useEffect(() => {
        if (loading) {
            console.log('loading', loading);
            console.log('user', user)
            return;
        }
        if (user === null) {
            console.log('should redirect?')
            navigate("/login")
        };
    }, [user, loading]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                const email = user.email;
                // ...
                console.log("uid", uid, "email", email)
            } else {
                // User is signed out
                // ...
                console.log("user is logged out")
            }
        });

    }, [])

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    useEffect(() => {
        if (user) {
            // get the JWT token from the server
            console.log('try for JWT');
            const url = process.env.REACT_APP_JWT_ENDPOINT;
            const payload = {
                userID: user.uid,
            };

            try {
                axios.post(url, payload).then((response) => {
                    console.log(response.data.token)
                    setCentrifugoJWT(response.data.token);
                });
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.log('no user yet')
        }

    }, [user])

    useEffect(() => {

        console.log('start the CENTRIFUGO use effect');
        let subDriverLaps;
        let subFastestLaps;
        let centrifuge
        if (centrifugoJWT !== "") {
            toast.success(`Established Connection.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            centrifuge = new Centrifuge("wss://messaging.purplekraken.dev/centrifugo/connection/websocket", {
                token: centrifugoJWT,
            });
            centrifuge.on('connecting', function (ctx) {
                console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
            }).on('connected', function (ctx) {
                console.log(`connected over ${ctx.transport}`);
            }).on('disconnected', function (ctx) {
                console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
            }).connect();

            console.log(rebindAbly)

            if (rebindAbly.drivers !== undefined && rebindAbly.drivers.length === 2) {
                centrifuge.publish("rpm:driverSelect", { drivers: rebindAbly.drivers }).then(function (res) {
                    console.log('successfully published drivers selected', rebindAbly.drivers.length);
                }, function (err) {
                    console.log('publish error', err);
                });
            }

            subFastestLaps = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:fastestLaps`);
            subDriverLaps = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:lapCompare`);

            subFastestLaps.on('publication', function (ctx) {
                console.log('-------- New Fastest Lap Data Recieved -----')
                const battle = ctx.data;
                console.log(battle)
                const lowestSector1 = battle.reduce((min, current) => {
                    return parseFloat(current.sector1) < parseFloat(min.sector1) ? current : min;
                });
                const lowestSector2 = battle.reduce((min, current) => {
                    return parseFloat(current.sector2) < parseFloat(min.sector2) ? current : min;
                });
                const lowestSector3 = battle.reduce((min, current) => {
                    return parseFloat(current.sector3) < parseFloat(min.sector3) ? current : min;
                });
                console.log(lowestSector1, lowestSector2, lowestSector3)
                const rules = {
                    "cell-1-pass": params => params.value === lowestSector1.sector1,
                    "cell-2-pass": params => params.value === lowestSector2.sector2,
                    "cell-3-pass": params => params.value === lowestSector3.sector3,
                    "cell-lap-pass": params => params.value === battle[0].lap
                };
                setColumnDefs([
                    { field: 'name', filter: true, cellClassRules: rules },
                    { field: 'sector1', filter: true, cellClassRules: rules },
                    { field: 'sector2', filter: true, cellClassRules: rules },
                    { field: 'sector3', filter: true, cellClassRules: rules },
                    { field: 'lap', filter: true, cellClassRules: rules },
                ])
                setRowData(battle);
                // gridRef.current.api.redrawRows()


            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();

            subDriverLaps.on('publication', function (ctx) {
                console.log('-------- New Driver Laps Data Recieved -----')
                const delta = ctx.data;
                console.log(delta)
            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();

        } else {
            console.log('Could not generate a secure JWT');
            toast.error(`Could not generate a secure JWT.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }

        // run the cleanup to unsubscribe from pusher and unbind the events
        return () => {
            console.log('clean up');

            if (subFastestLaps) {
                subFastestLaps.unsubscribe();
                subFastestLaps.removeAllListeners();
            }

            if (subDriverLaps) {
                subDriverLaps.unsubscribe();
                subDriverLaps.removeAllListeners();
            }

            if (centrifuge) {
                centrifuge.disconnect();
            }
        };

    }, [rebindAbly, centrifugoJWT]);

    const handleDriverSelectOne = (selectedOption) => {
        // if(selectedOption)
        setDriverSelectOne(selectedOption.label);
        console.log(`Driver Option selected:`, selectedOption.label);
    };

    const handleDriverSelectTwo = (selectedOption) => {
        // if(selectedOption)
        setDriverSelectTwo(selectedOption.label);
        console.log(`Driver Option selected:`, selectedOption.label);
    };

    function refreshPusher(league) {
        console.log("refresh select league")
        if (league === "premier") {
            console.log(driverOptionsPremier)
            setDriverOptions(driverOptionsPremier)
        }
        if (league === "championship") {
            setDriverOptions(driverOptionsChamp)
        }
        if (league === "first") {
            setDriverOptions(driverOptionsFirst)
        }
        if (league === "offline") {
            setDriverOptions(driverOptionsOffline)
        }
        setSelectDivision(league);
    }

    function setDrivers() {
        let driverSelectOneValid = driverSelectOne !== 'Not Set' && driverSelectOne !== 'No Driver' ? true : false;
        let driverSelectTwoValid = driverSelectTwo !== 'Not Set' && driverSelectTwo !== 'No Driver' ? true : false;
        if (driverSelectOneValid && driverSelectTwoValid) {
            setRebindAbly({ drivers: [driverSelectOne, driverSelectTwo] });
        } else {
            console.log('Please select two drivers.');
            toast.error(`Please select two drivers.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }

    return (
        <>
            {loading !== true && user !== null &&
                <div>
                    <nav>
                        <img src={rpmLogoGold} style={{ "cursor": "pointer" }} onClick={() => { navigate("/") }} />
                        <div className='adminButtons headerButtons'>
                            <div>
                                <button onClick={() => { navigate("/live-standings") }}>
                                    Live Standings
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/session-data") }}>
                                    Session Data
                                </button>
                            </div>
                        </div>
                        <span style={{ 'color': 'white' }}>League ID - {process.env.REACT_APP_LEAGUE}</span>
                        <div>
                            <button onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    </nav>
                    {selectDivision === "Not Set" ? <div style={{ "textAlign": "center" }}><h2 style={{ "color": "white" }}>Select League for Data Binding</h2></div> : null}
                    <div className='adminButtons'>
                        <div>
                            <button style={{ "background": selectDivision === "premier" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("premier") }}>
                                Premier
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "championship" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("championship") }}>
                                Championship
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "first" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("first") }}>
                                First
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "practice" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("practice") }}>
                                Practice
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "offline" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("offline") }}>
                                Offline
                            </button>
                        </div>
                    </div>
                    <div className="App">
                        <Tabs>
                            <TabList>
                                <Tab>Lap History</Tab>
                            </TabList>
                            <TabPanel>
                                <div>

                                    {/* Example using Grid's API */}
                                    <button onClick={buttonListener}>Push Me</button>

                                    {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
                                    <div className="ag-theme-alpine" style={{ width: 'cacl(100% - 4rem)', height: 500, margin: '2rem' }}>

                                        <AgGridReact ref={gridRef} rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} animateRows={true} rowSelection='multiple' onCellClicked={cellClickedListener} />
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>


                    </div>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
            }
        </>
    );
}

export default LapHistory