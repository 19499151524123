import React from 'react';




export default function DriverLapsFront({ driverData }) {
    console.log('driverData', driverData);
    function returnBackground(number) {
        let background = number === 1 ? 'gold' : number === 2 ? 'silver' : number === 3 ? 'bronze' : 'white';
        return background;
    }
    return (
        <>
            {
                <div className='statContainer'>
                    { // for the first 5 entries in driverData, return a div with the name and the laps leadDriver
                        driverData.map((driver, index) => {
                            return <div className='finish' key={index} >{driver['driver_name']} - <strong>{driver['laps_lead']}</strong></div>
                        }
                        )
                    }
                </div>
            }
        </>
    )
}
