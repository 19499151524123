import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



export default function DriverDamage({ damage }) {
    // console.log('damage data: ', damage)
    return (
        <>
            {
                <div style={{ "display": "flex", "justifyContent": "center", "marginTop": "5rem" }}>

                    <div className='tyres'>
                        <h2 style={{ "color": "white", "textAlign": "left", "marginLeft": "1rem" }}>Tyre Wear (%)</h2>
                        <div className='tyreGrid'>
                            {damage.tyreWear.map((dmg, index) => {
                                return <div className={
                                    index === 0 ? 'rearLeft tyreBox'
                                        : index === 1 ? 'rearRight tyreBox'
                                            : index === 2 ? 'frontLeft tyreBox'
                                                : 'frontRight tyreBox'
                                } key={index}
                                    style={{
                                        "order":
                                            index === 0 ? '3'
                                                : index === 1 ? '4'
                                                    : index === 2 ? '1'
                                                        : '2'
                                    }}
                                >
                                    <div className='tyre' style={{
                                        background: dmg.toFixed(2) < 20 ? 'green'
                                            : dmg.toFixed(2) < 40 ? 'yellow'
                                                : dmg.toFixed(2) < 60 ? 'orange'
                                                    : 'red'
                                    }}>

                                    </div>
                                    <p style={{ "color": "white" }}>{
                                        index === 0 ? 'Rear Left'
                                            : index === 1 ? 'Rear Right'
                                                : index === 2 ? 'Front Left'
                                                    : 'Front Right'
                                    }</p>
                                    <p style={{ "color": "white" }}>Wear {dmg.toFixed(2)}%</p>
                                </div>
                            })}
                        </div>
                    </div>

                    <div className='carDamage'>
                        <h2 style={{ "color": "white", "textAlign": "left" }}>Car Damage (%)</h2>
                        <div className='damageGrid'>
                            <div className='damagePart frontWings'>
                                <div className='partContainer'>
                                    <div className='part-desc'>
                                        <div className='left'>
                                            <h5 style={{ "color": "white" }}>Front Left Wing</h5>
                                            <p style={{ "color": "white" }}>Damage </p><p style={{ "color": "white" }}>{damage.frontWingLeft}%</p>
                                        </div>
                                        <div className='right'>
                                            <h5 style={{ "color": "white" }}>Front Right Wing</h5>
                                            <p style={{ "color": "white" }}>Damage </p><p style={{ "color": "white" }}>{damage.frontWingRight}%</p>
                                        </div>
                                        <div className='Sidepods'>
                                            <h5 style={{ "color": "white" }}>Sidepods</h5>
                                            <p style={{ "color": "white" }}>Damage </p><p style={{ "color": "white" }}>{damage.sidepod}%</p>
                                        </div>
                                        <div className='Floor'>
                                            <h5 style={{ "color": "white" }}>Floor</h5>
                                            <p style={{ "color": "white" }}>Damage </p><p style={{ "color": "white" }}>{damage.floor}%</p>
                                        </div>
                                        <div className='Diffuser'>
                                            <h5 style={{ "color": "white" }}>Diffuser</h5>
                                            <p style={{ "color": "white" }}>Damage </p><p style={{ "color": "white" }}>{damage.diffuser}%</p>
                                        </div>
                                        <div className='RearWing'>
                                            <h5 style={{ "color": "white" }}>Rear Wing</h5>
                                            <p style={{ "color": "white" }}>Damage </p><p style={{ "color": "white" }}>{damage.rearWing}%</p>
                                        </div>
                                    </div>
                                    <svg width="263.2px" height="686px" viewBox="0 0 752 1960">
                                        <title>Group</title>
                                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Group" transform="translate(0.000000, 2.136719)" stroke="#000000">
                                                <path fill={damage.frontWingRight < 5 ? 'green'
                                                    : damage.frontWingRight < 25 ? 'yellow'
                                                        : damage.frontWingRight < 50 ? 'orange'
                                                            : 'red'} d="M671.953125,294.078125 L631.417969,109.101563 L602.695545,34.4217898 C601.704268,31.8444206 602.990051,28.9514617 605.56742,27.9601854 C605.579473,27.9555497 605.591544,27.9509607 605.603633,27.9464185 L606.167969,27.734375 C608.10949,27.0048684 609.092023,24.8395721 608.362516,22.8980505 C608.187495,22.4322462 607.921647,22.0058689 607.580421,21.6436943 L600.886719,14.5390625 L600.886719,14.5390625 L573.738281,-2.1640625 L547.355469,-10.34375 L519.375,-20.7773437 L482.910156,-28.8867187 L443.714844,-39 C433.779013,-36.8687398 427.221721,-33.497646 424.042969,-28.8867188 C420.864216,-24.2757915 418.956664,-15.3682394 418.320312,-2.1640625 C422.131083,5.9678012 430.595926,18.7750929 443.714844,36.2578125 C450.677503,45.5364906 471.316175,61.5580069 482.910156,76.1484375 C489.744283,84.7488363 498.019023,98.3634196 507.734375,116.992188 C516.526149,130.818989 522.649847,141.627582 526.105469,149.417969 C529.561091,157.208355 533.182184,168.368511 536.96875,182.898438 L558.820312,198.933594 L558.820312,204.214844 L567.074219,249.742188 C583.598831,259.014284 594.869665,266.656211 600.886719,272.667969 C606.903773,278.679726 610.424606,285.816445 611.449219,294.078125 L671.953125,294.078125 Z" id="frontWingRight" strokeWidth="4" transform="translate(545.136719, 127.539062) scale(1, -1) rotate(90.000000) translate(-545.136719, -127.539062) "></path>
                                                <path d="M551.134739,39.3378642 C538.261201,45.1073855 531.090628,50.3756147 529.62302,55.1425517 C528.155413,59.9094888 530.413876,68.8997232 536.398411,82.1132549 C551.351809,102.963225 562.09009,118.732756 568.613255,129.421849 C575.136419,140.110941 582.135117,153.405863 589.609349,169.306614 C596.962038,183.754426 602.262168,195.03763 605.509739,203.156224 C608.75731,211.274818 612.965644,223.761797 618.134739,240.617161 C621.993183,246.678187 625.269224,251.353317 627.962864,254.642552 C630.656504,257.931787 636.774343,262.036604 646.31638,266.957005" id="Path-2" strokeWidth="3" transform="translate(587.752930, 153.147435) scale(1, -1) rotate(90.000000) translate(-587.752930, -153.147435) "></path>
                                                <path d="M559.829664,19.7421875 C549.531084,23.3560033 543.711436,27.3353644 542.370719,31.680271 C541.030002,36.0251775 540.150569,40.9878736 539.732422,46.5683594 C543.951642,58.8946134 549.040834,69.9291186 555,79.671875 C560.959166,89.4146314 568.622439,102.984293 577.989821,120.380859 C584.986855,137.240142 589.968764,149.369699 592.935547,156.769531 C595.90233,164.169363 599.821601,176.893972 604.693359,194.943359 C606.901175,207.640642 609.235456,215.488299 611.696202,218.486328 C614.156947,221.484358 619.309645,228.236311 627.154297,238.742187" id="Path-2" strokeWidth="3" transform="translate(583.443359, 129.242187) scale(1, -1) rotate(90.000000) translate(-583.443359, -129.242187) "></path>
                                                <path d="M562.843201,3.75572492 C552.544622,7.36954068 546.724973,11.3489018 545.384256,15.6938084 C544.043539,20.0387149 543.164107,25.0014111 542.745959,30.5818968 C550.184955,41.865182 556.884036,52.3782029 562.843201,62.1209593 C568.802367,71.8637157 574.855753,85.9548615 581.003359,104.394397 C586.777737,123.106544 591.148317,136.162534 594.1151,143.562366 C597.081883,150.962197 599.174331,163.514932 600.392444,181.220569 C604.483782,198.607956 607.759823,208.800664 610.220569,211.798694 C612.681314,214.796723 614.523762,215.7824 615.747912,214.755725" id="Path-2" strokeWidth="3" transform="translate(579.246936, 109.452148) scale(1, -1) rotate(90.000000) translate(-579.246936, -109.452148) "></path>
                                                <path d="M559.454119,-16.1507663 C547.313065,-15.312468 540.57218,-12.7208656 539.231463,-8.37595909 C537.890746,-4.03105255 538.878376,1.66230683 542.194353,8.70411904 C544.77137,13.342873 549.039461,20.5336282 554.998627,30.2763847 C560.957792,40.0191411 567.349857,55.7962041 574.174822,77.6075738 C577.693716,100.890595 580.936555,116.232022 583.903338,123.631853 C586.870121,131.031685 589.751631,139.613065 592.547869,149.375994 C596.639207,166.763381 599.915249,176.95609 602.375994,179.954119 C604.836739,182.952149 606.679187,183.937826 607.903338,182.91115" id="Path-2" strokeWidth="3" transform="translate(573.277894, 83.576616) scale(1, -1) rotate(90.000000) translate(-573.277894, -83.576616) "></path>
                                                <path d="M552.987783,-34.617102 C542.82199,-35.0860954 537.068735,-33.1481389 535.728018,-28.8032323 C534.387301,-24.4583258 534.387301,-18.1113206 535.728018,-9.76221669 C538.305034,-5.12346269 542.573125,2.06729252 548.532291,11.8100489 C554.491456,21.5528054 560.883522,37.3298684 567.708486,59.1412381 C571.22738,82.4242594 574.470219,97.7656859 577.437002,105.165518 C580.403785,112.565349 583.285295,121.14673 586.081533,130.909658 C587.101257,139.226733 588.841491,144.884285 591.302236,147.882315 C593.762982,150.880344 596.629518,152.532126 599.901846,152.837662" id="Path-2" strokeWidth="3" transform="translate(567.312163, 59.076011) scale(1, -1) rotate(90.000000) translate(-567.312163, -59.076011) "></path>
                                                <line x1="519.667969" y1="67.53125" x2="427.667969" y2="67.53125" id="Line" strokeWidth="3" strokeLinecap="square" transform="translate(473.667969, 67.531250) scale(1, -1) rotate(90.000000) translate(-473.667969, -67.531250) "></line>
                                                <path fill={damage.frontWingLeft < 5 ? 'green'
                                                    : damage.frontWingLeft < 25 ? 'yellow'
                                                        : damage.frontWingLeft < 50 ? 'orange'
                                                            : 'red'} d="M337.03125,294.078125 L296.496094,109.101563 L267.77367,34.4217898 C266.782393,31.8444206 268.068176,28.9514617 270.645545,27.9601854 C270.657598,27.9555497 270.669669,27.9509607 270.681758,27.9464185 L271.246094,27.734375 C273.187615,27.0048684 274.170148,24.8395721 273.440641,22.8980505 C273.26562,22.4322462 272.999772,22.0058689 272.658546,21.6436943 L265.964844,14.5390625 L265.964844,14.5390625 L238.816406,-2.1640625 L212.433594,-10.34375 L184.453125,-20.7773437 L147.988281,-28.8867187 L108.792969,-39 C98.8571381,-36.8687398 92.2998465,-33.497646 89.1210937,-28.8867187 C85.942341,-24.2757915 84.034789,-15.3682394 83.3984375,-2.1640625 C87.2092077,5.9678012 95.6740515,18.7750929 108.792969,36.2578125 C115.755628,45.5364906 136.3943,61.5580069 147.988281,76.1484375 C154.822408,84.7488363 163.097148,98.3634196 172.8125,116.992188 C181.604274,130.818989 187.727972,141.627582 191.183594,149.417969 C194.639216,157.208355 198.260309,168.368511 202.046875,182.898438 L223.898437,198.933594 L223.898437,204.214844 L232.152344,249.742188 C248.676956,259.014284 259.94779,266.656211 265.964844,272.667969 C271.981898,278.679726 275.502731,285.816445 276.527344,294.078125 L337.03125,294.078125 Z" id="frontWingLeft" strokeWidth="4" transform="translate(210.214844, 127.539063) rotate(-90.000000) translate(-210.214844, -127.539063) "></path>
                                                <path d="M130.980442,39.3378642 C118.106904,45.1073855 110.936331,50.3756147 109.468724,55.1425517 C108.001116,59.9094888 110.259579,68.8997232 116.244114,82.1132549 C131.197512,102.963225 141.935794,118.732756 148.458958,129.421849 C154.982122,140.110941 161.98082,153.405863 169.455052,169.306614 C176.807741,183.754426 182.107871,195.03763 185.355442,203.156224 C188.603013,211.274818 192.811347,223.761797 197.980442,240.617161 C201.838886,246.678187 205.114927,251.353317 207.808567,254.642552 C210.502207,257.931787 216.620046,262.036604 226.162083,266.957005" id="Path-2" strokeWidth="3" transform="translate(167.598633, 153.147435) rotate(-90.000000) translate(-167.598633, -153.147435) "></path>
                                                <path d="M148.294508,19.7421875 C137.995928,23.3560033 132.17628,27.3353644 130.835563,31.680271 C129.494845,36.0251775 128.615413,40.9878736 128.197266,46.5683594 C132.416485,58.8946134 137.505678,69.9291186 143.464844,79.671875 C149.424009,89.4146314 157.087283,102.984293 166.454665,120.380859 C173.451699,137.240142 178.433608,149.369699 181.400391,156.769531 C184.367174,164.169363 188.286444,176.893972 193.158203,194.943359 C195.366019,207.640642 197.7003,215.488299 200.161045,218.486328 C202.621791,221.484358 207.774489,228.236311 215.619141,238.742187" id="Path-2" strokeWidth="3" transform="translate(171.908203, 129.242187) rotate(-90.000000) translate(-171.908203, -129.242187) "></path>
                                                <path d="M159.700892,3.75572492 C149.402312,7.36954068 143.582664,11.3489018 142.241947,15.6938084 C140.90123,20.0387149 140.021798,25.0014111 139.60365,30.5818968 C147.042646,41.865182 153.741726,52.3782029 159.700892,62.1209593 C165.660058,71.8637157 171.713443,85.9548615 177.86105,104.394397 C183.635427,123.106544 188.006008,136.162534 190.972791,143.562366 C193.939574,150.962197 196.032022,163.514932 197.250134,181.220569 C201.341472,198.607956 204.617514,208.800664 207.078259,211.798694 C209.539005,214.796723 211.381453,215.7824 212.605603,214.755725" id="Path-2" strokeWidth="3" transform="translate(176.104627, 109.452148) rotate(-90.000000) translate(-176.104627, -109.452148) "></path>
                                                <path d="M168.249893,-16.1507663 C156.10884,-15.312468 149.367954,-12.7208656 148.027237,-8.37595909 C146.68652,-4.03105255 147.67415,1.66230683 150.990128,8.70411904 C153.567144,13.342873 157.835235,20.5336282 163.794401,30.2763847 C169.753566,40.0191411 176.145632,55.7962041 182.970596,77.6075738 C186.48949,100.890595 189.732329,116.232022 192.699112,123.631853 C195.665895,131.031685 198.547405,139.613065 201.343643,149.375994 C205.434981,166.763381 208.711023,176.95609 211.171768,179.954119 C213.632514,182.952149 215.474962,183.937826 216.699112,182.91115" id="Path-2" strokeWidth="3" transform="translate(182.073668, 83.576616) rotate(-90.000000) translate(-182.073668, -83.576616) "></path>
                                                <path d="M173.71502,-34.617102 C163.549227,-35.0860954 157.795972,-33.1481389 156.455255,-28.8032323 C155.114537,-24.4583258 155.114537,-18.1113206 156.455255,-9.76221669 C159.032271,-5.12346269 163.300362,2.06729252 169.259528,11.8100489 C175.218693,21.5528054 181.610758,37.3298684 188.435723,59.1412381 C191.954617,82.4242594 195.197456,97.7656859 198.164239,105.165518 C201.131022,112.565349 204.012532,121.14673 206.80877,130.909658 C207.828494,139.226733 209.568728,144.884285 212.029473,147.882315 C214.490219,150.880344 217.356755,152.532126 220.629083,152.837662" id="Path-2" strokeWidth="3" transform="translate(188.039400, 59.076011) rotate(-90.000000) translate(-188.039400, -59.076011) "></path>
                                                <line x1="327.683594" y1="67.53125" x2="235.683594" y2="67.53125" id="Line" strokeWidth="3" strokeLinecap="square" transform="translate(281.683594, 67.531250) rotate(-90.000000) translate(-281.683594, -67.531250) "></line>
                                                <path fill="darkgray" d="M416.964844,104.054688 C411.69861,88.2554813 406.748089,77.8876428 402.113281,72.9511719 C397.478474,68.014701 389.025349,64.1064979 376.753906,61.2265625 C365.010666,64.0806386 356.692958,67.9888417 351.800781,72.9511719 C346.908604,77.913502 341.152094,88.2813406 334.53125,104.054688 L319.480469,349.4375 L310.863281,443.726562 L299.644531,657.734375 L257.777344,956.054688 C255.594945,1006.11035 257.357966,1049.61035 263.066406,1086.55469 C268.774846,1123.49903 282.662867,1181.29069 304.730469,1259.92969 C324.766967,1346.4635 338.31254,1418.56506 345.367188,1476.23438 C350.715349,1519.95375 360.824866,1605.66204 362.71875,1716.42188 C363.121616,1739.98263 361.387241,1775.91362 357.515625,1824.21484 L395.523438,1824.21484 C391.704792,1774.84982 389.807656,1738.91883 389.832031,1716.42188 C389.942585,1614.38501 403.052692,1514.48238 407.765625,1476.23438 C414.03721,1425.33707 427.845804,1355.4816 449.191406,1266.66797 C473.902015,1186.45552 489.202796,1126.41776 495.09375,1086.55469 C500.984704,1046.69162 500.984704,1003.19162 495.09375,956.054688 L454.382813,657.734375 L442.027344,443.726562 L432.042969,349.4375 L416.964844,104.054688 Z" id="monocoque" strokeWidth="3"></path>
                                                <path fill={damage.sidepod < 5 ? 'green'
                                                    : damage.sidepod < 25 ? 'yellow'
                                                        : damage.sidepod < 50 ? 'orange'
                                                            : 'red'} d="M541.871953,960.535156 C508.099813,965.822232 483.805256,970.725878 468.988281,975.246094 C454.171307,979.76631 439.318442,986.805372 424.429687,996.363281 C398.578203,1009.67623 387.447994,1036.04081 391.039062,1075.45703 C394.630131,1114.87325 415.149988,1191.57117 452.598633,1305.55078 C504.715364,1398.72029 542.782837,1476.64216 566.80105,1539.31641 C590.819264,1601.99065 608.776205,1670.26148 620.671875,1744.12891 L646.367187,1744.12891 C641.579371,1647.63993 637.279892,1579.3691 633.46875,1539.31641 C626.639548,1467.54581 615.168944,1402.27839 609.046875,1363.30078 C590.272263,1243.76775 552.201786,1152.91887 541.871953,1075.45703 C538.791874,1052.35999 538.791874,1014.0527 541.871953,960.535156 Z" id="sidepod" strokeWidth="4" transform="translate(518.374627, 1352.332031) scale(-1, 1) translate(-518.374627, -1352.332031) "></path>
                                                <path fill={damage.sidepod < 5 ? 'green'
                                                    : damage.sidepod < 25 ? 'yellow'
                                                        : damage.sidepod < 50 ? 'orange'
                                                            : 'red'} d="M256.871953,960.535156 C223.099813,965.822232 198.805256,970.725878 183.988281,975.246094 C169.171307,979.76631 154.318442,986.805372 139.429687,996.363281 C113.578203,1009.67623 102.447994,1036.04081 106.039062,1075.45703 C109.630131,1114.87325 130.149988,1191.57117 167.598633,1305.55078 C219.715364,1398.72029 257.782837,1476.64216 281.80105,1539.31641 C305.819264,1601.99065 323.776205,1670.26148 335.671875,1744.12891 L361.367187,1744.12891 C356.579371,1647.63993 352.279892,1579.3691 348.46875,1539.31641 C341.639548,1467.54581 330.168944,1402.27839 324.046875,1363.30078 C305.272263,1243.76775 267.201786,1152.91887 256.871953,1075.45703 C253.791874,1052.35999 253.791874,1014.0527 256.871953,960.535156 Z" id="sidepod" strokeWidth="4"></path>
                                                <path fill={damage.floor < 5 ? 'green'
                                                    : damage.floor < 25 ? 'yellow'
                                                        : damage.floor < 50 ? 'orange'
                                                            : 'red'} d="M135.773437,989.632812 L113.530103,1003.59564 C98.594908,1012.97093 89.060691,1028.93898 87.8923271,1046.53418 C82.4073463,1129.13628 80.5070393,1186.52593 82.1914062,1218.70313 C88.4919556,1339.06526 101.452331,1386.32312 99.1328125,1401.54688 C96.1252383,1421.28656 115.4644,1429.52603 208.931641,1580.20703 C216.058455,1591.69636 232.038559,1646.33698 256.871953,1744.12891 L335.671875,1744.12891 C322.734704,1683.74432 310.916995,1636.63624 300.21875,1602.80469 C289.520505,1568.97313 271.702796,1522.36376 246.765625,1462.97656 C231.716994,1435.08811 219.105666,1411.02821 208.931641,1390.79688 C198.757615,1370.56554 184.979946,1342.15018 167.598633,1305.55078 C149.252056,1257.88996 136.104595,1220.81054 128.15625,1194.3125 C120.207905,1167.81446 110.533425,1128.81056 99.1328125,1077.30078 C98.9530175,1053.47777 101.036102,1036.36709 105.382066,1025.96875 C109.72803,1015.57041 119.858487,1003.45843 135.773437,989.632812 Z" id="Floor" strokeWidth="4"></path>
                                                <path fill={damage.floor < 5 ? 'green'
                                                    : damage.floor < 25 ? 'yellow'
                                                        : damage.floor < 50 ? 'orange'
                                                            : 'red'} d="M468.773437,989.632812 L446.530103,1003.59564 C431.594908,1012.97093 422.060691,1028.93898 420.892327,1046.53418 C415.407346,1129.13628 413.507039,1186.52593 415.191406,1218.70313 C421.491956,1339.06526 434.452331,1386.32312 432.132812,1401.54688 C429.125238,1421.28656 448.4644,1429.52603 541.931641,1580.20703 C549.058455,1591.69636 565.038559,1646.33698 589.871953,1744.12891 L668.671875,1744.12891 C655.734704,1683.74432 643.916995,1636.63624 633.21875,1602.80469 C622.520505,1568.97313 604.702796,1522.36376 579.765625,1462.97656 C564.716994,1435.08811 552.105666,1411.02821 541.931641,1390.79688 C531.757615,1370.56554 517.979946,1342.15018 500.598633,1305.55078 C482.252056,1257.88996 469.104595,1220.81054 461.15625,1194.3125 C453.207905,1167.81446 443.533425,1128.81056 432.132812,1077.30078 C431.953018,1053.47777 434.036102,1036.36709 438.382066,1025.96875 C442.72803,1015.57041 452.858487,1003.45843 468.773437,989.632812 Z" id="Floor" strokeWidth="4" transform="translate(541.634847, 1366.880859) scale(-1, 1) translate(-541.634847, -1366.880859) "></path>
                                                <polygon fill={damage.rearWing < 5 ? 'green'
                                                    : damage.rearWing < 25 ? 'yellow'
                                                        : damage.rearWing < 50 ? 'orange'
                                                            : 'red'} id="RearWing" strokeWidth="4" points="357.515625 1824.21484 202.191406 1824.21484 202.191406 1926.33984 549.414062 1926.33984 549.414062 1824.21484"></polygon>
                                                <line x1="202.191406" y1="1885.375" x2="549.414062" y2="1885.375" id="Path" strokeWidth="3"></line>
                                                <polygon fill="darkgray" id="Path-3" strokeWidth="3" points="536.464844 1824.21484 536.464844 1796.375 549.414062 1804.90625 549.414062 1824.21484"></polygon>
                                                <polygon fill="darkgray" id="Path-3" strokeWidth="3" transform="translate(208.939453, 1810.294922) scale(-1, 1) translate(-208.939453, -1810.294922) " points="202.464844 1824.21484 202.464844 1796.375 215.414062 1804.90625 215.414062 1824.21484"></polygon>
                                                <polygon fill="darkgray" id="Path-3" strokeWidth="3" transform="translate(208.939453, 1940.294922) scale(1, -1) translate(-208.939453, -1940.294922) " points="202.464844 1954.21484 202.464844 1926.375 215.414062 1934.90625 215.414062 1954.21484"></polygon>
                                                <polygon fill="darkgray" id="Path-3" strokeWidth="3" transform="translate(542.939453, 1940.294922) scale(-1, -1) translate(-542.939453, -1940.294922) " points="536.464844 1954.21484 536.464844 1926.375 549.414062 1934.90625 549.414062 1954.21484"></polygon>
                                                <polygon fill={damage.diffuser < 5 ? 'green'
                                                    : damage.diffuser < 25 ? 'yellow'
                                                        : damage.diffuser < 50 ? 'orange'
                                                            : 'red'} id="diffuser" strokeWidth="4" transform="translate(485.505859, 1783.191406) scale(-1, 1) translate(-485.505859, -1783.191406) " points="575.367187 1764.98828 395.644531 1764.98828 395.644531 1801.39453 422.939453 1791.01562 575.367187 1796.375"></polygon>
                                                <polygon fill={damage.diffuser < 5 ? 'green'
                                                    : damage.diffuser < 25 ? 'yellow'
                                                        : damage.diffuser < 50 ? 'orange'
                                                            : 'red'} id="diffuser" strokeWidth="4" points="361.367187 1764.98828 181.644531 1764.98828 181.644531 1801.39453 208.939453 1791.01562 361.367187 1796.375"></polygon>
                                                <path fill="darkgray" d="M601.480469,350.4375 L586.09375,566.386719 C571.762211,543.551184 556.021612,526.198319 538.871953,514.328125 C521.722293,502.457931 486.850913,484.779546 434.257812,461.292969 L434.257812,461.292969 L434.257812,385.074219 C486.291346,380.245604 523.264002,375.56201 545.175781,371.023438 C567.08756,366.484865 585.855789,359.622886 601.480469,350.4375 L601.480469,350.4375 Z M578.324219,403.109375 C549.230307,404.959697 528.247109,407.091208 515.374627,409.503906 C504.514296,411.539466 490.644566,415.421316 473.765438,421.149456 L472.769077,421.488535 C470.155646,422.380409 468.760049,425.222021 469.651924,427.835451 C469.986412,428.815591 470.617,429.667681 471.456586,430.274024 C479.133707,435.81838 488.098699,441.085111 498.351563,446.074219 C512.089841,452.759351 536.057288,462.798413 570.253906,476.191406 L589,458.412109 L594.273438,417.871094 C596.468161,414.324284 596.468161,411.535221 594.273438,409.503906 C592.078714,407.472591 586.762308,405.341081 578.324219,403.109375 Z" id="Combined-Shape" strokeWidth="4" transform="translate(517.869141, 458.412109) scale(-1, 1) translate(-517.869141, -458.412109) "></path>
                                                <path fill="darkgray" d="M318.480469,350.4375 L303.09375,566.386719 C288.762211,543.551184 273.021612,526.198319 255.871953,514.328125 C238.722293,502.457931 203.850913,484.779546 151.257812,461.292969 L151.257812,461.292969 L151.257812,385.074219 C203.291346,380.245604 240.264002,375.56201 262.175781,371.023438 C284.08756,366.484865 302.855789,359.622886 318.480469,350.4375 L318.480469,350.4375 Z M295.324219,403.109375 C266.230307,404.959697 245.247109,407.091208 232.374627,409.503906 C221.514296,411.539466 207.644566,415.421316 190.765438,421.149456 L189.769077,421.488535 C187.155646,422.380409 185.760049,425.222021 186.651924,427.835451 C186.986412,428.815591 187.617,429.667681 188.456586,430.274024 C196.133707,435.81838 205.098699,441.085111 215.351563,446.074219 C229.089841,452.759351 253.057288,462.798413 287.253906,476.191406 L306,458.412109 L311.273438,417.871094 C313.468161,414.324284 313.468161,411.535221 311.273438,409.503906 C309.078714,407.472591 303.762308,405.341081 295.324219,403.109375 Z" id="Combined-Shape" strokeWidth="4"></path>
                                                <polygon fill="darkgray" id="Path-6" strokeWidth="3" points="233.374627 1669.82031 202.191406 1721.62891 181.644531 1729.375 181.644531 1757.50391 361.367187 1757.50391 361.367187 1744.12891 233.374627 1744.12891 247.25 1713.66211"></polygon>
                                                <polygon fill="darkgray" id="Path-6" strokeWidth="3" transform="translate(482.874627, 1710.587891) scale(-1, 1) translate(-482.874627, -1710.587891) " points="447.374627 1663.67188 410.928941 1721.62891 390.382066 1729.375 390.382066 1757.50391 575.367187 1757.50391 575.367187 1744.12891 447.374627 1744.12891 466.237288 1713.66211"></polygon>
                                                <rect fill={damage.tyreDamage[2].toFixed(2) < 25 ? 'green'
                                                    : damage.tyreDamage[2].toFixed(2) < 50 ? 'yellow'
                                                        : damage.tyreDamage[2].toFixed(2) < 70 ? 'orange'
                                                            : 'red'} id="frontLeftTyre" strokeWidth="4" x="2" y="330.863281" width="147" height="197" rx="35"></rect>
                                                <rect fill={damage.tyreDamage[0].toFixed(2) < 25 ? 'green'
                                                    : damage.tyreDamage[0].toFixed(2) < 50 ? 'yellow'
                                                        : damage.tyreDamage[0].toFixed(2) < 70 ? 'orange'
                                                            : 'red'} id="rearLeftTyre" strokeWidth="4" x="10" y="1644.86328" width="170" height="197" rx="35"></rect>
                                                <rect fill={damage.tyreDamage[1].toFixed(2) < 25 ? 'green'
                                                    : damage.tyreDamage[1].toFixed(2) < 50 ? 'yellow'
                                                        : damage.tyreDamage[1].toFixed(2) < 70 ? 'orange'
                                                            : 'red'} id="rearRightTyre" strokeWidth="4" x="577" y="1644.86328" width="170" height="197" rx="35"></rect>
                                                <rect fill={damage.tyreDamage[3].toFixed(2) < 25 ? 'green'
                                                    : damage.tyreDamage[3].toFixed(2) < 50 ? 'yellow'
                                                        : damage.tyreDamage[3].toFixed(2) < 70 ? 'orange'
                                                            : 'red'} id="frontRightTyre" strokeWidth="4" x="603" y="330.863281" width="147" height="197" rx="35"></rect>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    )
}
