import React from 'react';

export default function DriverFinshes({ driverData }) {
    console.log('driverData', driverData);
    function returnBackground(number){
        let background = number === 1 ? 'gold' : number === 2 ? 'silver' : number === 3 ? 'bronze' : 'white';
        return background;
    }
    return (
        <>
            {
                <div className='finishesContainer'>
                    <div style={{"fontWeight": "700"}}>{driverData['driver_name']}</div>
                    <div className='finish' style={{background: returnBackground(driverData['round_8'])}}>{driverData['round_8']}</div>
                    <div className='finish' style={{background: returnBackground(driverData['round_9'])}}>{driverData['round_9']}</div>
                    <div className='finish' style={{background: returnBackground(driverData['round_10'])}}>{driverData['round_10']}</div>
                    <div className='finish' style={{background: returnBackground(driverData['round_11'])}}>{driverData['round_11']}</div>
                    <div className='finish' style={{background: returnBackground(driverData['round_12'])}}>{driverData['round_12']}</div>
                </div>
            }
        </>
    )
}
