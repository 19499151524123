import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

const Signup = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault()

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);
                navigate("/login")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });


    }

    return (
        <main >
            <section>
                <div>
                    <div>
                        <h1 style={{"textAlign": "center"}}> Signup </h1>
                        <form className="login">
                            <div className="login__container">
                                <input
                                    type="email"
                                    label="Email address"
                                    value={email}
                                    className="login__textBox"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    placeholder="Email address"
                                />
                                <input
                                    type="password"
                                    label="Create password"
                                    value={password}
                                    className="login__textBox"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    placeholder="Password"
                                />
                            </div>

                            <div className="buttonTextHolder">
                                <button
                                    type="submit"
                                    className="login__btn"
                                    onClick={onSubmit}
                                >
                                    Sign up
                                </button>
                                <p>
                                    Already have an account?{' '}
                                    <NavLink to="/login" >
                                        Sign in
                                    </NavLink>
                                </p>
                            </div>

                        </form>

                    </div>
                </div>
            </section>
        </main>
    )
}

export default Signup