import React from 'react'
import LiveDriver from './LiveDriver'
import { v4 as uuidv4 } from 'uuid';

export default function LiveTable({ drivers }) {
    return (
        <>
            {drivers.length > 0 && drivers.map((driver, index) => {
                return <LiveDriver key={uuidv4()} driver={driver} position={index + 1} />
            })}
        </>
    )
}
