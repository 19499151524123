import React from 'react';




export default function StrikeDistance({ battleData }) {

    console.log(battleData)

    function getDelta(battleData) {
        let deltaDiff;
        if(battleData.battle[0].currentPosition === battleData.battle[1].currentPosition + 1){
            deltaDiff = (battleData.battle[0].deltaAhead / 1000).toFixed(3);
        } else {
            deltaDiff= ((battleData.battle[0].deltaRaceLead - battleData.battle[1].deltaRaceLead) / 1000).toFixed(3);
        }
        return deltaDiff + "S";
    }
    const delta = getDelta(battleData);
    return (
        <>
            {
                <div className='battleContainer'>
                    <div className='top'>
                        <div>Battle Forecast</div>
                        <div>Powered by Race League Manager</div>
                    </div>
                    <div className='middle'>
                        <div className='chaser'>
                            <h4>{battleData.battle[0].driver}</h4>
                            <h4>{battleData.battle[0].cornerCuttingWarnings} Warnings</h4>
                            {/* <h4>Mercedes</h4> */}
                        </div>
                        {battleData.battleCalcs.lapPrediction > -1 && !battleData.battleCalcs.inStrikerRange && battleData.battleCalcs.closingPace ? <div className='distance'>
                            <h3>STRIKING DISTANCE</h3>
                            <span>IN <strong>{battleData.battleCalcs.lapPrediction}</strong> LAPS</span>
                        </div>
                            : battleData.battleCalcs.inStrikerRange ? <div className='distance'>
                                <h3>STRIKING DISTANCE</h3>
                                <span>DRIVER IN RANGE</span>
                            </div>
                                : <div className='distance'>
                                    <h3>STRIKING DISTANCE</h3>
                                    <span>DRIVER NOT CLOSING</span>
                                </div>
                        }
                        <div className='target'>
                            <h4>{battleData.battle[1].driver}</h4>
                            <h4>{battleData.battle[1].cornerCuttingWarnings} Warnings</h4>
                            {/* <h4>Haas</h4> */}
                        </div>
                    </div>
                    <div className='bottom'>
                        <div className='chaserPositionTarget'>
                            <h4>Chasing P:{battleData.battleCalcs.positionChased}</h4>
                        </div>
                        {battleData.battleCalcs.lapPrediction > -1 || battleData.battleCalcs.inStrikerRange ? <div className='distance'>
                            <div className='difficulty'><div className='difficultyCover' style={{ "width": battleData.battleCalcs.difficulty }}></div></div>
                            <h6>OVERTAKE DIFFICULTY</h6></div>
                            : <div className='distance'><div className='difficulty'><div className='difficultyCover' style={{ "width": "0%" }}></div></div>
                                <h6>OVERTAKE DIFFICULTY</h6>
                            </div>
                        }
                        {battleData.battleCalcs.driverPassed ?
                            <div className='targetLead'>
                                <h4>Driver has been Passed</h4>
                            </div>
                            : <div className='targetLead'>
                                <h4>{delta} AHEAD</h4>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}
