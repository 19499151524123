import React from 'react';
// import { v4 as uuidv4 } from 'uuid';
import alpine from "../assets/logos/alpine.png";
import alphaTauri from "../assets/logos/alphaTauri.png";
import alfaRomeo from "../assets/logos/alfaRomeo.png";
import astonMartin from "../assets/logos/astonMartin.png";
import ferrari from "../assets/logos/ferrari.png";
import haas from "../assets/logos/haas.png";
import mclaren from "../assets/logos/mclaren.png";
import mercedes from "../assets/logos/mercedes.png";
import redbull from "../assets/logos/redbull.png";
import williams from "../assets/logos/williams.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



export default function LiveDriver({ driver, position }) {
  // console.log('driver', driver);

  let customClass = driver.champion === true ? 'basic driverLine champion' : 'basic driverLine';
  let pointsChange = driver.livePoints - driver.points;
  let change = driver.startingPosition - position;
  // let changeSymbol = 
  let positionDesc = '';
  switch (position) {
    case 1:
      positionDesc = 'st'
      break;
    case 2:
      positionDesc = 'nd'
      break;
    case 3:
      positionDesc = 'rd'
      break;
    default:
      positionDesc = 'th'
  }

  let imageSrc = ''
  switch (driver.constructor) {
    case 'alpine':
      imageSrc = alpine
      break;
    case 'alphaTauri':
      imageSrc = alphaTauri
      break;
    case 'alfaRomeo':
      imageSrc = alfaRomeo
      break;
    case 'astonMartin':
      imageSrc = astonMartin
      break;
    case 'ferrari':
      imageSrc = ferrari
      break;
    case 'haas':
      imageSrc = haas
      break;
    case 'mclaren':
      imageSrc = mclaren
      break;
    case 'mercedes':
      imageSrc = mercedes
      break;
    case 'redbull':
      imageSrc = redbull
      break;
    case 'williams':
      imageSrc = williams
      break;
    default:
      positionDesc = ''
  }

  const newPointsClass = driver.fastestLap && driver.polePosition ? 'pole-fast new-points' :
  driver.fastestLap && !driver.polePosition ? 'fast new-points' :
  !driver.fastestLap && driver.polePosition ? 'pole new-points' :
  'new-points';

  let attendingClass = driver.driverMatch ? 'green attend' : 'red attend'
  if(driver.name === driver.constructor){
    attendingClass = 'attend'
  }
  return (
    <>
      {
        <div className={customClass}>
          <div className={'position'}>
            <div className={attendingClass}></div>
            <span><strong>{position + positionDesc}</strong></span>
          </div>
          <div className={`driver-team ${driver.constructor}`}>
            <span>{driver.name}</span>
            <img className={'constructorImg'} src={imageSrc} alt="logo" />
          </div>
          <div className={'points-change'}>
            <span className={'points'}>{driver.livePoints}</span>
            <span style={{ 'marginLeft':'5px', 'fontSize':'15px','color': change > 0 ? 'green' : change < 0 ? 'red' : 'black' }}>
              {
                change > 0 ?
                <FontAwesomeIcon icon='chevron-up' /> :
                change < 0 ?
                <FontAwesomeIcon icon='chevron-down' />
                : ''
              }
              </span>
              <span style={{ 'marginLeft':'5px', 'fontSize':'10px','color': 'black' }}>
              {
                change > 0 ?
                '+ ' + change :
                change < 0 ?
                ' ' + change
                : ''
              }
              </span>
          </div>
          <div className={newPointsClass}>
              <span>
                {
                pointsChange > 0 ?
                '+ ' + pointsChange :
                ' ' + pointsChange
              }
              </span>
          </div>
        </div>
      }
    </>
  )
}
