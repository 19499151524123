import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



export default function WeatherForecast({ forecast }) {
    console.log('forecast data: ', forecast)
    return (
        <>
            {
                <div className='forecastGrid'>
                    {forecast.map((weather, index) => {
                        return <div className={'weatherBlock'} key={index}>
                            <h2>{weather.sessionType}</h2>
                            <p>+ {weather.timeOffset} Minutes</p>
                            <p style={{ "textTransform": "capitalize" }}>{weather.weather}</p>
                            {
                                {
                                    'clear': <FontAwesomeIcon icon='sun' />,
                                    'light cloud': <FontAwesomeIcon icon='cloud-sun' />,
                                    'overcast': <FontAwesomeIcon icon='cloud' />,
                                    'light rain': <FontAwesomeIcon icon='cloud-rain' />,
                                    'heavy rain': <FontAwesomeIcon icon='cloud-heavy-showers' />,
                                    'storm': <FontAwesomeIcon icon='cloud-bolt' />,
                                }[weather.weather]
                            }
                            <p>Rain -  {weather.rainPercentage} %</p>

                        </div>
                    })}
                </div>
            }
        </>
    )
}