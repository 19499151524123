import React from 'react';




export default function DriverStatPack({ driverData }) {
    console.log('driverData', driverData);
    function returnBackground(number){
        let background = number === 1 ? 'gold' : number === 2 ? 'silver' : number === 3 ? 'bronze' : 'white';
        return background;
    }
    return (
        <>
            {
                <div className='statContainer'>
                    <div className='finish'>Starts: <strong>{driverData['starts']}</strong></div>
                    <div className='finish'>Wins: <strong>{driverData['wins']} - {driverData['win_percentage']}</strong></div>
                    <div className='finish'>Podiums: <strong>{driverData['podiums']} - {driverData['podium_percentage']}</strong></div>
                    <div className='finish'>Pole positions: <strong>{driverData['pole']} - {driverData['pole_percentage']}</strong></div>
                    <div className='finish'>Fastest Laps: <strong>{driverData['fastest_lap']}</strong></div>
                    <div className='finish'>Top 5 Finish: <strong>{driverData['top_five']} - {driverData['top_five_percentage']}</strong></div>
                    <div className='finish'>Best Qualifying: <strong>{driverData['best_quali']}</strong></div>
                    <div className='finish'>Best Finish: <strong>{driverData['best_finish']}</strong></div>
                    <div className='finish'>Avg Quali: <strong>{driverData['avg_start']}</strong></div>
                    <div className='finish'>Avg Finish: <strong>{driverData['avg_finish']}</strong></div>
                    <div className='finish'>Avg Points: <strong>{driverData['points_per_race']}</strong></div>
                    <div className='finish'>Penalty Points: <strong>{driverData['penalty_points']}</strong></div>
                    <div className='finish'>DNF's: <strong>{driverData['dnf']} - {driverData['dnf_percentage']}</strong></div>
                </div>
            }
        </>
    )
}
