import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from '../firebase';
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import '../App.css';
import rpmLogoGold from "../assets/logos/rpm_gold.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import _ from "lodash";
import Select from 'react-select';
import StrikeDistance from '../Components/StrikeDistance';
import LapCompare from '../Components/LapCompare';
import axios from 'axios';
import { Centrifuge } from 'centrifuge';
import PitPrediction from '../Components/PitPrediction';


const SessionData = () => {
    // set up constants and states
    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [user, loading] = useAuthState(auth);
    const [selectLeague, setSelectLeague] = useState(process.env.REACT_APP_LEAGUE);
    const [selectDivision, setSelectDivision] = useState("Not Set");
    const [weatherForecast, setWeatherForecast] = useState({})
    const [driverSelectOne, setDriverSelectOne] = useState('Not Set')
    const [driverSelectTwo, setDriverSelectTwo] = useState('Not Set')
    const [lapPrediction, setLapPrediction] = useState('-')
    const [delta, setDelta] = useState('-')
    const [positionChased, setPositionChased] = useState('-')
    const [passDifficulty, setPassDifficulty] = useState('100%')
    const [driverOptionsOffline, setDriverOptionsOffline] = useState([
        {
            label: "Hamilton",
            value: "hamilton"
        },
        {
            label: "Bottas",
            value: "bottas"
        },
        {
            label: "Sainz",
            value: "sainz"
        },
        {
            label: "Stroll",
            value: "stroll"
        },
        {
            label: "Alonso",
            value: "alonso"
        },
        {
            label: "Ocon",
            value: "ocon"
        },
        {
            label: "Zhou",
            value: "zhou"
        },
        {
            label: "Gasly",
            value: "gasly"
        },
        {
            label: "Tsunoda",
            value: "tsunoda"
        },
        {
            label: "Vettel",
            value: "vettel"
        },
        {
            label: "Magnussen",
            value: "magnussen"
        },
        {
            label: "Schumacher",
            value: "schumacher"
        },
        {
            label: "Leclerc",
            value: "leclerc"
        },
        {
            label: "Ricciardo",
            value: "ricciardo"
        },
        {
            label: "Norris",
            value: "norris"
        },
        {
            label: "Russel",
            value: "russel"
        },
        {
            label: "Verstappen",
            value: "verstappen"
        },
        {
            label: "PÉREZ",
            value: "PÉREZ"
        },
        {
            label: "Albon",
            value: "albon"
        },
        {
            label: "Latifi",
            value: "latifi"
        }
    ])
    const [driverOptionsFirstSeason6, setDriverOptionsFirstSeason6] = useState([
        {
            label: "matty347",
            value: "matty347",
        },
        {
            label: "N4T_P4CK3R5",
            value: "N4T_P4CK3R5",
        },
        {
            label: "Mocky1480",
            value: "Mocky1480",
        },
        {
            label: "smit1717",
            value: "smit1717",
        },
        {
            label: "RPM_Danos",
            value: "RPM_Danos",
        },
        {
            label: "RPM_jones58",
            value: "RPM_jones58",
        },
        {
            label: "RTRodo",
            value: "RTRodo",
        },
        {
            label: "Spivey-CJ",
            value: "Spivey-CJ",
        },
        {
            label: "JLeeky7",
            value: "JLeeky7",
        },
        {
            label: "F1-Dino-F1",
            value: "F1-Dino-F1",
        },
        {
            label: "Hasbullah-19",
            value: "Hasbullah-19",
        },
        {
            label: "rycka9",
            value: "rycka9",
        },
        {
            label: "Inorganic",
            value: "Inorganic",
        },
        {
            label: "Reuze_Beertje",
            value: "Reuze_Beertje",
        },
        {
            label: "Goee14",
            value: "Goee14",
        },
        {
            label: "RPM_ijver",
            value: "RPM_ijver",
        },
        {
            label: "themightyfrax",
            value: "themightyfrax",
        },
        {
            label: "Philmcconachie",
            value: "Philmcconachie",
        },
        {
            label: "Callum0064",
            value: "Callum0064",
        },
        {
            label: "snellehenk73",
            value: "snellehenk73",
        },
        {
            label: "No Driver",
            value: "No Driver",
        },
    ])
    const [driverOptionsFirst, setDriverOptionsFirst] = useState([
        {
            label: "BOBSPUN",
            value: "BOBSPUN",
        },
        {
            label: "RPM_Inorganic",
            value: "RPM_Inorganic",
        },
        {
            label: "Mocky1480",
            value: "Mocky1480",
        },
        {
            label: "RPM_Jones58",
            value: "RPM_Jones58",
        },
        {
            label: "RPM_Danos",
            value: "RPM_Danos",
        },
        {
            label: "UnluckyFC__",
            value: "UnluckyFC__",
        },
        {
            label: "LisbonAELion67",
            value: "LisbonAELion67",
        },
        {
            label: "Spivey-CJ",
            value: "Spivey-CJ",
        },
        {
            label: "DSBAKER-58",
            value: "DSBAKER-58",
        },
        {
            label: "Kieron_994",
            value: "Kieron_994",
        },
        {
            label: "Faulkiller",
            value: "Faulkiller",
        },
        {
            label: "OST_Jeweller",
            value: "OST_Jeweller",
        },
        {
            label: "MikelAuf",
            value: "MikelAuf",
        },
        {
            label: "Reuze_Beertje",
            value: "Reuze_Beertje",
        },
        {
            label: "Shaunfuss",
            value: "Shaunfuss",
        },
        {
            label: "RPM_IJVER",
            value: "RPM_IJVER",
        },
        {
            label: "TheChaosDruid",
            value: "TheChaosDruid",
        },
        {
            label: "i_AmTron",
            value: "i_AmTron",
        },
        {
            label: "ScooterClem10",
            value: "ScooterClem10",
        },
        {
            label: "snellehenk73",
            value: "snellehenk73",
        }
    ])
    const [driverOptionsChampSeason6, setDriverOptionsChampSeason6] = useState([
        {
            label: "Benpaines_",
            value: "Benpaines_",
        },
        {
            label: "RPM_Zabijaka420",
            value: "RPM_Zabijaka420",
        },
        {
            label: "BigPuggy1987",
            value: "BigPuggy1987",
        },
        {
            label: "booty1973",
            value: "booty1973",
        },
        {
            label: "lViatt",
            value: "lViatt",
        },
        {
            label: "RPM_Stubbe",
            value: "RPM_Stubbe",
        },
        {
            label: "Wardy-R6",
            value: "Wardy-R6",
        },
        {
            label: "Kieran0064",
            value: "Kieran0064",
        },
        {
            label: "Jetfish11",
            value: "Jetfish11",
        },
        {
            label: "AL2737",
            value: "AL2737",
        },
        {
            label: "Stebo__",
            value: "Stebo__",
        },
        {
            label: "RustyOilcan",
            value: "RustyOilcan",
        },
        {
            label: "lost_glaedr",
            value: "lost_glaedr",
        },
        {
            label: "DZ_Karting",
            value: "DZ_Karting",
        },
        {
            label: "RPM_BAKKER1194",
            value: "RPM_BAKKER1194",
        }
    ])
    const [driverOptionsChamp, setDriverOptionsChamp] = useState([
        {
            "label": "matty347",
            "value": "matty347"
        },
        {
            "label": "N4T_P4CK3R5",
            "value": "N4T_P4CK3R5"
        },
        {
            "label": "smit1717",
            "value": "smit1717"
        },
        {
            "label": "GiraffeBHA",
            "value": "GiraffeBHA"
        },
        {
            "label": "RPM_Stubbe",
            "value": "RPM_Stubbe"
        },
        {
            "label": "RPM_Entity123",
            "value": "RPM_Entity123"
        },
        {
            "label": "RPM_Bakker1194",
            "value": "RPM_Bakker1194"
        },
        {
            "label": "Laur1609",
            "value": "Laur1609"
        },
        {
            "label": "Explicitsimmo23",
            "value": "Explicitsimmo23"
        },
        {
            "label": "Kieran0064",
            "value": "Kieran0064"
        },
        {
            "label": "RPM_husssrc",
            "value": "RPM_husssrc"
        },
        {
            "label": "RTRodo",
            "value": "RTRodo"
        },
        {
            "label": "Jleeky7",
            "value": "Jleeky7"
        },
        {
            "label": "Rycka9",
            "value": "Rycka9"
        },
        {
            "label": "lost_glaedr",
            "value": "lost_glaedr"
        },
        {
            "label": "booty1973",
            "value": "booty1973"
        },
        {
            "label": "RustyOilcan",
            "value": "RustyOilcan"
        },
        {
            "label": "XdOscarvdg",
            "value": "XdOscarvdg"
        },
        {
            "label": "Troy08Blue",
            "value": "Troy08Blue"
        },
        {
            "label": "No Driver",
            "value": "No Driver"
        }
    ])
    const [driverOptionsPremierSeason6, setDriverOptionsPremierSeason6] = useState([
        {
            label: "RPM_Colin-86",
            value: "RPM_Colin-86",
        },
        {
            label: "RPM_Sinfull",
            value: "RPM_Sinfull",
        },
        {
            label: "Sminotla789",
            value: "Sminotla789",
        },
        {
            label: "RVL_Volcano",
            value: "RVL_Volcano",
        },
        {
            label: "RPM_Glassy",
            value: "RPM_Glassy",
        },
        {
            label: "RPM_Higgzy",
            value: "RPM_Higgzy",
        },
        {
            label: "RPM_Luukvdm",
            value: "RPM_Luukvdm",
        },
        {
            label: "Andrewx2905",
            value: "Andrewx2905",
        },
        {
            label: "RPM_KingPinquinn",
            value: "RPM_KingPinquinn",
        },
        {
            label: "CPI_Mango",
            value: "CPI_Mango",
        },
        {
            label: "ET8_Evil",
            value: "ET8_Evil",
        },
        {
            label: "RPM_Entity123",
            value: "RPM_Entity123",
        },
        {
            label: "GiraffeBha",
            value: "GiraffeBha",
        },
        {
            label: "C_osmo90",
            value: "C_osmo90",
        },
        {
            label: "maggot361975",
            value: "maggot361975",
        },
        {
            label: "RPM_Dragos",
            value: "RPM_Dragos",
        },
        {
            label: "RPM_husssrc",
            value: "RPM_husssrc",
        },
        {
            label: "davie_w",
            value: "davie_w",
        },
        {
            label: "Juul_Warrior_",
            value: "Juul_Warrior_",
        },
        {
            label: "ABV",
            value: "ABV",
        }
    ])
    const [driverOptionsPremier, setDriverOptionsPremier] = useState([
        {
            "label": "Banter_Branden_0",
            "value": "Banter_Branden_0",
        },
        {
            "label": "LearnerDriver86",
            "value": "LearnerDriver86",
        },
        {
            "label": "AL2737",
            "value": "AL2737",
        },
        {
            "label": "Jetfish11",
            "value": "Jetfish11",
        },
        {
            "label": "RVL_Volcano",
            "value": "RVL_Volcano",
        },
        {
            "label": "RPM_Higgzy",
            "value": "RPM_Higgzy",
        },
        {
            "label": "Davie_w",
            "value": "Davie_w",
        },
        {
            "label": "Stebo__",
            "value": "Stebo__",
        },
        {
            "label": "Sminotla789",
            "value": "Sminotla789",
        },
        {
            "label": "Maggot361975",
            "value": "Maggot361975",
        },
        {
            "label": "RPM_Dragos",
            "value": "RPM_Dragos",
        },
        {
            "label": "RPM_Kvkfc",
            "value": "RPM_Kvkfc",
        },
        {
            "label": "RPM_KingPinquinn",
            "value": "RPM_KingPinquinn",
        },
        {
            "label": "RPM_Yeast",
            "value": "RPM_Yeast",
        },
        {
            "label": "RPM_Luukvdm",
            "value": "RPM_Luukvdm",
        },
        {
            "label": "ET8_Evil",
            "value": "ET8_Evil",
        },
        {
            "label": "Zykotikz",
            "value": "Zykotikz",
        },
        {
            "label": "No Driver",
            "value": "No Driver",
        },
        {
            "label": "No Driver",
            "value": "No Driver",
        },
        {
            "label": "No Driver",
            "value": "No Driver",
        }
    ])

    const [driverOptions, setDriverOptions] = useState([])
    const [rebindAbly, setRebindAbly] = useState({})
    const [battleData, setBattleData] = useState({
        "battleCalcs": {
            "delta": "0",
            "closingSpeed": "0",
            "lapPrediction": 0,
            "positionChased": 0,
            "difficulty": "0",
            "approximate": false,
            "base": 0,
            "driverPassed": false,
            "inStrikerRange": false,
            "closingPace": false
        },
        "battle": [
            {
                "driver": "Driver Behind",
                "currentLap": 0,
                "currentLapTime": 0,
                "lastLapTime": 0,
                "totalRaceTimeDistanceMatch": 0,
                "distanceMatch": 0,
                "currentPosition": 0,
                "lapData": {
                    "laps": [
                        {
                            "m_lapTimeInMS": 0,
                            "m_sector1TimeInMS": 0,
                            "m_sector2TimeInMS": 0,
                            "m_sector3TimeInMS": 0,
                            "m_lapValidBitFlags": 0
                        },
                        {
                            "m_lapTimeInMS": 0,
                            "m_sector1TimeInMS": 0,
                            "m_sector2TimeInMS": 0,
                            "m_sector3TimeInMS": 0,
                            "m_lapValidBitFlags": 0
                        }
                    ]
                },
                "currentERS": 0,
                "currentTyreWear": {
                    "m_tyresWear": [
                        0,
                        0,
                        0,
                        0
                    ],
                    "m_tyresDamage": [
                        0,
                        0,
                        0,
                        0
                    ],
                    "m_brakesDamage": [
                        0,
                        0,
                        0,
                        0
                    ],
                    "m_frontLeftWingDamage": 0,
                    "m_frontRightWingDamage": 0,
                    "m_rearWingDamage": 0,
                    "m_floorDamage": 0,
                    "m_diffuserDamage": 0,
                    "m_sidepodDamage": 0,
                    "m_drsFault": 0,
                    "m_ersFault": 0,
                    "m_gearBoxDamage": 0,
                    "m_engineDamage": 0,
                    "m_engineMGUHWear": 0,
                    "m_engineESWear": 0,
                    "m_engineCEWear": 0,
                    "m_engineICEWear": 0,
                    "m_engineMGUKWear": 0,
                    "m_engineTCWear": 0,
                    "m_engineBlown": 0,
                    "m_engineSeized": 0
                },
                "tyreCompound": {
                    "compound": "C4",
                    "value": 0
                }
            },
            {
                "driver": "Driver Ahead",
                "currentLap": 0,
                "currentLapTime": 0,
                "lastLapTime": 0,
                "totalRaceTimeDistanceMatch": 0,
                "timeData": {
                    "leadDriverTimeStamp": 0,
                    "leadDriverTimeArray": [
                        {
                            "totalDistance": 0,
                            "lapDistance": 0,
                            "lapTime": 0,
                            "totalTime": 0
                        },
                        {
                            "totalDistance": 0,
                            "lapDistance": 0,
                            "lapTime": 0,
                            "totalTime": 0
                        }
                    ]
                },
                "currentPosition": 0,
                "lapData": {
                    "laps": [
                        {
                            "m_lapTimeInMS": 0,
                            "m_sector1TimeInMS": 0,
                            "m_sector2TimeInMS": 0,
                            "m_sector3TimeInMS": 0,
                            "m_lapValidBitFlags": 0
                        },
                        {
                            "m_lapTimeInMS": 0,
                            "m_sector1TimeInMS": 0,
                            "m_sector2TimeInMS": 0,
                            "m_sector3TimeInMS": 0,
                            "m_lapValidBitFlags": 0
                        }
                    ]
                },
                "currentERS": 0,
                "currentTyreWear": {
                    "m_tyresWear": [
                        0,
                        0,
                        0,
                        0
                    ],
                    "m_tyresDamage": [
                        0,
                        0,
                        0,
                        0
                    ],
                    "m_brakesDamage": [
                        0,
                        0,
                        0,
                        0
                    ],
                    "m_frontLeftWingDamage": 0,
                    "m_frontRightWingDamage": 0,
                    "m_rearWingDamage": 0,
                    "m_floorDamage": 0,
                    "m_diffuserDamage": 0,
                    "m_sidepodDamage": 0,
                    "m_drsFault": 0,
                    "m_ersFault": 0,
                    "m_gearBoxDamage": 0,
                    "m_engineDamage": 0,
                    "m_engineMGUHWear": 0,
                    "m_engineESWear": 0,
                    "m_engineCEWear": 0,
                    "m_engineICEWear": 0,
                    "m_engineMGUKWear": 0,
                    "m_engineTCWear": 0,
                    "m_engineBlown": 0,
                    "m_engineSeized": 0
                },
                "tyreCompound": {
                    "compound": "C4",
                    "value": 0
                }
            }
        ]
    })
    const [centrifugoJWT, setCentrifugoJWT] = useState("");







    const newDataLoaded = () => toast("The current tables have been updated.");

    // useEffects for redirecting non-logged in users and blocking homepage from showing during check
    useEffect(() => {
        if (loading) {
            console.log('loading', loading);
            console.log('user', user)
            return;
        }
        if (user === null) {
            console.log('should redirect?')
            navigate("/login")
        };
    }, [user, loading]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                const email = user.email;
                // ...
                console.log("uid", uid, "email", email)
            } else {
                // User is signed out
                // ...
                console.log("user is logged out")
            }
        });

    }, [])

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    useEffect(() => {
        if (user) {
            // get the JWT token from the server
            console.log('try for JWT');
            const url = process.env.REACT_APP_JWT_ENDPOINT;
            const payload = {
                userID: user.uid,
            };

            try {
                axios.post(url, payload).then((response) => {
                    console.log(response.data.token)
                    setCentrifugoJWT(response.data.token);
                });
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.log('no user yet')
        }

    }, [user])

    useEffect(() => {

        console.log('start the CENTRIFUGO use effect');
        let subBattle;
        let subDelta;
        let centrifuge
        if (centrifugoJWT !== "") {
            toast.success(`Established Connection.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            centrifuge = new Centrifuge("wss://messaging.purplekraken.dev/centrifugo/connection/websocket", {
                token: centrifugoJWT,
            });
            centrifuge.on('connecting', function (ctx) {
                console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
            }).on('connected', function (ctx) {
                console.log(`connected over ${ctx.transport}`);
            }).on('disconnected', function (ctx) {
                console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
            }).connect();

            console.log(rebindAbly)

            if(rebindAbly.drivers !== undefined && rebindAbly.drivers.length === 2){
                centrifuge.publish("rlmTelemteryService:driverSelect", { sessionKey: `${selectLeague}-${selectDivision}`, drivers: rebindAbly.drivers }).then(function (res) {
                    console.log('successfully published drivers selected', rebindAbly.drivers.length);
                }, function (err) {
                    console.log('publish error', err);
                });
            }


            subBattle = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:battle`);
            subDelta = centrifuge.newSubscription(`${selectLeague}-${selectDivision}:delta`);

            subBattle.on('publication', function (ctx) {
                console.log('-------- New Battle Data Recieved -----')
                const battle = ctx.data;
                console.log(battle)
                if (battle.length === 2 && battle[0].lapData.laps[1] !== null && battle[1].lapData.laps[1] !== null) {
                    let battleCalcs = difficultyCalc(track, battle[0], battle[1])
                    setBattleData({
                        battleCalcs,
                        battle
                    })
                }
            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();

            subDelta.on('publication', function (ctx) {
                console.log('-------- New Delta Data Recieved -----')
                const delta = ctx.data;
                console.log(delta)
            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();

        } else {
            console.log('Could not generate a secure JWT');
            toast.error(`Could not generate a secure JWT.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }

        // run the cleanup to unsubscribe from pusher and unbind the events
        return () => {
            console.log('clean up');

            if (subBattle) {
                subBattle.unsubscribe();
                subBattle.removeAllListeners();
            }

            if (subDelta) {
                subDelta.unsubscribe();
                subDelta.removeAllListeners();
            }

            if (centrifuge) {
                centrifuge.disconnect();
            }
        };

    }, [rebindAbly, centrifugoJWT]);

    const handleDriverSelectOne = (selectedOption) => {
        // if(selectedOption)
        setDriverSelectOne(selectedOption.label);
        console.log(`Driver Option selected:`, selectedOption.label);
    };

    const handleDriverSelectTwo = (selectedOption) => {
        // if(selectedOption)
        setDriverSelectTwo(selectedOption.label);
        console.log(`Driver Option selected:`, selectedOption.label);
    };

    function refreshPusher(league) {
        console.log("refresh select league")
        if (league === "premier") {
            console.log(driverOptionsPremier)
            setDriverOptions(driverOptionsPremier)
        }
        if (league === "championship") {
            setDriverOptions(driverOptionsChamp)
        }
        if (league === "first") {
            setDriverOptions(driverOptionsFirst)
        }
        if (league === "offline") {
            setDriverOptions(driverOptionsOffline)
        }
        setSelectDivision(league);
    }

    const track = {
        name: 'silverstone',
        baseDifficulty: 0.6
    }

    function difficultyCalc(track, chaseDriver, leadDriver) {
        const base = track.baseDifficulty;
        let delta = chaseDriver.deltaAhead;
        let chaseDriverThreeLapAvg = (chaseDriver.lapData.laps[0].m_lapTimeInMS + chaseDriver.lapData.laps[1].m_lapTimeInMS) / 2
        let leadDriverThreeLapAvg = (leadDriver.lapData.laps[0].m_lapTimeInMS + leadDriver.lapData.laps[1].m_lapTimeInMS) / 2
        const closingSpeed = (leadDriverThreeLapAvg - chaseDriverThreeLapAvg) / 1000;
        const lapPrediction = Math.round(delta / closingSpeed)
        setLapPrediction(lapPrediction);
        console.log(delta);
        setDelta(delta);
        setPositionChased(leadDriver.currentPosition);

        const deltaDiff = ((closingSpeed) * 0.1).toFixed(2);
        const compoundDiff = (chaseDriver.tyreCompound.value - leadDriver.tyreCompound.value).toFixed(2);
        // const ersDiff = (((chaseDriver.currentERS - leadDriver.currentERS) * 100) * 0.002).toFixed(2)
        const tyreWearDiff = (calculateTyreWearDifference([chaseDriver, leadDriver]) * 0.002).toFixed(2)
        const difficultyCalc = (((1 - (track.baseDifficulty - deltaDiff - compoundDiff)) * 100).toFixed(2))
        const difficulty = difficultyCalc > 100 ? '100%' : difficultyCalc < 0 ? '0%' : difficultyCalc.toString() + '%'
        console.log('deltaDiff: ', deltaDiff)
        console.log('compoundDiff: ', compoundDiff)
        // console.log('ers: ', ersDiff)
        console.log('tyreWear: ', tyreWearDiff)
        console.log(difficulty)
        setPassDifficulty(difficulty);
        let inStrikerRange = delta < 1 ? true : false;
        let closingPace = closingSpeed > 0.2 ? true : false;
        console.log(inStrikerRange, closingPace)

        return {
            delta: delta,
            closingSpeed: closingSpeed.toFixed(3),
            lapPrediction: lapPrediction,
            positionChased: leadDriver.currentPosition,
            difficulty: difficulty,
            base: base,
            inStrikerRange: inStrikerRange,
            closingPace: closingPace
        }
    }

    function setDrivers() {
        let driverSelectOneValid = driverSelectOne !== 'Not Set' && driverSelectOne !== 'No Driver' ? true : false;
        let driverSelectTwoValid = driverSelectTwo !== 'Not Set' && driverSelectTwo !== 'No Driver' ? true : false;
        if(driverSelectOneValid && driverSelectTwoValid){
            setRebindAbly({ drivers: [driverSelectOne, driverSelectTwo] });
        } else {
            console.log('Please select two drivers.');
            toast.error(`Please select two drivers.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }

    // calculate the difference in tyre wear between the two drivers using an average of each drivers tyre wear m_tyrewear in the m_cur
    const calculateTyreWearDifference = (data) => {
        const drivers = data.map(driver => {
            const averageTyreWear = driver.currentTyreWear.m_tyresWear.reduce((acc, tyreWear) => {
                return acc + tyreWear
            }, 0) / driver.currentTyreWear.m_tyresWear.length
            return {
                ...driver,
                averageTyreWear
            }
        })
        const tyreWearDifference = drivers[0].averageTyreWear - drivers[1].averageTyreWear
        return tyreWearDifference
    }


    return (
        <>
            {loading !== true && user !== null &&
                <div>
                    <nav>
                        <img src={rpmLogoGold} style={{ "cursor": "pointer" }} onClick={() => { navigate("/") }} />
                        <div className='adminButtons headerButtons'>
                            <div>
                                <button onClick={() => { navigate("/live-standings") }}>
                                    Live Standings
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/session-data") }}>
                                    Session Data
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/battle") }}>
                                    Battle Data
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/stats") }}>
                                    Stats
                                </button>
                            </div>
                        </div>

                        <span style={{ 'color': 'white' }}>League ID - {process.env.REACT_APP_LEAGUE}</span>
                        <div>
                            <button onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    </nav>
                    {selectDivision === "Not Set" ? <div style={{ "textAlign": "center" }}><h2 style={{ "color": "white" }}>Select League for Data Binding</h2></div> : null}
                    <div className='adminButtons'>
                        <div>
                            <button style={{ "background": selectDivision === "premier" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("premier") }}>
                                Premier
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "championship" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("championship") }}>
                                Championship
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "first" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("first") }}>
                                First
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "practice" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("practice") }}>
                                Practice
                            </button>
                        </div>
                        <div>
                            <button style={{ "background": selectDivision === "offline" ? "red" : "#b7801a" }} onClick={() => { refreshPusher("offline") }}>
                                Offline
                            </button>
                        </div>
                    </div>
                    <div className="App">
                        <Tabs>
                            <TabList>
                                <Tab>Battle Predictor</Tab>
                            </TabList>
                            <TabPanel>
                                {/* <div>
                                    <button style={{ "background": selectDivision === "offline" ? "red" : "#b7801a" }} onClick={() => difficultyCalc(track, battleDataSmit, battleDataMatty)}>
                                        Calc Battle
                                    </button>
                                </div>
                                <div>
                                    <button style={{ "background": selectDivision === "offline" ? "red" : "#b7801a" }} onClick={() => retrieveTime(singleDelta, singleDelta)}>
                                        Calc Delta
                                    </button>
                                </div> */}
                                <div className='selectContainers'>
                                    <div className='driverSelect'>
                                        <h2 style={{ "color": "white" }}>Select Driver Chasing</h2>
                                        {<Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // isMulti
                                            isDisabled={isDisabled}
                                            isLoading={isLoading}
                                            name="driverList"
                                            id='driverList'
                                            options={driverOptions}
                                            onChange={handleDriverSelectOne} />}
                                    </div>
                                    <button style={{width: "fit-content", margin: "0 2rem"}} onClick={() => { setDrivers() }}>
                                        Set selected drivers
                                    </button>
                                    <div className='driverSelect'>
                                        <h2 style={{ "color": "white" }}>Select Driver Leading</h2>
                                        {<Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // isMulti
                                            isDisabled={isDisabled}
                                            isLoading={isLoading}
                                            name="driverList"
                                            id='driverList'
                                            options={driverOptions}
                                            onChange={handleDriverSelectTwo} />}
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(battleData) && <StrikeDistance battleData={battleData}></StrikeDistance>
                                }
                                {
                                    !_.isEmpty(battleData) && <LapCompare battleData={battleData}></LapCompare>
                                }
                                {
                                    !_.isEmpty(battleData) && <PitPrediction battleData={battleData}></PitPrediction>
                                }
                            </TabPanel>
                        </Tabs>


                    </div>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
            }
        </>
    );
}

export default SessionData