import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from '../firebase';
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { doc, setDoc } from 'firebase/firestore';
import rpmLogo from "../assets/logos/rmplogo.jpeg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Centrifuge } from 'centrifuge';


const Admin = () => {
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const [rebindAbly, setRebindAbly] = useState({})
    const [selectLeague, setSelectLeague] = useState(process.env.REACT_APP_LEAGUE);
    const [selectDivision, setSelectDivision] = useState("premier");
    const [centrifugoJWT, setCentrifugoJWT] = useState("");

    let practiceStandings = [
        {
            "name": "RPM_GLASSY",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 1,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "smit1717",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 2,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "ET8_Evil",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 3,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Richie",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 4,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "al2737",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 5,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Sinfull",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 6,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "snellehenk",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 7,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "JLeeky7",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 8,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RustyOilCan",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 9,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Smythy",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Jones58",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 11,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Spivey_CJ",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 12,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Reuze_Beertje",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 13,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Goee14",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 14,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "themightyfrax",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 15,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Inorganic",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 16,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Cosmo",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 17,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Callum0064",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 18,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Ugglarp47",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 19,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "G3rlar1",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 20,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        }
    ];

    let firstStandingsBlank = [
        {
            "name": "matty347",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 1,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "goranpotnar",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 2,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "smit1717",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 3,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Danos",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 4,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_jones58",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 5,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RTRodo",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 6,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Spivey-CJ",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 7,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "JLeeky7",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 8,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "F1-Dino-F1",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 9,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Schnitzy91",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "rycka9",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 11,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Inorganic",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 12,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "BigPuggy1987",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 13,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Goee14",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 14,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_ijver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 15,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "themightyfrax",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 16,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Philmcconachie",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 17,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Callum0064",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 18,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "snellehenk73",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 19,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 20,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
    ];
    
    let firstStandingsSeason6 = [
        {
            "constructor": "williams",
            "points": 401,
            "name": "N4T_P4CK3R5",
            "positionChange": null,
            "polePosition": false,
            "fastestLap": false,
            "livePoints": 401,
            "champion": true,
            "startingPosition": 1,
            "driverMatch": false,
            "pole": false,
            "fastest": false
        },
        {
            "champion": false,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "livePoints": 314,
            "positionChange": null,
            "startingPosition": 2,
            "name": "smit1717",
            "fastestLap": false,
            "driverMatch": false,
            "polePosition": false,
            "points": 314
        },
        {
            "positionChange": null,
            "fastestLap": false,
            "polePosition": false,
            "name": "RTRodo",
            "livePoints": 280,
            "points": 280,
            "fastest": false,
            "pole": false,
            "champion": false,
            "startingPosition": 3,
            "driverMatch": false,
            "constructor": "alpine"
        },
        {
            "name": "matty347",
            "positionChange": null,
            "startingPosition": 4,
            "fastest": false,
            "polePosition": false,
            "driverMatch": false,
            "champion": false,
            "fastestLap": false,
            "points": 270,
            "livePoints": 270,
            "pole": false,
            "constructor": "haas"
        },
        {
            "fastest": false,
            "name": "rycka9",
            "startingPosition": 5,
            "positionChange": null,
            "constructor": "redbull",
            "polePosition": false,
            "livePoints": 252,
            "pole": false,
            "driverMatch": false,
            "points": 252,
            "champion": false,
            "fastestLap": false
        },
        {
            "startingPosition": 6,
            "name": "F1-Dino-F1",
            "champion": false,
            "fastestLap": false,
            "positionChange": null,
            "driverMatch": false,
            "livePoints": 233,
            "constructor": "alfaRomeo",
            "pole": false,
            "points": 233,
            "polePosition": false,
            "fastest": false
        },
        {
            "driverMatch": false,
            "positionChange": null,
            "constructor": "ferrari",
            "livePoints": 213,
            "fastestLap": false,
            "name": "RPM_Danos",
            "points": 213,
            "fastest": false,
            "startingPosition": 7,
            "champion": false,
            "polePosition": false,
            "pole": false
        },
        {
            "driverMatch": false,
            "livePoints": 184,
            "champion": false,
            "startingPosition": 8,
            "pole": false,
            "points": 184,
            "positionChange": null,
            "polePosition": false,
            "constructor": "mclaren",
            "fastest": false,
            "fastestLap": false,
            "name": "snellehenk73"
        },
        {
            "startingPosition": 9,
            "livePoints": 181,
            "champion": false,
            "fastestLap": false,
            "fastest": false,
            "constructor": "haas",
            "points": 181,
            "positionChange": null,
            "name": "JLeeky7",
            "polePosition": false,
            "driverMatch": false,
            "pole": false
        },
        {
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "positionChange": null,
            "champion": false,
            "points": 148,
            "startingPosition": 10,
            "polePosition": false,
            "fastestLap": false,
            "name": "RPM_jones58",
            "driverMatch": false,
            "livePoints": 148
        },
        {
            "points": 96,
            "pole": false,
            "polePosition": false,
            "positionChange": null,
            "champion": false,
            "startingPosition": 11,
            "name": "Spivey-CJ",
            "driverMatch": false,
            "fastest": false,
            "livePoints": 96,
            "constructor": "mercedes",
            "fastestLap": false
        },
        {
            "name": "Inorganic",
            "startingPosition": 12,
            "champion": false,
            "positionChange": null,
            "fastestLap": false,
            "points": 85,
            "fastest": false,
            "constructor": "alfaRomeo",
            "driverMatch": false,
            "polePosition": false,
            "livePoints": 85,
            "pole": false
        },
        {
            "fastestLap": false,
            "startingPosition": 13,
            "polePosition": false,
            "name": "Callum0064",
            "livePoints": 82,
            "pole": false,
            "driverMatch": false,
            "points": 82,
            "fastest": false,
            "champion": false,
            "positionChange": null,
            "constructor": "redbull"
        },
        {
            "fastestLap": false,
            "polePosition": false,
            "positionChange": null,
            "startingPosition": 14,
            "pole": false,
            "points": 47,
            "constructor": "ferrari",
            "fastest": false,
            "driverMatch": false,
            "livePoints": 47,
            "name": "RPM_ijver",
            "champion": false
        },
        {
            "driverMatch": false,
            "startingPosition": 15,
            "polePosition": false,
            "points": 42,
            "name": "themightyfrax",
            "champion": false,
            "fastest": false,
            "positionChange": null,
            "pole": false,
            "fastestLap": false,
            "livePoints": 42,
            "constructor": "williams"
        },
        {
            "fastest": false,
            "champion": false,
            "driverMatch": false,
            "positionChange": null,
            "pole": false,
            "points": 34,
            "livePoints": 34,
            "fastestLap": false,
            "polePosition": false,
            "name": "Reuze_Beertje",
            "startingPosition": 16,
            "constructor": "alphaTauri"
        },
        {
            "champion": false,
            "polePosition": false,
            "fastestLap": false,
            "name": "Scooterclem10",
            "pole": false,
            "positionChange": null,
            "livePoints": 28,
            "fastest": false,
            "points": 28,
            "driverMatch": false,
            "constructor": "mclaren",
            "startingPosition": 17
        },
        {
            "fastestLap": false,
            "positionChange": null,
            "pole": false,
            "fastest": false,
            "champion": false,
            "startingPosition": 18,
            "points": 19,
            "livePoints": 19,
            "driverMatch": false,
            "name": "Philmcconachie",
            "polePosition": false,
            "constructor": "alpine"
        },
        {
            "startingPosition": 19,
            "champion": false,
            "fastest": false,
            "points": 12,
            "constructor": "astonMartin",
            "name": "Mocky1480",
            "polePosition": false,
            "fastestLap": false,
            "livePoints": 12,
            "driverMatch": false,
            "positionChange": null,
            "pole": false
        },
        {
            "positionChange": null,
            "driverMatch": false,
            "startingPosition": 20,
            "pole": false,
            "name": "Goee14",
            "fastest": false,
            "fastestLap": false,
            "livePoints": 12,
            "champion": false,
            "constructor": "alphaTauri",
            "polePosition": false,
            "points": 12
        }
    ];

    let firstStandings = [
        {
            "name": "BOBSPUN",
            "points": 118,
            "livePoints": 118,
            "positionChange": null,
            "startingPosition": 1,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Jones58",
            "points": 75,
            "livePoints": 75,
            "positionChange": null,
            "startingPosition": 2,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },

        {
            "name": "RPM_Inorganic",
            "points": 72,
            "livePoints": 72,
            "positionChange": null,
            "startingPosition": 3,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "UnluckyFC__",
            "points": 69,
            "livePoints": 69,
            "positionChange": null,
            "startingPosition": 4,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "snellehenk73",
            "points": 69,
            "livePoints": 69,
            "positionChange": null,
            "startingPosition": 5,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "LisbonAELion67",
            "points": 53,
            "livePoints": 53,
            "positionChange": null,
            "startingPosition": 6,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "ScooterClem10",
            "points": 48,
            "livePoints": 48,
            "positionChange": null,
            "startingPosition": 7,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Reuze_Beertje",
            "points": 45,
            "livePoints": 45,
            "positionChange": null,
            "startingPosition": 8,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Spivey-CJ",
            "points": 36,
            "livePoints": 36,
            "positionChange": null,
            "startingPosition": 9,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "DSBAKER-58",
            "points": 32,
            "livePoints": 32,
            "positionChange": null,
            "startingPosition": 10,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "MikelAuf",
            "points": 32,
            "livePoints": 32,
            "positionChange": null,
            "startingPosition": 11,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Kieron_994",
            "points": 28,
            "livePoints": 28,
            "positionChange": null,
            "startingPosition": 12,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "nxxhonwkey",
            "points": 22,
            "livePoints": 22,
            "positionChange": null,
            "startingPosition": 13,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Danos",
            "points": 20,
            "livePoints": 20,
            "positionChange": null,
            "startingPosition": 14,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Mocky1480",
            "points": 20,
            "livePoints": 20,
            "positionChange": null,
            "startingPosition": 15,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Faulkiller",
            "points": 18,
            "livePoints": 18,
            "positionChange": null,
            "startingPosition": 16,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "OST_Jeweller",
            "points": 14,
            "livePoints": 14,
            "positionChange": null,
            "startingPosition": 17,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "TheChaosDruid",
            "points": 8,
            "livePoints": 8,
            "positionChange": null,
            "startingPosition": 18,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_IJVER",
            "points": 6,
            "livePoints": 6,
            "positionChange": null,
            "startingPosition": 19,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Shaunfuss",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 20,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        }
    ];

    let championshipStandingsBlank = [
        {
            "name": "Benpaines_",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 1,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Reuze_Beertje",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 2,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "booty1973",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 3,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "lViatt",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 4,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "ET8_Evil",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 5,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Wardy-R6",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 6,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Kieran0064",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 7,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Jetfish11",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 8,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "AL2737",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 9,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Stebo__",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "RustyOilcan",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 11,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },

        {
            "name": "RPM_Stubbe",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 12,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 13,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 14,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 15,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 16,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 17,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 18,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 19,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 20,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        }
    ];

    let championshipStandingsSeason6 = [
        {
            "points": 448,
            "name": "Jetfish11",
            "startingPosition": 1,
            "polePosition": false,
            "livePoints": 448,
            "pole": false,
            "fastestLap": false,
            "champion": false,
            "constructor": "mercedes",
            "fastest": false,
            "positionChange": null,
            "driverMatch": false
        },
        {
            "points": 398,
            "livePoints": 398,
            "positionChange": null,
            "constructor": "mercedes",
            "fastestLap": false,
            "name": "AL2737",
            "champion": false,
            "startingPosition": 2,
            "polePosition": false,
            "fastest": false,
            "driverMatch": false,
            "pole": false
        },
        {
            "positionChange": null,
            "livePoints": 303,
            "name": "Stebo__",
            "pole": false,
            "startingPosition": 3,
            "fastestLap": false,
            "points": 303,
            "polePosition": false,
            "champion": false,
            "driverMatch": false,
            "constructor": "ferrari",
            "fastest": false
        },
        {
            "positionChange": null,
            "fastest": false,
            "constructor": "mclaren",
            "points": 248,
            "pole": false,
            "name": "Kieran0064",
            "champion": false,
            "livePoints": 248,
            "driverMatch": false,
            "fastestLap": false,
            "polePosition": false,
            "startingPosition": 4
        },
        {
            "points": 223,
            "name": "booty1973",
            "constructor": "mclaren",
            "polePosition": false,
            "fastestLap": false,
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false,
            "positionChange": null,
            "startingPosition": 5,
            "livePoints": 223
        },
        {
            "pole": false,
            "fastestLap": false,
            "driverMatch": false,
            "livePoints": 156,
            "name": "lViatt",
            "champion": false,
            "startingPosition": 6,
            "constructor": "redbull",
            "polePosition": false,
            "positionChange": null,
            "fastest": false,
            "points": 156
        },
        {
            "positionChange": null,
            "driverMatch": false,
            "name": "DZ_Karting",
            "livePoints": 130,
            "fastest": false,
            "points": 130,
            "startingPosition": 7,
            "pole": false,
            "champion": false,
            "constructor": "alphaTauri",
            "polePosition": false,
            "fastestLap": false
        },
        {
            "fastestLap": false,
            "champion": false,
            "fastest": false,
            "driverMatch": false,
            "startingPosition": 8,
            "livePoints": 98,
            "constructor": "haas",
            "positionChange": null,
            "name": "RPM_Stubbe",
            "points": 98,
            "polePosition": false,
            "pole": false
        },
        {
            "livePoints": 98,
            "constructor": "ferrari",
            "fastestLap": false,
            "polePosition": false,
            "champion": false,
            "pole": false,
            "positionChange": null,
            "driverMatch": false,
            "name": "RPM_BAKKER1194",
            "fastest": false,
            "points": 98,
            "startingPosition": 9
        },
        {
            "driverMatch": false,
            "polePosition": false,
            "champion": false,
            "name": "RustyOilcan",
            "fastestLap": false,
            "pole": false,
            "points": 78,
            "positionChange": null,
            "livePoints": 78,
            "fastest": false,
            "constructor": "alfaRomeo",
            "startingPosition": 10
        },
        {
            "champion": false,
            "fastestLap": false,
            "pole": false,
            "positionChange": null,
            "points": 45,
            "polePosition": false,
            "name": "lost_glaedr",
            "fastest": false,
            "startingPosition": 11,
            "constructor": "haas",
            "driverMatch": false,
            "livePoints": 45
        },
        {
            "livePoints": 41,
            "name": "RPM_Zabijaka420",
            "polePosition": false,
            "positionChange": null,
            "points": 41,
            "startingPosition": 12,
            "champion": false,
            "pole": false,
            "constructor": "alfaRomeo",
            "fastestLap": false,
            "driverMatch": false,
            "fastest": false
        },
        {
            "points": 22,
            "pole": false,
            "polePosition": false,
            "driverMatch": false,
            "fastest": false,
            "livePoints": 22,
            "constructor": "williams",
            "name": "BigPuggy1987",
            "fastestLap": false,
            "champion": false,
            "positionChange": null,
            "startingPosition": 13
        },
        {
            "polePosition": false,
            "champion": false,
            "fastestLap": false,
            "driverMatch": false,
            "points": 0,
            "pole": false,
            "livePoints": 0,
            "startingPosition": 14,
            "constructor": "alpine",
            "positionChange": null,
            "fastest": false,
            "name": "Benpaines_"
        },
        {
            "fastest": false,
            "constructor": "astonMartin",
            "polePosition": false,
            "points": 0,
            "positionChange": null,
            "champion": false,
            "livePoints": 0,
            "fastestLap": false,
            "driverMatch": false,
            "name": "Fragiletunnel",
            "startingPosition": 15,
            "pole": false
        },
        {
            "champion": false,
            "polePosition": false,
            "points": 0,
            "pole": false,
            "fastest": false,
            "fastestLap": false,
            "driverMatch": false,
            "name": "UnluckyFC_",
            "livePoints": 0,
            "constructor": "astonMartin",
            "positionChange": null,
            "startingPosition": 16
        },
        {
            "pole": false,
            "livePoints": 0,
            "polePosition": false,
            "constructor": "alpine",
            "fastestLap": false,
            "positionChange": null,
            "fastest": false,
            "driverMatch": false,
            "name": "Brocki4",
            "points": 0,
            "startingPosition": 17,
            "champion": false
        },
        {
            "polePosition": false,
            "startingPosition": 18,
            "name": "No Driver",
            "pole": false,
            "points": 0,
            "positionChange": null,
            "driverMatch": false,
            "fastest": false,
            "fastestLap": false,
            "champion": false,
            "livePoints": 0,
            "constructor": "redbull"
        },
        {
            "pole": false,
            "points": 0,
            "fastestLap": false,
            "name": "No Driver",
            "livePoints": 0,
            "polePosition": false,
            "positionChange": null,
            "constructor": "alphaTauri",
            "champion": false,
            "startingPosition": 19,
            "fastest": false,
            "driverMatch": false
        },
        {
            "constructor": "williams",
            "polePosition": false,
            "startingPosition": 20,
            "pole": false,
            "champion": false,
            "driverMatch": false,
            "points": 0,
            "positionChange": null,
            "fastest": false,
            "name": "No Driver",
            "fastestLap": false,
            "livePoints": 0
        }
    ]

    let championshipStandings = [
        {
            "name": "smit1717",
            "points": 95,
            "livePoints": 95,
            "positionChange": null,
            "startingPosition": 1,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "RPM_Stubbe",
            "points": 77,
            "livePoints": 77,
            "positionChange": null,
            "startingPosition": 2,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "GiraffeBHA",
            "points": 75,
            "livePoints": 75,
            "positionChange": null,
            "startingPosition": 3,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "RPM_Entity123",
            "points": 75,
            "livePoints": 75,
            "positionChange": null,
            "startingPosition": 4,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "RPM_Bakker1194",
            "points": 71,
            "livePoints": 71,
            "positionChange": null,
            "startingPosition": 5,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "matty347",
            "points": 65,
            "livePoints": 65,
            "positionChange": null,
            "startingPosition": 6,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "N4T_P4CK3R5",
            "points": 59,
            "livePoints": 59,
            "positionChange": null,
            "startingPosition": 7,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "RTRodo",
            "points": 46,
            "livePoints": 46,
            "positionChange": null,
            "startingPosition": 8,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "lost_glaedr",
            "points": 44,
            "livePoints": 44,
            "positionChange": null,
            "startingPosition": 9,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Laur1609",
            "points": 35,
            "livePoints": 35,
            "positionChange": null,
            "startingPosition": 10,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Kieran0064",
            "points": 27,
            "livePoints": 27,
            "positionChange": null,
            "startingPosition": 11,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Rycka9",
            "points": 25,
            "livePoints": 25,
            "positionChange": null,
            "startingPosition": 12,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Jleeky7",
            "points": 23,
            "livePoints": 23,
            "positionChange": null,
            "startingPosition": 13,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "RPM_husssrc",
            "points": 23,
            "livePoints": 23,
            "positionChange": null,
            "startingPosition": 14,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Bronco_8_",
            "points": 17,
            "livePoints": 17,
            "positionChange": null,
            "startingPosition": 15,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Explicitsimmo23",
            "points": 14,
            "livePoints": 14,
            "positionChange": null,
            "startingPosition": 16,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "RustyOilcan",
            "points": 5,
            "livePoints": 5,
            "positionChange": null,
            "startingPosition": 17,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "booty1973",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 18,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "XdOscarvdg",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 19,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        },
        {
            "name": "Troy08Blue",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 20,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "driverMatch": false,
            "champion": false
        }
    ];

    let premierStandingsBlank = [
        {
            "name": "RPM_Colin-86",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 1,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Sinfull",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 2,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Branden23",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 3,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RVL_Volcano",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 4,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Glassy",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 5,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Higgzy",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 6,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Luukvdm",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 7,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Andrewx2905",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 8,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_KingPinquinn",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 9,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Spiderman__F1",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Zabijaka420",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 11,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_BAKKER1194",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 12,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Entity123",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 13,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "GiraffeBha",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 14,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Cosmo",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 15,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "maggot361975",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 16,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Dragos",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 17,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_husssrc",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 18,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "davie_w",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 19,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Juul_Warrior_",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 20,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        }
    ];

    let premierStandingsSeason6 = [
        {
            "positionChange": null,
            "points": 442,
            "driverMatch": false,
            "startingPosition": 1,
            "constructor": "ferrari",
            "name": "RPM_Colin-86",
            "fastest": false,
            "livePoints": 442,
            "polePosition": false,
            "fastestLap": false,
            "champion": true,
            "pole": false
        },
        {
            "constructor": "haas",
            "fastest": false,
            "driverMatch": false,
            "pole": false,
            "startingPosition": 2,
            "name": "RVL_Volcano",
            "champion": false,
            "livePoints": 273,
            "fastestLap": false,
            "polePosition": false,
            "points": 273,
            "positionChange": null
        },
        {
            "fastest": false,
            "points": 244,
            "fastestLap": false,
            "polePosition": false,
            "livePoints": 244,
            "positionChange": null,
            "champion": false,
            "pole": false,
            "driverMatch": false,
            "startingPosition": 3,
            "name": "Andrewx2905",
            "constructor": "redbull"
        },
        {
            "driverMatch": false,
            "polePosition": false,
            "positionChange": null,
            "fastestLap": false,
            "constructor": "alfaRomeo",
            "livePoints": 225,
            "champion": false,
            "name": "davie_w",
            "points": 225,
            "startingPosition": 4,
            "pole": false,
            "fastest": false
        },
        {
            "champion": false,
            "polePosition": false,
            "positionChange": null,
            "startingPosition": 5,
            "fastest": false,
            "livePoints": 207,
            "points": 207,
            "fastestLap": false,
            "constructor": "redbull",
            "pole": false,
            "driverMatch": false,
            "name": "ET8_Evil"
        },
        {
            "pole": false,
            "livePoints": 182,
            "champion": false,
            "fastestLap": false,
            "positionChange": null,
            "fastest": false,
            "driverMatch": false,
            "name": "maggot361975",
            "startingPosition": 6,
            "polePosition": false,
            "points": 182,
            "constructor": "astonMartin"
        },
        {
            "driverMatch": false,
            "fastestLap": false,
            "livePoints": 182,
            "champion": false,
            "points": 182,
            "fastest": false,
            "positionChange": null,
            "pole": false,
            "startingPosition": 7,
            "name": "RPM_Glassy",
            "constructor": "mclaren",
            "polePosition": false
        },
        {
            "polePosition": false,
            "livePoints": 179,
            "constructor": "mclaren",
            "driverMatch": false,
            "fastest": false,
            "pole": false,
            "positionChange": null,
            "startingPosition": 8,
            "champion": false,
            "name": "RPM_Higgzy",
            "points": 179,
            "fastestLap": false
        },
        {
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false,
            "polePosition": false,
            "constructor": "alfaRomeo",
            "name": "RPM_husssrc",
            "points": 154,
            "fastestLap": false,
            "positionChange": null,
            "livePoints": 154,
            "startingPosition": 9
        },
        {
            "polePosition": false,
            "driverMatch": false,
            "positionChange": null,
            "pole": false,
            "livePoints": 131,
            "champion": false,
            "constructor": "mercedes",
            "points": 131,
            "fastestLap": false,
            "fastest": false,
            "name": "RPM_KingPinquinn",
            "startingPosition": 10
        },
        {
            "positionChange": null,
            "polePosition": false,
            "fastest": false,
            "pole": false,
            "startingPosition": 11,
            "champion": false,
            "driverMatch": false,
            "constructor": "ferrari",
            "points": 126,
            "livePoints": 126,
            "name": "RPM_Luukvdm",
            "fastestLap": false
        },
        {
            "driverMatch": false,
            "fastestLap": false,
            "constructor": "mercedes",
            "livePoints": 106,
            "pole": false,
            "name": "RPM_Sinfull",
            "points": 106,
            "fastest": false,
            "champion": false,
            "polePosition": false,
            "positionChange": null,
            "startingPosition": 12
        },
        {
            "positionChange": null,
            "constructor": "alpine",
            "startingPosition": 13,
            "driverMatch": false,
            "fastest": false,
            "livePoints": 103,
            "polePosition": false,
            "champion": false,
            "pole": false,
            "fastestLap": false,
            "name": "RPM_Dragos",
            "points": 103
        },
        {
            "champion": false,
            "positionChange": null,
            "livePoints": 68,
            "pole": false,
            "fastest": false,
            "points": 68,
            "startingPosition": 14,
            "polePosition": false,
            "name": "Sminotla789",
            "driverMatch": false,
            "fastestLap": false,
            "constructor": "alphaTauri"
        },
        {
            "fastest": false,
            "livePoints": 66,
            "name": "RPM_Entity123",
            "driverMatch": false,
            "pole": false,
            "startingPosition": 15,
            "positionChange": null,
            "fastestLap": false,
            "polePosition": false,
            "points": 66,
            "constructor": "williams",
            "champion": false
        },
        {
            "constructor": "williams",
            "fastestLap": false,
            "driverMatch": false,
            "livePoints": 52,
            "champion": false,
            "pole": false,
            "startingPosition": 16,
            "points": 52,
            "fastest": false,
            "positionChange": null,
            "name": "GiraffeBha",
            "polePosition": false
        },
        {
            "pole": false,
            "champion": false,
            "points": 21,
            "startingPosition": 17,
            "fastest": false,
            "driverMatch": false,
            "livePoints": 21,
            "polePosition": false,
            "constructor": "alpine",
            "name": "C_osmo90",
            "positionChange": null,
            "fastestLap": false
        },
        {
            "points": 8,
            "champion": false,
            "driverMatch": false,
            "startingPosition": 18,
            "positionChange": null,
            "pole": false,
            "polePosition": false,
            "name": "Juul_Warrior_",
            "fastest": false,
            "fastestLap": false,
            "constructor": "alphaTauri",
            "livePoints": 8
        },
        {
            "startingPosition": 19,
            "points": 4,
            "polePosition": false,
            "driverMatch": false,
            "livePoints": 4,
            "name": "CPI_Mango",
            "pole": false,
            "positionChange": null,
            "fastestLap": false,
            "champion": false,
            "fastest": false,
            "constructor": "astonMartin"
        },
        {
            "positionChange": null,
            "fastest": false,
            "constructor": "haas",
            "fastestLap": false,
            "startingPosition": 20,
            "driverMatch": false,
            "name": "ABV",
            "polePosition": false,
            "champion": false,
            "livePoints": 0,
            "points": 0,
            "pole": false
        }
    ]

    let premierStandings = [
        {
            "name": "Banter_Branden_0",
            "points": 68,
            "livePoints": 68,
            "positionChange": null,
            "startingPosition": 1,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "LearnerDriver86",
            "points": 49,
            "livePoints": 49,
            "positionChange": null,
            "startingPosition": 2,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "AL2737",
            "points": 48,
            "livePoints": 48,
            "positionChange": null,
            "startingPosition": 3,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Jetfish11",
            "points": 41,
            "livePoints": 41,
            "positionChange": null,
            "startingPosition": 4,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RVL_Volcano",
            "points": 34,
            "livePoints": 34,
            "positionChange": null,
            "startingPosition": 5,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Higgzy",
            "points": 33,
            "livePoints": 33,
            "positionChange": null,
            "startingPosition": 6,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Davie_w",
            "points": 28,
            "livePoints": 28,
            "positionChange": null,
            "startingPosition": 7,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Stebo__",
            "points": 26,
            "livePoints": 26,
            "positionChange": null,
            "startingPosition": 8,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Sminotla789",
            "points": 21,
            "livePoints": 21,
            "positionChange": null,
            "startingPosition": 9,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Maggot361975",
            "points": 15,
            "livePoints": 15,
            "positionChange": null,
            "startingPosition": 10,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Dragos",
            "points": 12,
            "livePoints": 12,
            "positionChange": null,
            "startingPosition": 11,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Kvkfc",
            "points": 11,
            "livePoints": 11,
            "positionChange": null,
            "startingPosition": 12,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_KingPinquinn",
            "points": 7,
            "livePoints": 7,
            "positionChange": null,
            "startingPosition": 13,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Yeast",
            "points": 5,
            "livePoints": 5,
            "positionChange": null,
            "startingPosition": 14,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "RPM_Luukvdm",
            "points": 2,
            "livePoints": 2,
            "positionChange": null,
            "startingPosition": 15,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "ET8_Evil",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 16,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Zykotikz",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 17,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 18,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 19,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "No Driver",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 20,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        }
    ];

    let offlineStandings = [
        {
            "name": "Alonso",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 1,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Ocon",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 2,
            "constructor": "alpine",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Bottas",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 3,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Zhou",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 4,
            "constructor": "alfaRomeo",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Gasly",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 5,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Tsunoda",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 6,
            "constructor": "alphaTauri",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Vettel",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 7,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Stroll",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 8,
            "constructor": "astonMartin",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Magnussen",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 9,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Schumacher",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "constructor": "haas",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Leclerc",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 11,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Sainz",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 12,
            "constructor": "ferrari",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Ricciardo",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 13,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Norris",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 14,
            "constructor": "mclaren",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Hamilton",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 15,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Russell",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 16,
            "constructor": "mercedes",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Verstappen",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 17,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Pérez",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 18,
            "constructor": "redbull",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Albon",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 19,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        },
        {
            "name": "Latifi",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 20,
            "constructor": "williams",
            "pole": false,
            "fastest": false,
            "champion": false,
            "driverMatch": false
        }
    ]

    let premierConstructorsBlank = [
        {
            "name": "ferrari",
            "constructor": "ferrari",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 1,
            "champion": false
        },
        {
            "name": "mclaren",
            "constructor": "mclaren",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 2,
            "champion": false
        },
        {
            "name": "mercedes",
            "constructor": "mercedes",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 3,
            "champion": false
        },
        {
            "name": "haas",
            "constructor": "haas",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 4,
            "champion": false
        },
        {
            "name": "redbull",
            "constructor": "redbull",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 5,
            "champion": false
        },
        {
            "name": "alphaTauri",
            "constructor": "alphaTauri",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 6,
            "champion": false
        },
        {
            "name": "williams",
            "constructor": "williams",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 7,
            "champion": false
        },
        {
            "name": "astonMartin",
            "constructor": "astonMartin",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 8,
            "champion": false
        },
        {
            "name": "alpine",
            "constructor": "alpine",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 9,
            "champion": false
        },
        {
            "name": "alfaRomeo",
            "constructor": "alfaRomeo",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "champion": false
        },
    ]

    let premierConstructorsSeason6 = [
        {
            "champion": false,
            "name": "ferrari",
            "livePoints": 516,
            "constructor": "ferrari",
            "startingPosition": 1,
            "points": 516,
            "positionChange": null
        },
        {
            "livePoints": 424,
            "champion": false,
            "name": "redbull",
            "points": 424,
            "startingPosition": 2,
            "constructor": "redbull",
            "positionChange": null
        },
        {
            "positionChange": null,
            "livePoints": 345,
            "constructor": "alfaRomeo",
            "points": 345,
            "champion": false,
            "startingPosition": 3,
            "name": "alfaRomeo"
        },
        {
            "startingPosition": 4,
            "constructor": "mclaren",
            "positionChange": null,
            "champion": false,
            "points": 339,
            "livePoints": 339,
            "name": "mclaren"
        },
        {
            "name": "haas",
            "positionChange": null,
            "points": 243,
            "constructor": "haas",
            "champion": false,
            "startingPosition": 5,
            "livePoints": 243
        },
        {
            "positionChange": null,
            "startingPosition": 6,
            "points": 220,
            "livePoints": 220,
            "constructor": "mercedes",
            "champion": false,
            "name": "mercedes"
        },
        {
            "points": 200,
            "constructor": "astonMartin",
            "name": "astonMartin",
            "startingPosition": 7,
            "livePoints": 200,
            "champion": false,
            "positionChange": null
        },
        {
            "positionChange": null,
            "champion": false,
            "points": 118,
            "startingPosition": 8,
            "constructor": "alpine",
            "livePoints": 118,
            "name": "alpine"
        },
        {
            "points": 112,
            "constructor": "williams",
            "name": "williams",
            "livePoints": 112,
            "startingPosition": 9,
            "positionChange": null,
            "champion": false
        },
        {
            "startingPosition": 10,
            "livePoints": 58,
            "name": "alphaTauri",
            "constructor": "alphaTauri",
            "positionChange": null,
            "points": 58,
            "champion": false
        }
    ]

    let premierConstructors = [
        {
            "name": "mercedes",
            "constructor": "mercedes",
            "points": 89,
            "livePoints": 89,
            "positionChange": null,
            "startingPosition": 1,
            "champion": false
        },{
            "name": "ferrari",
            "constructor": "ferrari",
            "points": 75,
            "livePoints": 75,
            "positionChange": null,
            "startingPosition": 2,
            "champion": false
        },
        {
            "name": "alfaRomeo",
            "constructor": "alfaRomeo",
            "points": 62,
            "livePoints": 62,
            "positionChange": null,
            "startingPosition": 3,
            "champion": false
        },
        {
            "name": "williams",
            "constructor": "williams",
            "points": 51,
            "livePoints": 51,
            "positionChange": null,
            "startingPosition": 4,
            "champion": false
        },
        {
            "name": "astonMartin",
            "constructor": "astonMartin",
            "points": 47,
            "livePoints": 47,
            "positionChange": null,
            "startingPosition": 5,
            "champion": false
        },
        {
            "name": "redbull",
            "constructor": "redbull",
            "points": 44,
            "livePoints": 44,
            "positionChange": null,
            "startingPosition": 6,
            "champion": false
        },
        {
            "name": "alpine",
            "constructor": "alpine",
            "points": 15,
            "livePoints": 15,
            "positionChange": null,
            "startingPosition": 7,
            "champion": false
        },
        {
            "name": "alphaTauri",
            "constructor": "alphaTauri",
            "points": 12,
            "livePoints": 12,
            "positionChange": null,
            "startingPosition": 8,
            "champion": false
        },
        {
            "name": "mclaren",
            "constructor": "mclaren",
            "points": 5,
            "livePoints": 5,
            "positionChange": null,
            "startingPosition": 9,
            "champion": false
        },
        {
            "name": "haas",
            "constructor": "haas",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "champion": false
        }
    ]

    let championshipConstructorsBlank = [
        {
            "name": "williams",
            "constructor": "williams",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 1,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "redbull",
            "constructor": "redbull",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 2,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alfaRomeo",
            "constructor": "alfaRomeo",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 3,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alphaTauri",
            "constructor": "alphaTauri",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 4,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "ferrari",
            "constructor": "ferrari",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 5,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alpine",
            "constructor": "alpine",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 6,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "mclaren",
            "constructor": "mclaren",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 7,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "astonMartin",
            "constructor": "astonMartin",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 8,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "mercedes",
            "constructor": "mercedes",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 9,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "haas",
            "constructor": "haas",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },

    ];

    let championshipConstructorsSeason6 = [
        {
            "name": "mercedes",
            "constructor": "mercedes",
            "fastestLap": false,
            "startingPosition": 1,
            "champion": true,
            "polePosition": false,
            "positionChange": null,
            "points": 846,
            "livePoints": 846
        },
        {
            "polePosition": false,
            "points": 477,
            "fastestLap": false,
            "livePoints": 477,
            "constructor": "ferrari",
            "name": "ferrari",
            "startingPosition": 2,
            "champion": false,
            "positionChange": null
        },
        {
            "livePoints": 471,
            "constructor": "mclaren",
            "name": "mclaren",
            "points": 471,
            "startingPosition": 3,
            "polePosition": false,
            "fastestLap": false,
            "champion": false,
            "positionChange": null
        },
        {
            "polePosition": false,
            "champion": false,
            "constructor": "alfaRomeo",
            "positionChange": null,
            "fastestLap": false,
            "livePoints": 180,
            "name": "alfaRomeo",
            "startingPosition": 4,
            "points": 180
        },
        {
            "constructor": "redbull",
            "name": "redbull",
            "livePoints": 156,
            "startingPosition": 5,
            "positionChange": null,
            "fastestLap": false,
            "champion": false,
            "polePosition": false,
            "points": 156
        },
        {
            "champion": false,
            "startingPosition": 6,
            "positionChange": null,
            "fastestLap": false,
            "livePoints": 143,
            "polePosition": false,
            "name": "haas",
            "constructor": "haas",
            "points": 143
        },
        {
            "polePosition": false,
            "livePoints": 130,
            "positionChange": null,
            "fastestLap": false,
            "name": "alphaTauri",
            "startingPosition": 7,
            "points": 130,
            "constructor": "alphaTauri",
            "champion": false
        },
        {
            "fastestLap": false,
            "startingPosition": 8,
            "polePosition": false,
            "constructor": "williams",
            "champion": false,
            "livePoints": 65,
            "points": 65,
            "positionChange": null,
            "name": "williams"
        },
        {
            "startingPosition": 9,
            "fastestLap": false,
            "champion": false,
            "positionChange": null,
            "name": "alpine",
            "constructor": "alpine",
            "livePoints": 0,
            "points": 0,
            "polePosition": false
        },
        {
            "livePoints": 0,
            "points": 0,
            "positionChange": null,
            "polePosition": false,
            "name": "astonMartin",
            "champion": false,
            "constructor": "astonMartin",
            "startingPosition": 10,
            "fastestLap": false
        }
    ]

    let championshipConstructors = [
        {
            "name": "mercedes",
            "constructor": "mercedes",
            "points": 160,
            "livePoints": 160,
            "positionChange": null,
            "startingPosition": 1,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "williams",
            "constructor": "williams",
            "points": 150,
            "livePoints": 150,
            "positionChange": null,
            "startingPosition": 2,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "astonMartin",
            "constructor": "astonMartin",
            "points": 105,
            "livePoints": 105,
            "positionChange": null,
            "startingPosition": 3,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alphaTauri",
            "constructor": "alphaTauri",
            "points": 100,
            "livePoints": 100,
            "positionChange": null,
            "startingPosition": 4,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "redbull",
            "constructor": "redbull",
            "points": 73,
            "livePoints": 73,
            "positionChange": null,
            "startingPosition": 5,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alpine",
            "constructor": "alpine",
            "points": 69,
            "livePoints": 69,
            "positionChange": null,
            "startingPosition": 6,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "ferrari",
            "constructor": "ferrari",
            "points": 61,
            "livePoints": 61,
            "positionChange": null,
            "startingPosition": 7,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alfaRomeo",
            "constructor": "alfaRomeo",
            "points": 30,
            "livePoints": 30,
            "positionChange": null,
            "startingPosition": 8,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "mclaren",
            "constructor": "mclaren",
            "points": 27,
            "livePoints": 27,
            "positionChange": null,
            "startingPosition": 9,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "haas",
            "constructor": "haas",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },

    ];

    let firstConstructorsBlank = [
        {
            "name": "williams",
            "constructor": "williams",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 1,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "redbull",
            "constructor": "redbull",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 2,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alfaRomeo",
            "constructor": "alfaRomeo",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 3,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alphaTauri",
            "constructor": "alphaTauri",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 4,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "ferrari",
            "constructor": "ferrari",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 5,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alpine",
            "constructor": "alpine",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 6,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "mclaren",
            "constructor": "mclaren",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 7,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "astonMartin",
            "constructor": "astonMartin",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 8,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "mercedes",
            "constructor": "mercedes",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 9,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "haas",
            "constructor": "haas",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },

    ];

    let firstConstructorsSeason6 = [
        {
            "champion": false,
            "fastestLap": false,
            "positionChange": null,
            "polePosition": false,
            "startingPosition": 1,
            "name": "haas",
            "livePoints": 451,
            "points": 451,
            "constructor": "haas"
        },
        {
            "champion": false,
            "fastestLap": false,
            "points": 441,
            "startingPosition": 2,
            "livePoints": 441,
            "constructor": "williams",
            "positionChange": null,
            "name": "williams",
            "polePosition": false
        },
        {
            "polePosition": false,
            "livePoints": 410,
            "startingPosition": 3,
            "fastestLap": false,
            "positionChange": null,
            "constructor": "mercedes",
            "name": "mercedes",
            "champion": false,
            "points": 410
        },
        {
            "points": 334,
            "livePoints": 334,
            "name": "redbull",
            "startingPosition": 4,
            "positionChange": null,
            "fastestLap": false,
            "champion": false,
            "constructor": "redbull",
            "polePosition": false
        },
        {
            "name": "alfaRomeo",
            "points": 318,
            "startingPosition": 5,
            "polePosition": false,
            "livePoints": 318,
            "champion": false,
            "constructor": "alfaRomeo",
            "positionChange": null,
            "fastestLap": false
        },
        {
            "points": 299,
            "champion": false,
            "livePoints": 299,
            "startingPosition": 6,
            "name": "alpine",
            "positionChange": null,
            "constructor": "alpine",
            "polePosition": false,
            "fastestLap": false
        },
        {
            "fastestLap": false,
            "livePoints": 260,
            "champion": false,
            "startingPosition": 7,
            "positionChange": null,
            "constructor": "ferrari",
            "name": "ferrari",
            "polePosition": false,
            "points": 260
        },
        {
            "positionChange": null,
            "constructor": "mclaren",
            "name": "mclaren",
            "fastestLap": false,
            "champion": false,
            "polePosition": false,
            "points": 212,
            "livePoints": 212,
            "startingPosition": 8
        },
        {
            "livePoints": 205,
            "startingPosition": 9,
            "constructor": "astonMartin",
            "points": 205,
            "fastestLap": false,
            "name": "astonMartin",
            "polePosition": false,
            "positionChange": null,
            "champion": false
        },
        {
            "name": "alphaTauri",
            "livePoints": 139,
            "constructor": "alphaTauri",
            "points": 139,
            "startingPosition": 10,
            "champion": false,
            "positionChange": null,
            "polePosition": false,
            "fastestLap": false
        }
    ]

    let firstConstructors = [
        {
            "name": "mercedes",
            "constructor": "mercedes",
            "points": 132,
            "livePoints": 132,
            "positionChange": null,
            "startingPosition": 1,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alfaRomeo",
            "constructor": "alfaRomeo",
            "points": 125,
            "livePoints": 125,
            "positionChange": null,
            "startingPosition": 2,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "astonMartin",
            "constructor": "astonMartin",
            "points": 95,
            "livePoints": 95,
            "positionChange": null,
            "startingPosition": 3,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alphaTauri",
            "constructor": "alphaTauri",
            "points": 91,
            "livePoints": 91,
            "positionChange": null,
            "startingPosition": 4,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alpine",
            "constructor": "alpine",
            "points": 77,
            "livePoints": 77,
            "positionChange": null,
            "startingPosition": 5,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "williams",
            "constructor": "williams",
            "points": 77,
            "livePoints": 77,
            "positionChange": null,
            "startingPosition": 6,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "redbull",
            "constructor": "redbull",
            "points": 76,
            "livePoints": 76,
            "positionChange": null,
            "startingPosition": 7,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "haas",
            "constructor": "haas",
            "points": 68,
            "livePoints": 68,
            "positionChange": null,
            "startingPosition": 8,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "ferrari",
            "constructor": "ferrari",
            "points": 26,
            "livePoints": 26,
            "positionChange": null,
            "startingPosition": 9,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "mclaren",
            "constructor": "mclaren",
            "points": 18,
            "livePoints": 18,
            "positionChange": null,
            "startingPosition": 10,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        }

    ];

    let practiceConstructors = [
        {
            "name": "williams",
            "constructor": "williams",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 1,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "redbull",
            "constructor": "redbull",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 2,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alfaRomeo",
            "constructor": "alfaRomeo",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 3,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alphaTauri",
            "constructor": "alphaTauri",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 4,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "ferrari",
            "constructor": "ferrari",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 5,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alpine",
            "constructor": "alpine",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 6,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "mclaren",
            "constructor": "mclaren",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 7,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "astonMartin",
            "constructor": "astonMartin",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 8,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "mercedes",
            "constructor": "mercedes",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 9,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "haas",
            "constructor": "haas",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },

    ];

    let offlineConstructors = [
        {
            "name": "williams",
            "constructor": "williams",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 1,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "redbull",
            "constructor": "redbull",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 2,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alfaRomeo",
            "constructor": "alfaRomeo",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 3,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alphaTauri",
            "constructor": "alphaTauri",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 4,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "ferrari",
            "constructor": "ferrari",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 5,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "alpine",
            "constructor": "alpine",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 6,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "mclaren",
            "constructor": "mclaren",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 7,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "astonMartin",
            "constructor": "astonMartin",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 8,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "mercedes",
            "constructor": "mercedes",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 9,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },
        {
            "name": "haas",
            "constructor": "haas",
            "points": 0,
            "livePoints": 0,
            "positionChange": null,
            "startingPosition": 10,
            "champion": false,
            "fastestLap": false,
            "polePosition": false
        },

    ];

    // let allTiers = [
    //     {
    //         "name": "alfaRomeo",
    //         "constructor": "alfaRomeo",
    //         "points": 113,
    //         "livePoints": 113,
    //         "positionChange": null,
    //         "startingPosition": 1,
    //         "champion": false
    //     },
    //     {
    //         "name": "ferrari",
    //         "constructor": "ferrari",
    //         "points": 106,
    //         "livePoints": 106,
    //         "positionChange": null,
    //         "startingPosition": 2,
    //         "champion": false
    //     },
    //     {
    //         "name": "mercedes",
    //         "constructor": "mercedes",
    //         "points": 99,
    //         "livePoints": 99,
    //         "positionChange": null,
    //         "startingPosition": 3,
    //         "champion": false
    //     },
    //     {
    //         "name": "mclaren",
    //         "constructor": "mclaren",
    //         "points": 76,
    //         "livePoints": 76,
    //         "positionChange": null,
    //         "startingPosition": 4,
    //         "champion": false
    //     },
    //     {
    //         "name": "redbull",
    //         "constructor": "redbull",
    //         "points": 73,
    //         "livePoints": 73,
    //         "positionChange": null,
    //         "startingPosition": 5,
    //         "champion": false
    //     },
    //     {
    //         "name": "williams",
    //         "constructor": "williams",
    //         "points": 65,
    //         "livePoints": 65,
    //         "positionChange": null,
    //         "startingPosition": 6,
    //         "champion": false
    //     },
    //     {
    //         "name": "haas",
    //         "constructor": "haas",
    //         "points": 64,
    //         "livePoints": 64,
    //         "positionChange": null,
    //         "startingPosition": 7,
    //         "champion": false
    //     },
    //     {
    //         "name": "astonMartin",
    //         "constructor": "astonMartin",
    //         "points": 54,
    //         "livePoints": 54,
    //         "positionChange": null,
    //         "startingPosition": 8,
    //         "champion": false
    //     },
    //     {
    //         "name": "alpine",
    //         "constructor": "alpine",
    //         "points": 20,
    //         "livePoints": 20,
    //         "positionChange": null,
    //         "startingPosition": 9,
    //         "champion": false
    //     },
    //     {
    //         "name": "alphaTauri",
    //         "constructor": "alphaTauri",
    //         "points": 8,
    //         "livePoints": 8,
    //         "positionChange": null,
    //         "startingPosition": 10,
    //         "champion": false
    //     }
    // ]

    // let allTiers = [
    //     {
    //         "name": "mercedes",
    //         "constructor": "mercedes",
    //         "points": 125,
    //         "livePoints": 125,
    //         "positionChange": null,
    //         "startingPosition": 1,
    //         "champion": false
    //     },
    //     {
    //         "name": "ferrari",
    //         "constructor": "ferrari",
    //         "points": 118,
    //         "livePoints": 118,
    //         "positionChange": null,
    //         "startingPosition": 2,
    //         "champion": false
    //     },
    //     {
    //         "name": "alfaRomeo",
    //         "constructor": "alfaRomeo",
    //         "points": 114,
    //         "livePoints": 114,
    //         "positionChange": null,
    //         "startingPosition": 3,
    //         "champion": false
    //     },
    //     {
    //         "name": "haas",
    //         "constructor": "haas",
    //         "points": 112,
    //         "livePoints": 112,
    //         "positionChange": null,
    //         "startingPosition": 4,
    //         "champion": false
    //     },
    //     {
    //         "name": "williams",
    //         "constructor": "williams",
    //         "points": 106,
    //         "livePoints": 106,
    //         "positionChange": null,
    //         "startingPosition": 5,
    //         "champion": false
    //     },
    //     {
    //         "name": "redbull",
    //         "constructor": "redbull",
    //         "points": 99,
    //         "livePoints": 99,
    //         "positionChange": null,
    //         "startingPosition": 6,
    //         "champion": false
    //     },
    //     {
    //         "name": "mclaren",
    //         "constructor": "mclaren",
    //         "points": 82,
    //         "livePoints": 82,
    //         "positionChange": null,
    //         "startingPosition": 7,
    //         "champion": false
    //     },
    //     {
    //         "name": "astonMartin",
    //         "constructor": "astonMartin",
    //         "points": 70,
    //         "livePoints": 70,
    //         "positionChange": null,
    //         "startingPosition": 8,
    //         "champion": false
    //     },
    //     {
    //         "name": "alpine",
    //         "constructor": "alpine",
    //         "points": 50,
    //         "livePoints": 50,
    //         "positionChange": null,
    //         "startingPosition": 9,
    //         "champion": false
    //     },
    //     {
    //         "name": "alphaTauri",
    //         "constructor": "alphaTauri",
    //         "points": 35,
    //         "livePoints": 35,
    //         "positionChange": null,
    //         "startingPosition": 10,
    //         "champion": false
    //     }
    // ]

    let allTiersSeason6 = [
        {
            "points": 1462,
            "name": "mercedes",
            "constructor": "mercedes",
            "startingPosition": 1,
            "champion": false,
            "livePoints": 1462,
            "positionChange": null
        },
        {
            "champion": false,
            "points": 1292,
            "positionChange": null,
            "constructor": "ferrari",
            "livePoints": 1292,
            "name": "ferrari",
            "startingPosition": 2
        },
        {
            "livePoints": 1028,
            "positionChange": null,
            "startingPosition": 3,
            "champion": false,
            "name": "mclaren",
            "constructor": "mclaren",
            "points": 1028
        },
        {
            "name": "redbull",
            "livePoints": 983,
            "startingPosition": 4,
            "constructor": "redbull",
            "points": 983,
            "champion": false,
            "positionChange": null
        },
        {
            "livePoints": 877,
            "points": 877,
            "champion": false,
            "positionChange": null,
            "constructor": "alfaRomeo",
            "name": "alfaRomeo",
            "startingPosition": 5
        },
        {
            "constructor": "haas",
            "points": 815,
            "positionChange": null,
            "name": "haas",
            "champion": false,
            "livePoints": 815,
            "startingPosition": 6
        },
        {
            "livePoints": 583,
            "champion": false,
            "startingPosition": 7,
            "constructor": "williams",
            "name": "williams",
            "points": 583,
            "positionChange": null
        },
        {
            "constructor": "astonMartin",
            "positionChange": null,
            "startingPosition": 8,
            "name": "astonMartin",
            "champion": false,
            "points": 405,
            "livePoints": 405
        },
        {
            "champion": false,
            "positionChange": null,
            "name": "alpine",
            "livePoints": 388,
            "startingPosition": 9,
            "points": 388,
            "constructor": "alpine"
        },
        {
            "positionChange": null,
            "name": "alphaTauri",
            "points": 345,
            "champion": false,
            "livePoints": 345,
            "constructor": "alphaTauri",
            "startingPosition": 10
        }
    ]

    let allTiers = [
        {
            "points": 277,
            "name": "mercedes",
            "constructor": "mercedes",
            "startingPosition": 1,
            "champion": false,
            "livePoints": 277,
            "positionChange": null
        },
        {
            "livePoints": 155,
            "champion": false,
            "startingPosition": 2,
            "constructor": "williams",
            "name": "williams",
            "points": 155,
            "positionChange": null
        },
        {
            "name": "redbull",
            "livePoints": 144,
            "startingPosition": 3,
            "constructor": "redbull",
            "points": 144,
            "champion": false,
            "positionChange": null
        },
        {
            "constructor": "astonMartin",
            "positionChange": null,
            "startingPosition": 4,
            "name": "astonMartin",
            "champion": false,
            "points": 142,
            "livePoints": 142
        },
        {
            "livePoints": 139,
            "points": 139,
            "champion": false,
            "positionChange": null,
            "constructor": "alfaRomeo",
            "name": "alfaRomeo",
            "startingPosition": 5
        },
        {
            "champion": false,
            "points": 131,
            "positionChange": null,
            "constructor": "ferrari",
            "livePoints": 131,
            "name": "ferrari",
            "startingPosition": 6
        },
        {
            "positionChange": null,
            "name": "alphaTauri",
            "points": 85,
            "champion": false,
            "livePoints": 85,
            "constructor": "alphaTauri",
            "startingPosition": 7
        },
        {
            "champion": false,
            "positionChange": null,
            "name": "alpine",
            "livePoints": 73,
            "startingPosition": 8,
            "points": 73,
            "constructor": "alpine"
        },
        {
            "livePoints": 32,
            "positionChange": null,
            "startingPosition": 9,
            "champion": false,
            "name": "mclaren",
            "constructor": "mclaren",
            "points": 32
        },
        {
            "constructor": "haas",
            "points": 32,
            "positionChange": null,
            "name": "haas",
            "champion": false,
            "livePoints": 32,
            "startingPosition": 10
        }
    ]

    useEffect(() => {
        if (loading) {
            console.log('loading', loading);
            console.log('user', user)
            return;
        };
        if (user === null) {
            console.log('should redirect?')
            navigate("/login")
        };
        if (user.uid !== process.env.REACT_APP_MASTER_ADMIN) {
            console.log('should redirect?')
            navigate("/")
        }

    }, [user, loading]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                const email = user.email;
                // ...
                console.log("uid", uid, "email", email)
            } else {
                // User is signed out
                // ...
                console.log("user is logged out")
            }
        });

    }, [])

    useEffect(() => {
        if (user) {
            // get the JWT token from the server
            console.log('try for JWT');
            const url = process.env.REACT_APP_JWT_ENDPOINT;
            const payload = {
                userID: user.uid,
            };

            try {
                axios.post(url, payload).then((response) => {
                    console.log(response.data.token)
                    setCentrifugoJWT(response.data.token);
                });
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.log('no user yet')
        }

    }, [user])

    useEffect(() => {

        console.log('start the CENTRIFUGO use effect');
        let subServerResponse;
        let centrifuge
        if (centrifugoJWT !== "") {
            toast.success(`Established Connection.`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                // pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            centrifuge = new Centrifuge("wss://messaging.purplekraken.dev/centrifugo/connection/websocket", {
                token: centrifugoJWT,
            });
            centrifuge.on('connecting', function (ctx) {
                console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
            }).on('connected', function (ctx) {
                console.log(`connected over ${ctx.transport}`);
            }).on('disconnected', function (ctx) {
                console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
            }).connect();

            subServerResponse = centrifuge.newSubscription("rpm:serverResponse");

            subServerResponse.on('publication', function (ctx) {
                console.log(ctx)
            }).on('subscribing', function (ctx) {
                console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            }).on('subscribed', function (ctx) {
                console.log('subscribed', ctx);
            }).on('unsubscribed', function (ctx) {
                console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            }).subscribe();

            if (rebindAbly.instruction === 'status') {
                console.log('check server');
                centrifuge.publish("rlmTelemetryService:serverCommands", { commandName: "serverStatus", sessionKey: `${selectLeague}-${selectDivision}`, raceType: rebindAbly.raceType }).then(function (res) {
                    console.log('successfully published');
                }, function (err) {
                    console.log('publish error', err);
                });
            }

            if (rebindAbly.instruction === 'refresh') {
                console.log('refresh server');
                centrifuge.publish("rlmTelemetryService:serverCommands", { commandName: "prepareForRace", sessionKey: `${selectLeague}-${selectDivision}`, raceType: rebindAbly.raceType }).then(function (res) {
                    console.log('successfully published');
                }, function (err) {
                    console.log('publish error', err);
                });
            }

            if (rebindAbly.instruction === 'error') {
                console.log('throw error');
                centrifuge.publish("rlmTelemetryService:serverCommands", { commandName: "error", sessionKey: `${selectLeague}-${selectDivision}`, raceType: rebindAbly.raceType }).then(function (res) {
                    console.log('successfully published');
                }, function (err) {
                    console.log('publish error', err);
                });
            }

            if (rebindAbly.division === 'premier') {
                console.log('set for prem');
                centrifuge.publish("rlmTelemetryService:serverCommands", { commandName: "restartServer", port: 30501, sessionKey: `${selectLeague}-${selectDivision}`, raceType: rebindAbly.raceType }).then(function (res) {
                    console.log('successfully published');
                }, function (err) {
                    console.log('publish error', err);
                });
            }

            if (rebindAbly.division === 'championship') {
                console.log('set for champ');
                centrifuge.publish("rlmTelemetryService:serverCommands", { commandName: "restartServer", port: 30502, sessionKey: `${selectLeague}-${selectDivision}`, raceType: rebindAbly.raceType }).then(function (res) {
                    console.log('successfully published');
                }, function (err) {
                    console.log('publish error', err);
                });
            }

            if (rebindAbly.division === 'first') {
                console.log('set for first');
                centrifuge.publish("rlmTelemetryService:serverCommands", { commandName: "restartServer", port: 30503, sessionKey: `${selectLeague}-${selectDivision}`, raceType: rebindAbly.raceType }).then(function (res) {
                    console.log('successfully published');
                }, function (err) {
                    console.log('publish error', err);
                });
            }

            if (rebindAbly.division === 'practice') {
                console.log('set for practice');
                centrifuge.publish("rlmTelemetryService:serverCommands", { commandName: "restartServer", port: 30504, sessionKey: `${selectLeague}-${selectDivision}`, raceType: rebindAbly.raceType }).then(function (res) {
                    console.log('successfully published');
                }, function (err) {
                    console.log('publish error', err);
                });
            }

            if (rebindAbly.division === 'offline') {
                console.log('set for offline');
                centrifuge.publish("rlmTelemetryService:serverCommands", { commandName: "restartServer", port: 30505, sessionKey: `${selectLeague}-${selectDivision}`, raceType: rebindAbly.raceType }).then(function (res) {
                    console.log('successfully published');
                }, function (err) {
                    console.log('publish error', err);
                });
            }

        } else {
            console.log('Could not generate a secure JWT');
            toast.error(`Could not generate a secure JWT.`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }

        // run the cleanup to unsubscribe from pusher and unbind the events
        return () => {
            console.log('clean up');

            if (subServerResponse) {
                subServerResponse.unsubscribe();
                subServerResponse.removeAllListeners();
            }

            if (centrifuge) {
                centrifuge.disconnect();
            }
        };

    }, [rebindAbly, centrifugoJWT]);


    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }


    async function updateTable(docName, standings, constructors) {
        console.log(docName, standings, constructors)
        await setDoc(doc(db, process.env.REACT_APP_LEAGUE, docName), {
            standings: standings,
            constructors: constructors
        })
        toast.success(`The ${docName} tables have been updated.`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    }

    async function updateAllTiers(docName, constructors) {
        console.log(docName, constructors)
        await setDoc(doc(db, process.env.REACT_APP_LEAGUE, docName), {
            allTiers: constructors
        })
        toast.success(`The ${docName} tables have been updated.`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    }

    // updates the selected league - the Pusher useEffect is dependant on this so triggers the cleanup and rebinding after updating driver standings
    function refreshPusher(league) {
        setRebindAbly(0);
        setSelectDivision(league);
    }

    function refreshServer(instruction, division, race) {
        setRebindAbly({ instruction: instruction, division: division, raceType: race });
    }

    function formatDriverHistory() {
        // Your original JSON data
        const data = [
            {
                "__1": "",
                "__2": 1,
                "__3": 3,
                "__4": "Mercedes",
                "__5": "",
                "__6": "smit1717",
                "__7": 6,
                "__8": 8,
                "__9": 3,
                "__10": 8,
                "__11": 1,
                "__12": 3,
                "__13": 12,
                "__14": 1,
                "__15": 11,
                "__16": 1,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 2,
                "__3": 12,
                "__4": "Mercedes",
                "__5": "",
                "__6": "Spivey-CJ",
                "__7": 15,
                "__8": 17,
                "__9": 12,
                "__10": 17,
                "__11": 19,
                "__12": 16,
                "__13": 9,
                "__14": 15,
                "__15": 15,
                "__16": 14,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 3,
                "__3": 2,
                "__4": "Red Bull",
                "__5": "",
                "__6": "Rycka9",
                "__7": 2,
                "__8": 5,
                "__9": 5,
                "__10": 5,
                "__11": 18,
                "__12": 7,
                "__13": 7,
                "__14": 4,
                "__15": 6,
                "__16": "DNS",
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 4,
                "__3": 14,
                "__4": "Red Bull",
                "__5": "",
                "__6": "Callum0064",
                "__7": 10,
                "__8": 6,
                "__9": 1,
                "__10": 10,
                "__11": 5,
                "__12": 10,
                "__13": "DNS",
                "__14": 11,
                "__15": 1,
                "__16": 6,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 5,
                "__3": 7,
                "__4": "Ferrari",
                "__5": "",
                "__6": "RPM_Danos",
                "__7": 3,
                "__8": 11,
                "__9": 14,
                "__10": 4,
                "__11": 7,
                "__12": 12,
                "__13": 4,
                "__14": 5,
                "__15": 17,
                "__16": 2,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 6,
                "__3": 16,
                "__4": "Ferrari",
                "__5": "",
                "__6": "RPM_Ijver",
                "__7": 14,
                "__8": 15,
                "__9": 13,
                "__10": 13,
                "__11": 12,
                "__12": 5,
                "__13": 8,
                "__14": 16,
                "__15": 14,
                "__16": 13,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 7,
                "__3": 17,
                "__4": "McLaren",
                "__5": "",
                "__6": "Scooterclem10",
                "__7": "DNS",
                "__8": "DNS",
                "__9": "DNS",
                "__10": "DNS",
                "__11": 9,
                "__12": 11,
                "__13": "DNS",
                "__14": "DNS",
                "__15": 3,
                "__16": 7,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 8,
                "__3": 8,
                "__4": "McLaren",
                "__5": "",
                "__6": "Snellehenk73",
                "__7": 13,
                "__8": 12,
                "__9": 10,
                "__10": 15,
                "__11": 13,
                "__12": 2,
                "__13": 10,
                "__14": 9,
                "__15": 8,
                "__16": 11,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 9,
                "__3": 20,
                "__4": "Aston Martin",
                "__5": "",
                "__6": "Mocky1480",
                "__7": "*",
                "__8": "*",
                "__9": "*",
                "__10": "*",
                "__11": "*",
                "__12": "*",
                "__13": "*",
                "__14": 12,
                "__15": "DNS",
                "__16": 16,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 10,
                "__3": 10,
                "__4": "Aston Martin",
                "__5": "",
                "__6": "RPM_jones58",
                "__7": 9,
                "__8": 7,
                "__9": 8,
                "__10": 19,
                "__11": 16,
                "__12": "DNS",
                "__13": 6,
                "__14": 6,
                "__15": 12,
                "__16": 9,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 11,
                "__3": 4,
                "__4": "Alpine",
                "__5": "",
                "__6": "RTRodo",
                "__7": 4,
                "__8": 2,
                "__9": 15,
                "__10": 3,
                "__11": 3,
                "__12": 15,
                "__13": 2,
                "__14": 8,
                "__15": 10,
                "__16": 4,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 12,
                "__3": 18,
                "__4": "Alpine",
                "__5": "",
                "__6": "Philmcconachie",
                "__7": 7,
                "__8": 18,
                "__9": 6,
                "__10": 11,
                "__11": 14,
                "__12": "DNS",
                "__13": "DNS",
                "__14": "DNS",
                "__15": "DNS",
                "__16": "DNS",
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 13,
                "__3": 19,
                "__4": "Alpha Tauri",
                "__5": "",
                "__6": "Goee14",
                "__7": 17,
                "__8": 14,
                "__9": 12,
                "__10": 14,
                "__11": "DNS",
                "__12": 8,
                "__13": 14,
                "__14": 18,
                "__15": "DNS",
                "__16": "DNS",
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 14,
                "__3": 11,
                "__4": "Alpha Tauri",
                "__5": "",
                "__6": "BigPuggy1987",
                "__7": 18,
                "__8": 19,
                "__9": 16,
                "__10": 20,
                "__11": 17,
                "__12": 14,
                "__13": 13,
                "__14": 19,
                "__15": 16,
                "__16": "DNS",
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 15,
                "__3": 5,
                "__4": "Alfa Romeo",
                "__5": "",
                "__6": "F1-Dino-F1",
                "__7": 11,
                "__8": "DNS",
                "__9": 4,
                "__10": 7,
                "__11": 6,
                "__12": 1,
                "__13": 5,
                "__14": 7,
                "__15": 7,
                "__16": 8,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 16,
                "__3": 13,
                "__4": "Alfa Romeo",
                "__5": "",
                "__6": "Inorganic",
                "__7": 8,
                "__8": 13,
                "__9": 9,
                "__10": 12,
                "__11": 15,
                "__12": 13,
                "__13": 11,
                "__14": 17,
                "__15": 9,
                "__16": 12,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 17,
                "__3": 6,
                "__4": "Haas",
                "__5": "",
                "__6": "Matty347",
                "__7": 1,
                "__8": 3,
                "__9": "DNS",
                "__10": 2,
                "__11": 4,
                "__12": 9,
                "__13": 3,
                "__14": 3,
                "__15": 2,
                "__16": 5,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 18,
                "__3": 9,
                "__4": "Haas",
                "__5": "",
                "__6": "Jleeky7",
                "__7": 12,
                "__8": 10,
                "__9": "DNS",
                "__10": 9,
                "__11": 10,
                "__12": 6,
                "__13": "DNS",
                "__14": 10,
                "__15": "DNS",
                "__16": 10,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 19,
                "__3": 15,
                "__4": "Williams",
                "__5": "",
                "__6": "themightyfrax",
                "__7": 16,
                "__8": 16,
                "__9": 17,
                "__10": 18,
                "__11": 13,
                "__12": 17,
                "__13": "DNS",
                "__14": 13,
                "__15": 5,
                "__16": 15,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            },
            {
                "__1": "",
                "__2": 20,
                "__3": 1,
                "__4": "Williams",
                "__5": "",
                "__6": "N4T_P4CK3R5",
                "__7": "DNS",
                "__8": 1,
                "__9": 7,
                "__10": 1,
                "__11": 2,
                "__12": 4,
                "__13": 1,
                "__14": 2,
                "__15": 4,
                "__16": 3,
                "__17": "*",
                "__18": "*",
                "__19": "*",
                "__20": "*",
                "__21": "*",
                "__22": "*"
            }
        ];

        // Loop through the remaining objects and modify them
        const modifiedData = data.map(item => {
            return {
                official_rank: item["__3"],
                team: item["__4"],
                driver_name: item["__6"],
                round_1: item["__7"],
                round_2: item["__8"],
                round_3: item["__9"],
                round_4: item["__10"],
                round_5: item["__11"],
                round_6: item["__12"],
                round_7: item["__13"],
                round_8: item["__14"],
                round_9: item["__15"],
                round_10: item["__16"],
                round_11: item["__17"],
                round_12: item["__18"],
                round_13: item["__19"],
                round_14: item["__20"],
                round_15: item["__21"],
                round_16: item["__22"]
            };
        });

        console.log(modifiedData);
    }


    function formatDriverStats() {
        // Your original JSON data
        const data = [
            {
                "__1": "smit1717",
                "__2": 12,
                "__3": 1,
                "__4": 4,
                "__5": 4,
                "__6": 2,
                "__7": 7,
                "__8": 288,
                "__9": 0,
                "__10": 0,
                "__11": 1,
                "__12": 1,
                "__13": 5,
                "__14": 5,
                "__15": -3,
                "__16": 0,
                "__17": 1,
                "__18": "8%",
                "__19": "33%",
                "__20": "33%",
                "__21": "58%",
                "__22": "0%",
                "__23": 24
            },
            {
                "__1": "Spivey-CJ",
                "__2": 12,
                "__3": 0,
                "__4": 0,
                "__5": 0,
                "__6": 0,
                "__7": 0,
                "__8": 77,
                "__9": 2,
                "__10": 0,
                "__11": 7,
                "__12": 9,
                "__13": 11,
                "__14": 15,
                "__15": 41,
                "__16": 3,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "0%",
                "__21": "0%",
                "__22": "0%",
                "__23": 6.42
            },
            {
                "__1": "Rycka9",
                "__2": 9,
                "__3": 1,
                "__4": 5,
                "__5": 0,
                "__6": 0,
                "__7": 7,
                "__8": 252,
                "__9": 0,
                "__10": 0,
                "__11": 1,
                "__12": 2,
                "__13": 4,
                "__14": 7,
                "__15": 24,
                "__16": 3,
                "__17": "",
                "__18": "11%",
                "__19": "0%",
                "__20": "56%",
                "__21": "78%",
                "__22": "0%",
                "__23": 28
            },
            {
                "__1": "Callum0064",
                "__2": 11,
                "__3": 0,
                "__4": 0,
                "__5": 0,
                "__6": 0,
                "__7": 0,
                "__8": 82,
                "__9": 0,
                "__10": 5,
                "__11": 6,
                "__12": 1,
                "__13": 9,
                "__14": 7,
                "__15": -80,
                "__16": -7,
                "__17": 1,
                "__18": "0%",
                "__19": "0%",
                "__20": "0%",
                "__21": "0%",
                "__22": "45%",
                "__23": 7.45
            },
            {
                "__1": "RPM_Danos",
                "__2": 12,
                "__3": 0,
                "__4": 3,
                "__5": 0,
                "__6": 0,
                "__7": 7,
                "__8": 213,
                "__9": 2,
                "__10": 2,
                "__11": 2,
                "__12": 2,
                "__13": 6,
                "__14": 8,
                "__15": 7,
                "__16": 1,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "25%",
                "__21": "58%",
                "__22": "17%",
                "__23": 17.75
            },
            {
                "__1": "RPM_Ijver",
                "__2": 12,
                "__3": 0,
                "__4": 0,
                "__5": 0,
                "__6": 0,
                "__7": 0,
                "__8": 34,
                "__9": 4,
                "__10": 4,
                "__11": 10,
                "__12": 5,
                "__13": 12,
                "__14": 12,
                "__15": -35,
                "__16": -3,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "0%",
                "__21": "0%",
                "__22": "33%",
                "__23": 2.83
            },
            {
                "__1": "Schnitzy91",
                "__2": 6,
                "__3": 0,
                "__4": 0,
                "__5": 0,
                "__6": 0,
                "__7": 0,
                "__8": 28,
                "__9": 0,
                "__10": 2,
                "__11": 8,
                "__12": 3,
                "__13": 12,
                "__14": 10,
                "__15": -21,
                "__16": -4,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "0%",
                "__21": "0%",
                "__22": "33%",
                "__23": 4.67
            },
            {
                "__1": "Snellehenk73",
                "__2": 12,
                "__3": 0,
                "__4": 1,
                "__5": 0,
                "__6": 1,
                "__7": 2,
                "__8": 168,
                "__9": 0,
                "__10": 1,
                "__11": 3,
                "__12": 2,
                "__13": 8,
                "__14": 10,
                "__15": 9,
                "__16": 1,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "8%",
                "__21": "17%",
                "__22": "8%",
                "__23": 14
            },
            {
                "__1": "Mocky1480",
                "__2": 3,
                "__3": 0,
                "__4": 0,
                "__5": 0,
                "__6": 0,
                "__7": 0,
                "__8": 12,
                "__9": 0,
                "__10": 0,
                "__11": 11,
                "__12": 12,
                "__13": 12,
                "__14": 13,
                "__15": 3,
                "__16": 1,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "0%",
                "__21": "0%",
                "__22": "0%",
                "__23": 4
            },
            {
                "__1": "RPM_jones58",
                "__2": 10,
                "__3": 0,
                "__4": 1,
                "__5": 0,
                "__6": 0,
                "__7": 2,
                "__8": 148,
                "__9": 2,
                "__10": 1,
                "__11": 3,
                "__12": 1,
                "__13": 7,
                "__14": 9,
                "__15": 11,
                "__16": 1,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "10%",
                "__21": "20%",
                "__22": "10%",
                "__23": 14.8
            },
            {
                "__1": "RTRodo",
                "__2": 12,
                "__3": 0,
                "__4": 3,
                "__5": 0,
                "__6": 1,
                "__7": 7,
                "__8": 245,
                "__9": 3,
                "__10": 1,
                "__11": 2,
                "__12": 2,
                "__13": 5,
                "__14": 6,
                "__15": -1,
                "__16": 0,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "25%",
                "__21": "58%",
                "__22": "8%",
                "__23": 20.42
            },
            {
                "__1": "Philmcconachie",
                "__2": 5,
                "__3": 0,
                "__4": 0,
                "__5": 0,
                "__6": 1,
                "__7": 0,
                "__8": 19,
                "__9": 3,
                "__10": 3,
                "__11": 6,
                "__12": 6,
                "__13": 11,
                "__14": 11,
                "__15": -26,
                "__16": -5,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "0%",
                "__21": "0%",
                "__22": "60%",
                "__23": 3.8
            },
            {
                "__1": "Goee14",
                "__2": 7,
                "__3": 0,
                "__4": 0,
                "__5": 0,
                "__6": 0,
                "__7": 0,
                "__8": 12,
                "__9": 2,
                "__10": 5,
                "__11": 10,
                "__12": 8,
                "__13": 11,
                "__14": 14,
                "__15": -25,
                "__16": -4,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "0%",
                "__21": "0%",
                "__22": "71%",
                "__23": 1.71
            },
            {
                "__1": "Reuze_Beertje",
                "__2": 1,
                "__3": 0,
                "__4": 0,
                "__5": 0,
                "__6": 0,
                "__7": 1,
                "__8": 34,
                "__9": 0,
                "__10": 0,
                "__11": 4,
                "__12": 9,
                "__13": 7,
                "__14": 16,
                "__15": 7,
                "__16": 4,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "0%",
                "__21": "100%",
                "__22": "0%",
                "__23": 34
            },
            {
                "__1": "F1-Dino-F1",
                "__2": 11,
                "__3": 1,
                "__4": 4,
                "__5": 0,
                "__6": 0,
                "__7": 7,
                "__8": 233,
                "__9": 0,
                "__10": 1,
                "__11": 1,
                "__12": 1,
                "__13": 5,
                "__14": 6,
                "__15": 4,
                "__16": 0,
                "__17": 1,
                "__18": "9%",
                "__19": "0%",
                "__20": "36%",
                "__21": "64%",
                "__22": "9%",
                "__23": 21.18
            },
            {
                "__1": "Inorganic",
                "__2": 12,
                "__3": 0,
                "__4": 0,
                "__5": 0,
                "__6": 1,
                "__7": 1,
                "__8": 85,
                "__9": 4,
                "__10": 2,
                "__11": 5,
                "__12": 8,
                "__13": 11,
                "__14": 12,
                "__15": -3,
                "__16": 0,
                "__17": 2,
                "__18": "0%",
                "__19": "0%",
                "__20": "0%",
                "__21": "8%",
                "__22": "17%",
                "__23": 7.08
            },
            {
                "__1": "Matty347",
                "__2": 11,
                "__3": 3,
                "__4": 5,
                "__5": 1,
                "__6": 1,
                "__7": 6,
                "__8": 240,
                "__9": 1,
                "__10": 1,
                "__11": 1,
                "__12": 1,
                "__13": 6,
                "__14": 3,
                "__15": -41,
                "__16": -4,
                "__17": 1,
                "__18": "27%",
                "__19": "9%",
                "__20": "45%",
                "__21": "55%",
                "__22": "9%",
                "__23": 21.82
            },
            {
                "__1": "Jleeky7",
                "__2": 9,
                "__3": 0,
                "__4": 0,
                "__5": 0,
                "__6": 0,
                "__7": 2,
                "__8": 159,
                "__9": 0,
                "__10": 0,
                "__11": 5,
                "__12": 6,
                "__13": 6,
                "__14": 10,
                "__15": 30,
                "__16": 3,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "0%",
                "__21": "22%",
                "__22": "0%",
                "__23": 17.67
            },
            {
                "__1": "themightyfrax",
                "__2": 8,
                "__3": 0,
                "__4": 0,
                "__5": 0,
                "__6": 0,
                "__7": 0,
                "__8": 42,
                "__9": 0,
                "__10": 3,
                "__11": 8,
                "__12": 5,
                "__13": 11,
                "__14": 14,
                "__15": 2,
                "__16": 0,
                "__17": "",
                "__18": "0%",
                "__19": "0%",
                "__20": "0%",
                "__21": "0%",
                "__22": "38%",
                "__23": 5.25
            },
            {
                "__1": "N4T_P4CK3R5",
                "__2": 11,
                "__3": 6,
                "__4": 9,
                "__5": 3,
                "__6": 4,
                "__7": 9,
                "__8": 358,
                "__9": 0,
                "__10": 1,
                "__11": 1,
                "__12": 1,
                "__13": 3,
                "__14": 3,
                "__15": -13,
                "__16": -1,
                "__17": 1,
                "__18": "55%",
                "__19": "27%",
                "__20": "82%",
                "__21": "82%",
                "__22": "9%",
                "__23": 32.55
            }
        ];

        // Loop through the remaining objects and modify them
        const modifiedData = data.map(item => {
            return {
                driver_name: item["__1"],
                starts: item["__2"],
                wins: item["__3"],
                podiums: item["__4"],
                pole: item["__5"],
                fastest_lap: item["__6"],
                top_five: item["__7"],
                points: item["__8"],
                penalty_points: item["__9"],
                dnf: item["__10"],
                best_finish: item["__11"],
                best_quali: item["__12"],
                avg_finish: item["__13"],
                avg_start: item["__14"],
                total_positions_gained: item["__15"],
                avg_positions_gained: item["__16"],
                dotw: item["__17"],
                win_percentage: item["__18"],
                pole_percentage: item["__19"],
                podium_percentage: item["__20"],
                top_five_percentage: item["__21"],
                dnf_percentage: item["__22"],
                points_per_race: item["__23"]
            };
        });
        // const modifiedData = data.map(item => {
        //     return {
        //         driver_name: item["Driver"],
        //         starts: item["Starts"],
        //         wins: item["Wins"],
        //         podiums: item["Podiums"],
        //         pole: item["Pole"],
        //         fastest_lap: item["FL"],
        //         top_five: item["Top 5"],
        //         points: item["Points"],
        //         penalty_points: item["PP"],
        //         dnf: item["DNF"],
        //         best_finish: item["Best Finish"],
        //         best_quali: item["Best Quai"],
        //         avg_finish: item["Avg Finish"],
        //         avg_start: item["Avg Start"],
        //         total_positions_gained: item["Total Positions Gain"],
        //         avg_positions_gained: item["Average Position Gain"],
        //         dotw: item["DOTW"],
        //         win_percentage: item["Win %"],
        //         pole_percentage: item["Pole %"],
        //         podium_percentage: item["Podium %"],
        //         top_five_percentage: item["Top 5 %"],
        //         dnf_percentage: item["DNF %"],
        //         points_per_race: item["Pts per Race"]
        //     };
        // });

        // const modifiedData = data.map(item => {
        //     return {
        //         driver_name: item["__3"],
        //         starts: item["__4"],
        //         wins: item["__5"],
        //         podiums: item["__6"],
        //         pole: item["__7"],
        //         fastest_lap: item["__8"],
        //         top_five: item["__9"],
        //         points: item["__10"],
        //         penalty_points: item["__11"],
        //         dnf: item["__12"],
        //         best_finish: item["__13"],
        //         best_quali: item["__14"],
        //         avg_finish: item["__15"],
        //         avg_start: item["__16"],
        //         total_positions_gained: item["__17"],
        //         avg_positions_gained: item["__18"],
        //         dotw: item["__19"],
        //         win_percentage: item["__20"],
        //         pole_percentage: item["__21"],
        //         podium_percentage: item["__22"],
        //         top_five_percentage: item["__23"],
        //         dnf_percentage: item["__24"],
        //         points_per_race: item["__25"]
        //     };
        // });

        console.log(data);
        console.log(modifiedData);
    }


    return (
        <>
            {loading !== true && user !== null &&
                <div>
                    <nav>
                        <div>
                            <button onClick={() => { navigate("/live-standings") }}>
                                Live Standings
                            </button>
                        </div>
                        <div>
                            <button onClick={() => { navigate("/session-data") }}>
                                Session Data
                            </button>
                        </div>
                        <div>
                            <button onClick={() => { navigate("/battle") }}>
                                Battle Data
                            </button>
                        </div>
                        <div>
                            <button onClick={() => { navigate("/stats") }}>
                                Stats
                            </button>
                        </div>
                        <img src={rpmLogo} />
                        <div>
                            <button onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    </nav>
                    <div className='adminButtons'>

                        <div>
                            <button onClick={() => { refreshServer('error', '', '') }}>
                                throw error
                            </button>
                        </div>

                        <div>
                            <button onClick={() => { refreshServer('status', '', '') }}>
                                get server status
                            </button>
                        </div>


                        <div>
                            <button onClick={() => { refreshServer('refresh', '', '') }}>
                                refresh server params
                            </button>
                        </div>

                        <div>
                            <button onClick={() => { refreshServer('restart', 'premier', 'race') }}>
                                Start Server Premier
                            </button>
                        </div>

                        <div>
                            <button onClick={() => { refreshServer('restart', 'championship', 'race') }}>
                                Start Server Championship
                            </button>
                        </div>

                        <div>
                            <button onClick={() => { refreshServer('restart', 'first', 'race') }}>
                                Start Server First
                            </button>
                        </div>

                        <div>
                            <button onClick={() => { refreshServer('restart', 'practice', 'race') }}>
                                Start Server Practice
                            </button>
                        </div>

                        <div>
                            <button onClick={() => { refreshServer('restart', 'offline', 'race') }}>
                                Start Server Offline
                            </button>
                        </div>

                        <div>
                            <button onClick={() => { refreshServer('restart', 'premier', 'sprint') }}>
                                Start Server Premier - Sprint
                            </button>
                        </div>

                        <div>
                            <button onClick={() => { refreshServer('restart', 'championship', 'sprint') }}>
                                Start Server Championship - Sprint
                            </button>
                        </div>

                        <div>
                            <button onClick={() => { refreshServer('restart', 'first', 'sprint') }}>
                                Start Server First - Sprint
                            </button>
                        </div>

                        <div>
                            <button onClick={() => { refreshServer('restart', 'practice', 'sprint') }}>
                                Start Server Practice - Sprint
                            </button>
                        </div>

                        <div>
                            <button onClick={() => { refreshServer('restart', 'offline', 'sprint') }}>
                                Start Server Offline - Sprint
                            </button>
                        </div>
                    </div>
                    <div className='adminButtons'>
                        <div>
                            <button onClick={() => { updateAllTiers("allTiers", allTiers) }}>
                                Update AllTiers
                            </button>
                        </div>
                        <div>
                            <button onClick={() => { updateTable("premier", premierStandings, premierConstructors) }}>
                                Update Premier
                            </button>
                        </div>
                        <div>
                            <button onClick={() => { updateTable("championship", championshipStandings, championshipConstructors) }}>
                                Update Championship
                            </button>
                        </div>
                        <div>
                            <button onClick={() => { updateTable("first", firstStandings, firstConstructors) }}>
                                Update First
                            </button>
                        </div>
                        <div>
                            <button onClick={() => { updateTable("practice", practiceStandings, practiceConstructors) }}>
                                Update Practice
                            </button>
                        </div>
                        <div>
                            <button onClick={() => { updateTable("offline", offlineStandings, offlineConstructors) }}>
                                Update Offline Test
                            </button>
                        </div>
                    </div>
                    <div className='adminButtons'>
                        <div>
                            <button onClick={() => { formatDriverHistory() }}>
                                Format Driver History
                            </button>
                        </div>
                        <div>
                            <button onClick={() => { formatDriverStats() }}>
                                Format Driver Stats
                            </button>
                        </div>
                    </div>
                    {/* <div className='adminButtons'>
                        <div>
                            <button onClick={() => { triggerResetServer() }}>
                                Update Offline Test
                            </button>
                        </div>
                    </div> */}
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
            }
        </>
    );
}

export default Admin