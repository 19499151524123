import React, { useState, useEffect } from 'react';
import Home from './Page/Home';
import Signup from './Page/Signup';
import Login from './Page/Login';
import Admin from './Page/Admin';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronUp, faChevronDown, faGripLines, faCloud, faSun, faCloudRain, faCloudBolt, faCloudShowersHeavy, faCloudSun } from '@fortawesome/free-solid-svg-icons'

import "./index.css"
import "./assets/fonts/Formula1-Bold.otf"
import "./assets/fonts/Formula1-Wide.otf"
import "./assets/fonts/Formula1-Regular.otf"
import StandingsOnly from './Page/StandingsOnly';
import SessionData from './Page/SessionData';
import BattlePredictor from './Page/BattlePredictor';
import LapHistory from './Page/LapHistory';
import DriverStats from './Page/DriverStats';

library.add(faChevronUp, faChevronDown, faGripLines, faCloud, faSun, faCloudRain, faCloudBolt, faCloudShowersHeavy, faCloudSun)
function App() {

  return (
    <Router>
      <div>
        <section>
          <Routes>                                                                        
            <Route path="/" element={<Home />} />
            <Route path="/live-standings" element={<StandingsOnly />} />
            <Route path="/session-data" element={<SessionData />} />
            <Route path="/battle" element={<BattlePredictor />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/laps" element={<LapHistory />} />
            <Route path="/stats" element={<DriverStats />} />
          </Routes>
        </section>
      </div>
    </Router>
  );
}

export default App;
