import React from 'react';




export default function LapCompare({ battleData }) {

    const sameLap = battleData.battle[0].currentLap === battleData.battle[1].currentLap;
    const leadIndex = sameLap ? 0 : 1

    const diffOne = ((battleData.battle[0].lapData.laps[0].m_sector1TimeInMS / 1000) - (battleData.battle[1].lapData.laps[leadIndex].m_sector1TimeInMS / 1000)).toFixed(3);
    const diffTwo = ((battleData.battle[0].lapData.laps[0].m_sector2TimeInMS / 1000) - (battleData.battle[1].lapData.laps[leadIndex].m_sector2TimeInMS / 1000)).toFixed(3);
    const diffThree = ((battleData.battle[0].lapData.laps[0].m_sector3TimeInMS / 1000) - (battleData.battle[1].lapData.laps[leadIndex].m_sector3TimeInMS / 1000)).toFixed(3);
    const diffLap = ((battleData.battle[0].lapData.laps[0].m_lapTimeInMS / 1000) - (battleData.battle[1].lapData.laps[leadIndex].m_lapTimeInMS / 1000)).toFixed(3);

    const outputOne = diffOne === 0 ? diffOne : diffOne > 0 ? `+${diffOne}` : `${diffOne}`;
    const outputTwo = diffTwo === 0 ? diffTwo : diffTwo > 0 ? `+${diffTwo}` : `${diffTwo}`;
    const outputThree = diffThree === 0 ? diffThree : diffThree > 0 ? `+${diffThree}` : `${diffThree}`;
    const outputLap = diffLap === 0 ? diffLap : diffLap > 0 ? `+${diffLap}` : `${diffLap}`;

    // convert .m_lapTimeInMs from millliseconds to seconds and round to 3 decimal places
    const lapTimeOne = (battleData.battle[1].lapData.laps[leadIndex].m_lapTimeInMS / 1000).toFixed(3);
    const lapTimeTwo = (battleData.battle[0].lapData.laps[0].m_lapTimeInMS / 1000).toFixed(3);

    //convert lapTimeOne and lapTimeTwo to minutes and seconds
    const lapTimeOneMin = Math.floor(lapTimeOne / 60);
    const lapTimeOneSec = (lapTimeOne % 60).toFixed(3);
    const lapTimeTwoMin = Math.floor(lapTimeTwo / 60);
    const lapTimeTwoSec = (lapTimeTwo % 60).toFixed(3);

    return (
        <>
            {
                <div className='lapContainer'>
                    <div className='top'>
                        <div>Lap Comparison - Lap {battleData.battle[0].currentLap - 1}</div>
                        <div>Powered by Race League Manager</div>
                    </div>
                    <div className='sectorHolder titles'>
                        <div className='rowName'></div>
                        <div className='timeDiv'>
                            <h6>Sector 1</h6>
                        </div>
                        <div className='timeDiv'>
                            <h6>Sector 2</h6>
                        </div>
                        <div className='timeDiv'>
                            <h6>Sector 3</h6>
                        </div>
                        <div className='timeDiv'>
                            <h6>Lap Time</h6>
                        </div>
                    </div>
                    <div className='sectorHolder'>
                        <div className='rowName'><h4 style={{ margin: 0 }}>{(battleData.battle[1].driver).toUpperCase()}</h4></div>
                        <div className='timeDiv'>
                            <h5>{(battleData.battle[1].lapData.laps[leadIndex].m_sector1TimeInMS / 1000).toFixed(3)}</h5>
                        </div>
                        <div className='timeDiv'>
                            <h5>{(battleData.battle[1].lapData.laps[leadIndex].m_sector2TimeInMS / 1000).toFixed(3)}</h5>
                        </div>
                        <div className='timeDiv'>
                            <h5>{(battleData.battle[1].lapData.laps[leadIndex].m_sector3TimeInMS / 1000).toFixed(3)}</h5>
                        </div>
                        <div className='timeDiv'>
                            <h5>{lapTimeOneMin + ':' + lapTimeOneSec}</h5>
                        </div>
                    </div>
                    {/* <div className='sectorHolder'>
                        <div className='rowName'><h4 style={{margin: 0}}>P{battleData.battle[0].currentPosition} - {(battleData.battle[0].driver).toUpperCase()}</h4></div>
                        <div className='timeDiv'>
                            <h5>{(battleData.battle[0].lapData.laps[0].m_sector1TimeInMS / 1000).toFixed(3)}</h5>
                        </div>
                        <div className='timeDiv'>
                            <h5>{(battleData.battle[0].lapData.laps[0].m_sector2TimeInMS / 1000).toFixed(3)}</h5>
                        </div>
                        <div className='timeDiv'>
                            <h5>{(battleData.battle[0].lapData.laps[0].m_sector3TimeInMS / 1000).toFixed(3)}</h5>
                        </div>
                        <div className='timeDiv'>
                            <h5>{lapTimeTwoMin +':'+lapTimeTwoSec}</h5>
                        </div>
                    </div> */}
                    <div className='sectorHolder'>
                        {/* <div className='rowName'>Difference</div> */}
                        <div className='rowName'><h4 style={{margin: 0}}>{(battleData.battle[0].driver).toUpperCase()}</h4></div>
                        <div style={{ color: diffOne === 0 ? 'black' : diffOne > 0 ? 'red' : 'green' }} className='timeDiv'>
                            <h5>{outputOne}</h5>
                        </div>
                        <div style={{ color: diffTwo === 0 ? 'black' : diffTwo > 0 ? 'red' : 'green' }} className='timeDiv'>
                            <h5>{outputTwo}</h5>
                        </div>
                        <div style={{ color: diffThree === 0 ? 'black' : diffThree > 0 ? 'red' : 'green' }} className='timeDiv'>
                            <h5>{outputThree}</h5>
                        </div>
                        <div style={{ color: diffLap === 0 ? 'black' : diffLap > 0 ? 'red' : 'green' }} className='timeDiv'>
                            <h5>{outputLap}</h5>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
