import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAwZiJDVLHdpOS8cegVvtQRCDR4IMNvYoU",
    authDomain: "f1-live-standings-rpm.firebaseapp.com",
    projectId: "f1-live-standings-rpm",
    storageBucket: "f1-live-standings-rpm.appspot.com",
    messagingSenderId: "796155125725",
    appId: "1:796155125725:web:ed93876ddcad754504e034"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Firestore database and get a reference to the service
export const db = getFirestore(app);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;