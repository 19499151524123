import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from '../firebase';
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { v4 as uuidv4 } from 'uuid';
import LiveTable from "../Components/LiveTable";
import WeatherForecast from "../Components/WeatherForecast";
// import { w3cwebsocket as W3CWebSocket } from "websocket";
import Pusher from 'pusher-js';
// Import the functions you need from the SDKs you need
import { collection, onSnapshot, getDoc, doc, setDoc, Timestamp, query, orderBy, limit } from 'firebase/firestore';
import rpmLogo from "../assets/logos/rmplogo.jpeg";
import rpmLogoBlack from "../assets/logos/rpm_black.png";
import rpmLogoGoldFade from "../assets/logos/rpm_gold_faded.png";
import rpmLogoGold from "../assets/logos/rpm_gold.png";
import rpmLogoOriginal from "../assets/logos/rpm_original.png";
import rpmLogoWhite from "../assets/logos/rpm_white.png";
// import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Home = () => {
    // set up constants and states
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);

    // useEffects for redirecting non-logged in users and blocking homepage from showing during check
    useEffect(() => {
        if (loading) {
            console.log('loading', loading);
            console.log('user', user)
            return;
        }
        if (user === null) {
            console.log('should redirect?')
            navigate("/login")
        };
    }, [user, loading]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                const email = user.email;
                // ...
                console.log("uid", uid, "email", email)
            } else {
                // User is signed out
                // ...
                console.log("user is logged out")
            }
        });

    }, [])

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    return (
        <>
            {loading !== true && user !== null &&
                <div>
                    <nav>
                        <img src={rpmLogoGold} style={{ "cursor": "pointer" }} onClick={() => { navigate("/") }} />
                        <div className='adminButtons headerButtons'>
                            <div>
                                <button onClick={() => { navigate("/live-standings") }}>
                                    Live Standings
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/session-data") }}>
                                    Session Data
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/battle") }}>
                                    Battle Data
                                </button>
                            </div>
                            <div>
                                <button onClick={() => { navigate("/stats") }}>
                                    Stats
                                </button>
                            </div>
                        </div>
                        <span style={{ 'color': 'white' }}>League ID - {process.env.REACT_APP_LEAGUE}</span>
                        <div>
                            <button onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    </nav>
                    <h1 style={{ "color": "white", "textAlign": "center", "marginTop": "3rem" }}>Welcome to the Racing Hub</h1>
                    <div className='homeTiles'>
                        <div className='homeTile' onClick={() => { navigate("/live-standings") }}>
                            <h4>Live Standings</h4>
                        </div>
                        <div className='homeTile' onClick={() => { navigate("/session-data") }}>
                            <h4>Session Data</h4>
                        </div>
                        <div className='homeTile' onClick={() => { navigate("/battle") }}>
                            <h4>Battle Data</h4>
                        </div>
                        <div className='homeTile' onClick={() => { navigate("/stats") }}>
                            <h4>Statistics</h4>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Home