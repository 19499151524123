import React from 'react';




export default function PitPrediction({ battleData }) {

    const avgPitLoss = 30.500;
    const delta = battleData.battleCalcs.delta;
    const pitDelta = (delta - avgPitLoss).toFixed(3);

    // calculate a probablity of overtaking where 100% if the pitDelta is > than 2 and 0% if the pitDelta is < than 2
    const probablity = (pitDelta / 1.15) * 100 > 100 ? 100 : (pitDelta / 1.15) * 100 < 0 ? 0 : (pitDelta / 1.15) * 100;

    return (
        <>
            {
                <div className='lapContainer'>
                    <div className='top'>
                        <div>Pit Predictor</div>
                        <div>Powered by Race League Manager</div>
                    </div>
                    <div className='sectorHolder titles pitHolder'>
                        <div className='rowName'></div>
                        <div className='timeDiv'>
                            <h6>Current Delta</h6>
                        </div>
                        <div className='timeDiv'>
                            <h6>Pitstop Avg Loss</h6>
                        </div>
                        <div className='timeDiv'>
                            <h6>Overtake Chance</h6>
                        </div>
                    </div>
                    <div className='sectorHolder pitHolder'>
                        <div className='rowName pitRow'>
                            <h4 style={{ margin: '2px 0' }}>P{battleData.battle[1].currentPosition} - {(battleData.battle[1].driver).toUpperCase()}</h4>
                            <h4 style={{ margin: '2px 0' }}>Compared to</h4>
                            <h4 style={{ margin: '2px 0' }}>P{battleData.battle[0].currentPosition} - {(battleData.battle[0].driver).toUpperCase()}</h4>
                            </div>
                        <div className='timeDiv'>
                            <h5 style={{color: delta === avgPitLoss ? 'black' : delta > avgPitLoss ? 'green' : 'red'}} >{delta}</h5>
                        </div>
                        <div className='timeDiv'>
                            <h5>{avgPitLoss}</h5>
                        </div>
                        <div className='timeDiv'>
                            <h5>{probablity.toFixed(2)}%</h5>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
